<template>
    <div class="media-object" :class="{ 'media-object--placeholder': isPlaceholder }">
        <slot v-if="!!$slots.image" name="image" />
        <div v-else class="media-object__image" :style="getImageStyle"></div>
        <div class="media-object__content">
            <slot />
        </div>
    </div>
</template>
<script>
export default {
    name: 'MediaObject',
    props: {
        image: {
            type: String,
            default: null,
        },
        isPlaceholder: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        getImageStyle() {
            return this.image ? `background-image: url(${this.image})` : '';
        },
    },
};
</script>
<style scoped lang="scss">
.media-object {
    $component: &;
    display: flex;
    min-height: 54px;

    &__image {
        flex: 0 0 auto;
        border-radius: 2px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        width: 70px;
        height: 54px;
    }

    &__content {
        margin-left: 10px;
    }

    &--placeholder {
        background: $bg-color-light;

        #{$component}__image {
            background-color: $grey-lighter-26;
        }
    }
}
</style>
