<template>
    <component :is="`h${variant === 'secondary' ? '2' : '1'}`" class="dc-logo" data-cy="dc-logo"
        >Daily<span>Chat</span></component
    >
</template>
<script>
export default {
    name: 'DailyChatLogo',
    props: {
        variant: {
            default: 'primary',
            validator: (value) => ['primary', 'secondary'].includes(value),
        },
    },
};
</script>
<style lang="scss" scoped>
$dc-logo-small-font-size: 3rem;
$dc-logo-medium-font-size: 4.8rem;

.dc-logo {
    font-weight: $font-weight-semibold;
    font-size: $dc-logo-small-font-size;
    color: $color-accent-1;
    letter-spacing: 0.075rem;

    @include media-query(medium) {
        font-size: $dc-logo-medium-font-size;
    }

    span {
        font-weight: $font-weight-light;
    }
}
</style>
