import { stringify } from 'qs';
import { Route } from 'vue-router';
import { AppAction } from '@/enums';

const config = process.env.config as any;
export default class RouterService {
    static getRedirectPath(route: Route, defaultPath: string = `${route.path}`): string {
        const query = RouterService.getRedirectParams(route);

        if (query.redirect_path) {
            return query.redirect_path;
        }

        if (query.state) {
            const state = JSON.parse(route.query.state.toString().replaceAll('\\', ''));
            return decodeURIComponent(state.redirect);
        }

        const params = stringify(query);

        if (params) {
            return `${defaultPath}?${params}`;
        }

        return defaultPath;
    }

    static getRedirectParams(route: Route): any {
        const query = JSON.parse(JSON.stringify(route.query));
        delete query.action;
        return query;
    }

    static getReferral(route: Route, $sentry: any): any {
        const queryParams = route.query;
        if (route.query.state) {
            try {
                const state = JSON.parse(route.query.state.toString().replaceAll('\\', ''));
                const referralString = decodeURIComponent(state.referral);
                const referral = JSON.parse(referralString);
                delete referral.action;
                return JSON.stringify(referral);
            } catch (error) {
                $sentry.captureException("router.service > getReferral: Error while parsing 'state' parameter", {
                    extra: {
                        route,
                        state: route.query.state,
                        error,
                    },
                });
            }
        }
        queryParams.webapp_version = config.sentryRelease;
        const queryParamsCopy = JSON.parse(JSON.stringify(queryParams));
        delete queryParamsCopy.action;
        return JSON.stringify(queryParamsCopy);
    }

    static getLoginLink(route: Route, convoId: string): string {
        let loginLink: string;

        if (!route.params.slug) {
            const origin = `${window.location.origin}${route.path !== '/' ? route.path : ''}`;
            loginLink = `${origin}?action=${AppAction.Signup}`;
        } else {
            const parts = route.path.split('/').filter(function (part) {
                return part !== '';
            });
            parts.splice(2, 1, convoId);
            loginLink = `${window.location.origin + '/' + parts.join('/')}?action=${AppAction.Signup}`;
        }

        const stringifiedQueryParams = stringify(route.query);
        if (stringifiedQueryParams) {
            loginLink = `${loginLink}&${stringifiedQueryParams}`;
        }

        return loginLink;
    }
}
