import { createHash } from 'crypto';
import config from '@/config';
import { QueryParam } from '@/enums';

export default function ({ $queryParams, redirect, error }) {
    if ($queryParams(QueryParam.StoryblokToken)) {
        const validationString = `${$queryParams(QueryParam.StoryblokSpaceId)}:${
            config.storyblokPreviewAccessToken
        }:${$queryParams(QueryParam.StoryblokTimestamp)}`;
        const validationToken = createHash('sha1').update(validationString).digest('hex');

        if (validationToken !== $queryParams(QueryParam.StoryblokToken)) {
            return error({ statusCode: 403 });
        }
    }
}
