import config from '@/config';
import { ModalType } from '@/enums/modalType.enum';
import { IAppOptions, IModal } from '@/interfaces';
import {
    SET_COUNTRY_ISO2_CODE,
    SET_IS_DAILY_CHAT_ENABLED,
    SET_IS_FULL_PAGE_LOADER_VISIBLE,
    SET_IS_LOADING,
    SET_LOCALE,
    SET_MENU_STATE,
    SET_MODAL_STATE,
    SET_SKIP_CHAT_WIDGET_INIT,
    SET_APP_OPTIONS,
} from '@/types/mutations.types';
import { IRootState } from '@/types/rootState';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { Cookie } from '@/enums';

export interface IMainState {
    activeModal: IModal;
    isLoading: boolean;
    menuOpen: boolean;
    locale: string;
    countryIso2Code: string;
    isFullPageLoaderVisible: boolean;
    skipChatWidgetInit: boolean;
    isDailyChatEnabled: boolean;
    appOptions?: IAppOptions;
}

const state = () =>
    ({
        activeModal: {
            type: null,
        },
        isLoading: false,
        menuOpen: false,
        locale: config.defaultLocale,
        countryIso2Code: config.defaultCountryCode,
        isFullPageLoaderVisible: false,
        skipChatWidgetInit: false,
        isDailyChatEnabled: false,
        appOptions: {},
    } as IMainState);

const getters: GetterTree<IMainState, IRootState> = {
    showCookieBanner: (state): boolean => state.appOptions?.options?.['website']?.['showCookieBanner'] !== false,
};

const actions: ActionTree<IMainState, any> = {
    toggleModal: (
        { commit, state },
        { type, payload }: { type: ModalType | null; payload: object } = { type: null, payload: {} },
    ) => {
        const modalExists = type ? Object.values(ModalType).includes(type) : false;
        const modalData: IModal = { type: null, payload };
        modalData.type = state.activeModal.type === type || !modalExists ? null : type;
        commit(SET_MODAL_STATE, modalData);
    },
    updateModal: ({ commit, state }, { payload }: { payload: object } = { payload: {} }) => {
        commit(SET_MODAL_STATE, { ...state.activeModal, payload: { ...state.activeModal.payload, ...payload } });
    },
    toggleMenu: ({ commit, state }, open: boolean) => {
        const isOpen = typeof open === 'boolean' ? open : !state.menuOpen;
        commit(SET_MENU_STATE, isOpen);
    },
    closeMenu: ({ commit }) => {
        commit(SET_MENU_STATE, false);
    },
    setIsLoading: ({ commit }, payload: boolean) => {
        commit(SET_IS_LOADING, payload);
    },
    async setLocale({ commit, dispatch }, { locale }: { locale: string }) {
        await this.$i18n.setLocale(locale);
        dispatch('storeLocale', locale);
        commit(SET_LOCALE, locale);
    },
    storeLocale(_context, locale: string) {
        this.app.$cookies.set(Cookie.Locale, locale, {
            expires: new Date(Date.now() + config.localeCookieExpiry),
            path: '/',
        });
    },
    clearStoredLocale() {
        this.app.$cookies.remove(Cookie.Locale);
    },
    setCountryIso2Code({ commit }, countryCode: string) {
        commit(SET_COUNTRY_ISO2_CODE, countryCode);
        this.app.$cookies.set(Cookie.CountryCode, countryCode, {
            expires: new Date(Date.now() + config.countryCodeCookieExpiry),
            path: '/',
        });
    },
    clearStoredCountryCode() {
        this.app.$cookies.remove(Cookie.CountryCode);
    },
    showFullPageLoader: ({ commit }, isVisible: boolean) => {
        commit(SET_IS_FULL_PAGE_LOADER_VISIBLE, isVisible);
    },
    setSkipChatWidgetInit: ({ commit }, skip: boolean) => {
        commit(SET_SKIP_CHAT_WIDGET_INIT, skip);
    },
    setIsDailyChatEnabled: ({ commit }, isDailyChatEnabled: boolean) => {
        commit(SET_IS_DAILY_CHAT_ENABLED, isDailyChatEnabled);
    },
    setAppOptions: ({ commit }, options: IAppOptions) => {
        commit(SET_APP_OPTIONS, options);
    },
};

const mutations: MutationTree<IMainState> = {
    [SET_IS_LOADING](state: IMainState, payload: boolean) {
        state.isLoading = payload;
    },
    [SET_APP_OPTIONS](state: IMainState, payload: IAppOptions) {
        state.appOptions = payload;
    },
    [SET_MODAL_STATE](state: IMainState, data: IModal): void {
        state.activeModal = data;
    },
    [SET_MENU_STATE](state: IMainState, payload: boolean): void {
        state.menuOpen = payload;
    },
    [SET_LOCALE](state: IMainState, locale: string): void {
        state.locale = locale;
    },
    [SET_COUNTRY_ISO2_CODE](state: IMainState, code: string): void {
        state.countryIso2Code = code;
    },
    [SET_IS_FULL_PAGE_LOADER_VISIBLE](state: IMainState, isVisible: boolean): void {
        state.isFullPageLoaderVisible = isVisible;
    },
    [SET_SKIP_CHAT_WIDGET_INIT](state: IMainState, skip: boolean): void {
        state.skipChatWidgetInit = skip;
    },
    [SET_IS_DAILY_CHAT_ENABLED](state: IMainState, isDailyChatEnabled: boolean): void {
        state.isDailyChatEnabled = isDailyChatEnabled;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
} as Module<IMainState, IRootState>;
