import { browser } from '@inconvo/utils';
import { isTouchDevice } from '@/services/Utils';

export default () => {
    if (isTouchDevice()) {
        document.body.classList.add('touch-device');
    }

    if ('serviceWorker' in navigator) {
        if (browser.storage.isSupported()) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                const registrationsLength = registrations.length;

                for (const registration of registrations) {
                    registration.unregister();
                }

                if (registrationsLength) {
                    window.location.reload();
                }
            });
        }
    }
};
