import Metatags from './metatags';

export default class Metadata {
    siteTitle: string;
    metatags!: Metatags;

    constructor() {
        this.siteTitle = '';
    }
}
