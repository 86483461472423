<template>
    <div class="chat-placeholder">
        <ChatHeader :channel="channel" :show-back-button="showBackButton" />
        <div v-if="showLoader" class="chat-loader">
            <p v-if="loaderText !== ''" class="text-large font-heading mb-3">{{ loaderText }}</p>
            <LoadingDots :dots-style="LoadingDotsStyle.Dark" />
        </div>
        <div class="chat-footer">
            <div class="chat-footer__input"></div>
        </div>
    </div>
</template>

<script>
import { LoadingDotsStyle } from '@/enums';
import LoadingDots from '@/components/LoadingDots';
import ChatHeader from './ChatHeader.vue';

export default {
    name: 'ChatPlaceholder',
    components: {
        LoadingDots,
        ChatHeader,
    },
    props: {
        channel: {
            type: Object,
            required: true,
        },
        showLoader: {
            type: Boolean,
            default: false,
        },
        loaderText: {
            type: String,
            default: '',
        },
        showBackButton: {
            type: Boolean,
            default: true,
        },
    },
    created() {
        this.LoadingDotsStyle = LoadingDotsStyle;
    },
};
</script>

<style lang="scss" scoped>
.chat-placeholder {
    width: 100%;
    height: calc(var(--icVh, 1vh) * 100);
    background-color: $bg-color;
    display: flex;
    flex-direction: column;
}

.chat-loader {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    letter-spacing: 0.19px;
}

.chat-footer {
    margin-top: auto;
    background-color: $bg-color-light;

    &__input {
        margin: 16px;
        height: 44px;
        background-color: #eeeeee;
        border-radius: 22px;
    }
}
</style>
