import { DailyChatOpinion } from '@/models';
import { IDailyChatOpinionDto } from '@/interfaces/dtos.interface';
import TestimonialFactory from './testimonial.factory';

export default class DailyChatOpinionFactory {
    public static make(data: IDailyChatOpinionDto) {
        const opinionObject = new DailyChatOpinion();

        opinionObject._editable = data._editable;
        opinionObject._uid = data._uid;
        opinionObject.title = data.title;
        opinionObject.items = data.items.map((o) => TestimonialFactory.make(o));

        return opinionObject;
    }
}
