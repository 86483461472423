export const ClickOutside = {
    bind(el: any, binding: any, vnode: any) {
        el.handleOutsideClick = (e: any) => {
            e.stopPropagation();
            if (!(el === e.target || el.contains(e.target))) {
                vnode.context[binding.expression](e);
            }
        };
        document.addEventListener('mouseup', el.handleOutsideClick);
        document.addEventListener('touchstart', el.handleOutsideClick, { passive: true });
    },
    unbind(el: any) {
        document.removeEventListener('mouseup', el.handleOutsideClick);
        document.removeEventListener('touchstart', el.handleOutsideClick);
    },
};
