export const vueI18n = {"fallbackLocale":"en-gb"}
export const vueI18nLoader = false
export const locales = [{"code":"en-gb","iso":"en-gb","country":"gb","language":"en","countryName":"United Kingdom","languageName":"English","title":"United Kingdom","file":"en.js"},{"code":"en-us","iso":"en-us","country":"us","language":"en","countryName":"United States","languageName":"English","title":"United States of America","file":"en-us.js"}]
export const defaultLocale = 'en-gb'
export const routesNameSeparator = '___'
export const defaultLocaleRouteNameSuffix = 'default'
export const strategy = 'no_prefix'
export const lazy = true
export const langDir = 'i18n/'
export const rootRedirect = null
export const detectBrowserLanguage = false
export const differentDomains = false
export const seo = false
export const baseUrl = 'https://www.yougov.chat'
export const vuex = {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true}
export const parsePages = true
export const pages = {}
export const beforeLanguageSwitch = () => null
export const onLanguageSwitched = () => null
export const IS_UNIVERSAL_MODE = true
export const MODULE_NAME = 'nuxt-i18n'
export const LOCALE_CODE_KEY = 'code'
export const LOCALE_ISO_KEY = 'iso'
export const LOCALE_DOMAIN_KEY = 'domain'
export const LOCALE_FILE_KEY = 'file'
export const STRATEGIES = {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"}
export const COMPONENT_OPTIONS_KEY = 'nuxtI18n'
export const localeCodes = ["en-gb","en-us"]
export const trailingSlash = undefined
