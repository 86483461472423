<template>
    <div>
        <ForbiddenPage v-if="error.statusCode === 403" />
        <NotFoundPage v-else />
    </div>
</template>

<script>
import NotFoundPage from '@/components/page-templates/NotFoundPage';
import ForbiddenPage from '@/components/page-templates/ForbiddenPage';

export default {
    name: 'ErrorPage',
    components: {
        NotFoundPage,
        ForbiddenPage,
    },
    layout: 'default',
    props: {
        error: {
            type: Object,
            default: () => ({}),
        },
    },
};
</script>
