export default async function (context) {
    try {
        await context.store.dispatch('channels/getChannel', context.route.params.slug);
        const channel = context.store.getters['channels/channelData'];
        if (Object.keys(channel).length === 0) {
            context.$sentry.captureMessage('channel-redirect middleware: could not get channel', {
                extra: {
                    slug: context.route.params.slug,
                },
            });

            return context.error({ statusCode: 404 });
        }
        if (channel?.slug && channel.slug !== context.route.params.slug) {
            let redirectPath = `/channels/${channel.slug}`;
            const convoId = context.route.params.convoId;

            if (convoId) {
                redirectPath = `${redirectPath}/${convoId}`;
            }

            if (Object.keys(context.route.query).length > 0) {
                const queryParams = new URLSearchParams(context.route.query);
                redirectPath = `${redirectPath}?${queryParams.toString()}`;
            }

            return context.redirect(301, redirectPath);
        }
    } catch (error) {
        context.$sentry.captureMessage('channel-redirect middleware error', {
            extra: {
                slug: context.route.params.slug,
                error,
            },
        });
    }
}
