import { Notification, NotificationI18nType, NotificationType } from '@/enums';
import { INotificationsConfig } from '@/interfaces/notification.interface';

const notificationsConfig: INotificationsConfig = {
    [Notification.Recontact]: {
        i18n: {
            type: NotificationI18nType.Default,
            path: 'notification.warnings.recontact',
        },
        disabledForLoggedInUsers: true,
        type: NotificationType.Warning,
    },
};

export default notificationsConfig;
