<template>
    <ContentContainer
        :id="data.id"
        v-editable="data"
        tag="section"
        :size="contentSize"
        class="convo-list"
        data-cy="convo-list"
    >
        <SectionHeader
            v-if="data.title"
            icon="section-header-chat"
            :text="data.title"
            :variant="variant"
            class="convo-list__header"
            data-cy="convo-list-header"
        />

        <template v-if="data.style === $options.ConvoListStyle.List">
            <ConvoListItem
                v-for="(item, index) in data.items"
                :key="index"
                class="convo-list__item"
                :item="item"
                :url="getItemUrl(item.channel.slug, item.id)"
                data-cy="convo-list-item-placeholder"
                @click="$emit('openConvo', item)"
            />
        </template>
        <div v-else-if="data.style === $options.ConvoListStyle.Grid" class="convo-grid">
            <ConvoGridItem
                v-for="(item, index) in data.items"
                :key="index"
                :item="item"
                :url="getItemUrl(item.channel.slug, item.id)"
                data-cy="convo-list-item"
                @click="$emit('openConvo', item)"
            />
        </div>
        <template v-else-if="data.style === $options.ConvoListStyle.Cards">
            <template v-if="data.showPlaceholder">
                <Card
                    v-for="index in data.total"
                    :key="index"
                    class="convo-list__item"
                    is-placeholder
                    data-cy="convo-list-item-placeholder"
                />
            </template>
            <template v-else>
                <ConvoCard
                    v-for="(item, index) in data.items"
                    :key="index"
                    class="convo-list__item"
                    :item="item"
                    :number-of-people="undefined"
                    data-cy="convo-list-item"
                    @click="$emit('openConvo', item)"
                />
            </template>
        </template>

        <div v-if="showLoadMore" class="text-center">
            <AppButton
                data-cy="convo-list-load-more"
                class="load-more-button"
                :variant="$options.ButtonVariant.outline"
                :label="data.loadMoreButtonText"
                @click="$emit('loadMore', data._uid)"
            />
        </div>
    </ContentContainer>
</template>

<script>
import ConvoCard from '@/components/ConvoCard';
import ContentContainer from '@/components/ContentContainer';
import Card from '@/components/Card';
import ConvoGridItem from '@/components/ConvoGridItem';
import ConvoListItem from '@/components/ConvoListItem';
import AppButton from '@/components/AppButton';
import { ConvoListStyle, SectionHeaderVariant } from '@/enums';
import { ButtonSize, ButtonColor, ButtonVariant } from '@/enums/buttons';
import SectionHeader from '@/components/SectionHeader';

export default {
    components: {
        ConvoCard,
        Card,
        ConvoListItem,
        ConvoGridItem,
        AppButton,
        SectionHeader,
        ContentContainer,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        contentSize: {
            type: String,
            required: true,
        },
        variant: {
            type: String,
            default: SectionHeaderVariant.Default,
            validator: (value) => Object.values(SectionHeaderVariant).includes(value),
        },
    },
    computed: {
        showLoadMore() {
            return this.data.loadMoreEnabled && this.data.total > this.data.items.length + this.data.skip;
        },
    },
    created() {
        this.$options.ButtonColor = ButtonColor;
        this.$options.ButtonVariant = ButtonVariant;
        this.$options.ButtonSize = ButtonSize;
        this.$options.SectionHeaderVariant = SectionHeaderVariant;
        this.$options.ConvoListStyle = ConvoListStyle;
    },
    methods: {
        getItemUrl(channelSlug, convoId) {
            return this.$router.resolve({
                name: 'channels-slug-convoId',
                params: { slug: channelSlug, convoId },
            }).href;
        },
    },
};
</script>

<style lang="scss" scoped>
.convo-list {
    $component: &;
    margin-bottom: 4rem;

    @include media-query(medium) {
        margin-bottom: 9rem;
    }

    &__item {
        margin-bottom: 3rem;

        @include media-query(medium) {
            margin-bottom: 5rem;
        }

        .touch-device & {
            user-select: none;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 3rem;

        @include media-query(medium) {
            margin-bottom: 6rem;
        }
    }

    &__title {
        font-family: $font-heading;
        margin-bottom: 0.6rem;
        font-size: $large-font-size;
        font-weight: $font-weight-semibold;
        color: $color-accent-1;
        line-height: 1.22;

        @include media-query(medium) {
            margin-bottom: 1rem;
            font-size: $larger-font-size;
            line-height: 1.39;
        }
    }

    &__icon {
        width: 25px;
        margin-left: 1.6rem;
    }
}

.convo-grid {
    margin-bottom: 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    gap: 3rem 3.8rem;

    @include media-query(medium) {
        margin-bottom: 7rem;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 7.5rem 5.8rem;
    }
}

.load-more-button {
    min-width: 15rem;
    @include media-query(medium) {
        min-width: 21rem;
    }
}
</style>
