import { ITestimonialDto } from '@/interfaces/dtos.interface';
import Testimonial from '../testimonial';

export default class TestimonialFactory {
    public static make(data: ITestimonialDto): Testimonial {
        const testimonial = new Testimonial();

        testimonial.testimonial = data.testimonial;
        testimonial.image = data.image.filename;
        testimonial.name = data.name;

        return testimonial;
    }
}
