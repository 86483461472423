import { IYougovDirectApiClient } from '@/api/interfaces/yougovDirectApiClient.interface';
import config from '@/config';
import { IYgdProfile } from '@/interfaces';
import RequestService from '@/services/request.service';
import { AxiosRequestConfig } from 'axios';

class YougovDirectClient implements IYougovDirectApiClient {
    public async getProfile(token?: string): Promise<IYgdProfile> {
        let requestConfig: AxiosRequestConfig = {
            baseURL: config.yougovProxyApiBaseUrl,
        };

        if (token) {
            requestConfig = { ...requestConfig, headers: { Authorization: `Bearer ${token}` } };
        }
        const response = await RequestService.get('profile', requestConfig);
        return response as any;
    }
}

export default new YougovDirectClient();
