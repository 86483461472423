import { ChatWigetType, Layout, QueryParam } from '@/enums';
import { createClient, IChatClientOptions } from '@inconvo/chat-client';
import { Inject, Context } from '@nuxt/types/app';
import { IClient } from '@/interfaces';

const config = process.env.config as any;
const allowedLayouts = [Layout.Default, Layout.Chat, Layout.DailyChatProduct];

export default async ({ route, nuxtState, app, store }: Context, inject: Inject) => {
    if (!allowedLayouts.includes(nuxtState.layout)) {
        return;
    }

    const appOptions = store.state.main.appOptions;
    const appCode = route.query[QueryParam.appCode] || config.applicationCode;

    let options: IChatClientOptions = {
        mode: ChatWigetType.Host,
        appCode,
        url: config.chatWidgetUrl,
        standalone: false,
        controlButtons: {
            showMinimiseButton: true,
            showCloseButton: true,
            showBackButton: false,
        },
        targetElementId: 'ic-chat-widget',
        locale: app.i18n.locale,
        onInit: (options) => {
            const client: IClient = {
                clientId: options.clientId,
                subscriptionTopic: options.subscriptionTopic,
            };
            app.store?.dispatch('auth/setClient', client);
        },
    };

    if (route.name === 'channels-slug-convoId') {
        options = {
            ...options,
            mode: ChatWigetType.ChannelView,
            closable: false,
            controlButtons: {
                showMinimiseButton: appOptions?.options?.chatHeader?.showMinimiseButton ?? false,
                showCloseButton: appOptions?.options?.chatHeader?.showCloseButton ?? false,
                showBackButton: appOptions?.options?.chatHeader?.showBackButton ?? true,
            },
        };
    }
    const chatWidget = await createClient(options);
    chatWidget.setCountryCode(store.state.main.countryIso2Code);

    inject('chatWidget', chatWidget);
};
