import { render, staticRenderFns } from "./AppTestimonial.vue?vue&type=template&id=135c16ae&scoped=true&"
import script from "./AppTestimonial.vue?vue&type=script&lang=js&"
export * from "./AppTestimonial.vue?vue&type=script&lang=js&"
import style0 from "./AppTestimonial.vue?vue&type=style&index=0&id=135c16ae&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "135c16ae",
  null
  
)

export default component.exports