import { createHmac } from 'crypto';

export default class StoryblokService {
    getSignature(secretToken: string, data: object): string | null {
        if (!secretToken || !data) {
            return null;
        }

        return createHmac('sha1', secretToken).update(JSON.stringify(data)).digest('hex');
    }

    setTimestamp(): void {
        process.env.storyblokTimestamp = new Date().getTime().toString();
    }

    getTimestamp(): string {
        if (!process.env.storyblokTimestamp) {
            this.setTimestamp();
        }

        return process.env.storyblokTimestamp || '';
    }
}
