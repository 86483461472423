export enum ButtonVariant {
    Default = 'default',
    Primary = 'primary',
    Accent = 'accent',
    Facebook = 'facebook',
    Google = 'google',
    Warning = 'warning',
    Transparent = 'transparent',
    Tertiary = 'tertiary',
    Plum = 'plum',
    Peach = 'peach',
    DefaultDarkHoverRed = 'default-dark-hover-red',
}
