import { IMetadataDto } from '@/interfaces/dtos.interface';
import { Metadata, Metatags } from '@/models';

export default class MetadataFactory {
    public static make(data: IMetadataDto): Metadata {
        const metadata = new Metadata();
        metadata.siteTitle = data.site_title;
        metadata.metatags = new Metatags();
        metadata.metatags.title = data.metatags.title;
        metadata.metatags.description = data.metatags.twitter_description;
        metadata.metatags.image = data.metatags.og_image;
        metadata.metatags.ogTitle = data.metatags.og_title;
        metadata.metatags.ogDescription = data.metatags.og_description;
        metadata.metatags.ogImage = data.metatags.og_image;
        metadata.metatags.twitterTitle = data.metatags.twitter_title;
        metadata.metatags.twitterDescription = data.metatags.twitter_description;
        metadata.metatags.twitterImage = data.metatags.twitter_image;

        return metadata;
    }
}
