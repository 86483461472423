export default class Metatags {
    title: string;
    image: string;
    description: string;
    ogTitle: string;
    ogDescription: string;
    ogImage: string;
    twitterTitle: string;
    twitterDescription: string;
    twitterImage: string;

    constructor() {
        this.title = '';
        this.description = '';
        this.image = '';
        this.ogTitle = '';
        this.ogDescription = '';
        this.ogImage = '';
        this.twitterTitle = '';
        this.twitterDescription = '';
        this.twitterImage = '';
    }
}
