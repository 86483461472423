<template>
    <ValidationProvider :rules="rules" tag="div" class="input-wrapper">
        <input
            :id="id"
            v-model="internalChecked"
            class="input"
            type="checkbox"
            v-bind="$attrs"
            :name="name"
            :disabled="disabled"
            :checked="value"
        />

        <label :for="id" class="label">{{ label }}</label>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
    name: 'Checkbox',
    components: {
        ValidationProvider,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        value: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        rules: {
            type: [Object, String],
            default: '',
        },
    },
    data() {
        return {
            internalChecked: this.value,
        };
    },
    watch: {
        internalChecked(checked) {
            this.$emit('input', checked);
        },
    },
};
</script>

<style lang="scss" scoped>
.input-wrapper {
    position: relative;
    min-height: 20px;

    .input {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;

        + .label::after {
            content: none;
        }
    }

    .input:checked + .label::after {
        content: '';
    }

    .label {
        padding-left: 28px;
        padding-top: 3px;
        position: relative;
        display: inline-block;
        font-size: 12px;
        color: $default-text-color;
        line-height: normal;

        &::before,
        &::after {
            position: absolute;
            content: '';
            display: inline-block;
        }

        &::before {
            background-color: #f3f3f3;
            border: 1px solid #e6e6e6;
            height: 20px;
            width: 20px;
            left: 0;
            top: 0;
        }
    }
}

.input-wrapper .label::after {
    border-radius: 2px;
    height: 12px;
    width: 12px;
    background-color: #334a8c;
    left: 4px;
    top: 4px;
}
</style>
