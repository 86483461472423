import { apiClient } from '@/api/server';
import config from '@/config';
import { IDictionary, IMessage } from '@/interfaces';
import { IConvoMetadataDto } from '@/interfaces/dtos.interface';
import { Channel, Metatags } from '@/models';
import { NuxtI18nSeo } from 'nuxt-i18n/types/nuxt-i18n';
import { Route } from 'vue-router';
import { ErrorCodes } from '@/enums';
import { decrypt } from './Utils';

interface IGetMetatags {
    title?: string;
    description?: string;
    image?: string;
    ogTitle?: string;
    ogDescription?: string;
    ogImage?: string;
    twitterTitle?: string;
    twitterDescription?: string;
    twitterImage?: string;
    url?: string;
    appName?: string;
}

interface IMetadataServiceParams {
    readonly title: string;
    readonly channel?: Channel;
    readonly convoId?: string;
    readonly shareMetadata?: string;
    readonly defaultMetatags?: Metatags;
    readonly i18nSeo?: NuxtI18nSeo;
    readonly url?: string;
}
export default class MetadataService {
    private readonly channel?: Channel;
    private readonly convoId?: string;
    private readonly shareMetadata?: string;
    private readonly defaultMetatags?: Metatags;
    private readonly i18nSeo?: NuxtI18nSeo;
    private readonly url?: string;
    private readonly title?: string;
    private readonly $logger: any;
    private readonly route?: Route;

    constructor(params: IMetadataServiceParams, $logger, route?: Route) {
        this.channel = params.channel;
        this.convoId = params.convoId;
        this.shareMetadata = params.shareMetadata;
        this.defaultMetatags = params.defaultMetatags;
        this.i18nSeo = params.i18nSeo;
        this.url = params.url;
        this.title = params.title;
        this.$logger = $logger;
        this.route = route;
    }

    getMetadata({ isCookieBannerEnabled, fetchedConvoMetadata }: { isCookieBannerEnabled: boolean; fetchedConvoMetadata: IConvoMetadataDto }) {
        try {
            let metadata: any = {
                meta: [],
            };
            let convoMetadata: IConvoMetadataDto = {} as IConvoMetadataDto;
            let shareMessage!: IMessage;

            if (this.i18nSeo) {
                metadata = { ...this.i18nSeo };
            }

            if (this.title) {
                metadata.title = this.channel?.name ? `${this.channel.name} | ${this.title}` : this.title;
            }

            let shareMetadata: string | undefined;
            if (this.shareMetadata) {
                shareMetadata = decrypt(`${process.env.ENCRYPTION_SERVICE_SECRET}`, this.shareMetadata);
            }

            const missingShareMessage: boolean =
                !shareMessage?.shareTitle || !shareMessage?.shareSubtitle || !shareMessage?.mediaUri;

            if (this.convoId && missingShareMessage) {
                convoMetadata = fetchedConvoMetadata;
            }

            const title = shareMessage?.shareTitle || convoMetadata?.name || this.channel?.name;
            const description =
                shareMetadata || shareMessage?.shareSubtitle || convoMetadata?.description || this.channel?.description;
            const image = shareMessage?.mediaUri || convoMetadata?.image || this.channel?.landscapeImage;

            const metatags = this.getMetatags({
                title: title || this.defaultMetatags?.title,
                description: description || this.defaultMetatags?.description,
                image: image || this.defaultMetatags?.image,
                ogTitle: title || this.defaultMetatags?.ogTitle,
                ogDescription: description || this.defaultMetatags?.ogDescription,
                ogImage: description || this.defaultMetatags?.ogImage,
                twitterTitle: title || this.defaultMetatags?.twitterTitle,
                twitterDescription: description || this.defaultMetatags?.twitterDescription,
                twitterImage: image || this.defaultMetatags?.twitterImage,
                appName: this.title,
                url: this.url,
            });

            metadata.meta = [...metadata.meta, ...metatags];

            metadata.script = this.getScripts(isCookieBannerEnabled);

            return metadata;
        } catch (error) {
            this.$logger.error({
                msg: 'metadata.service: error getting metadata',
                error,
                channel: this.channel,
                convoId: this.convoId,
                url: this.url,
                title: this.title,
                route: this.route,
                errorCode: ErrorCodes.FailedToGetMetadata,
            });
        }
    }

    async getConvoMetadata(convoId: string): Promise<IConvoMetadataDto | null> {
        try {
            return await apiClient.getConvoMetadata(convoId);
        } catch (error: any) {
            if (error.response?.status === 404) {
                this.$logger.warn({ msg: 'Convo metadata not found', error, convoId });
            } else {
                this.$logger.error({
                    msg: 'Could not get convo metadata',
                    error,
                    convoId,
                    errorCode: ErrorCodes.FailedToGetConvoMetadata,
                });
            }

            return null;
        }
    }

    private getMetatags(params: IGetMetatags): IDictionary[] {
        const metatags: IDictionary[] = [];

        if (params.title) {
            metatags.push({ hid: 'name', itemprop: 'name', content: params.title });
        }

        if (params.description) {
            metatags.push(
                { hid: 'description', name: 'description', content: params.description },
                { hid: 'description-itemprop', itemprop: 'description', content: params.description },
            );
        }

        if (params.ogTitle) {
            metatags.push({ hid: 'og-title', property: 'og:title', content: params.ogTitle });
        }

        if (params.ogDescription) {
            metatags.push({ hid: 'og-description', property: 'og:description', content: params.ogDescription });
        }

        if (params.twitterTitle) {
            metatags.push({ hid: 'twitter-title', property: 'twitter:title', content: params.twitterTitle });
        }

        if (params.twitterDescription) {
            metatags.push({
                hid: 'twitter-description',
                property: 'twitter:description',
                content: params.twitterDescription,
            });
        }

        if (params.twitterImage) {
            metatags.push({ hid: 'twitter-image', property: 'twitter:image', content: params.twitterImage });
        }

        if (params.image) {
            metatags.push(
                { hid: 'image', itemprop: 'image', content: params.image },
                { hid: 'og-image', property: 'og:image', content: params.image },
                { hid: 'og-image-width', property: 'og:image:width', content: 0 },
                { hid: 'og-image-height', property: 'og:image:height', content: 0 },
            );
        }

        if (params.url) {
            metatags.push({ hid: 'og-url', property: 'og:url', content: params.url });
        }

        if (params.appName) {
            metatags.push(
                { hid: 'app-name', name: 'application-name', content: params.appName },
                { hid: 'app-title', name: 'apple-mobile-web-app-title', content: params.appName },
            );
        }

        return metatags;
    }

    private getScripts(isCookieBannerEnabled: boolean) {
        if (isCookieBannerEnabled) {
            const scripts: any = [
                {
                    hid: 'onetrust-inline',
                    innerHTML: 'function OptanonWrapper() { }',
                    __dangerouslyDisableSanitizers: ['script'],
                },
            ];

            if (config.isProduction) {
                scripts.push({
                    hid: 'onetrust-autoblock',
                    src: 'https://cdn-ukwest.onetrust.com/consent/59f9c537-924c-4249-bdbd-89642a501bef/OtAutoBlock.js',
                    defer: true,
                });
                scripts.push({
                    hid: 'onetrust-prod',
                    src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
                    charset: 'UTF-8',
                    'data-domain-script': '59f9c537-924c-4249-bdbd-89642a501bef',
                    defer: true,
                });
            } else {
                scripts.push({
                    hid: 'onetrust-dev',
                    src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
                    charset: 'UTF-8',
                    'data-domain-script': '59f9c537-924c-4249-bdbd-89642a501bef-test',
                    defer: true,
                });
            }

            return scripts;
        }
    }
}
