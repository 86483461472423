<template>
    <header class="header" :class="{ 'header--collapsed': collapsed }">
        <div class="page-container header-container">
            <router-link to="/" class="logo-wrapper" data-cy="header-logo" :title="$t('header.home')">
                <AppLogo class="app-logo" />
            </router-link>
            <div class="ml-auto">
                <button
                    v-if="isLoggedIn"
                    id="nav-button"
                    class="button-reset nav-button"
                    data-cy="menu-button"
                    aria-haspopup="true"
                    aria-controls="app-nav"
                    :aria-label="$t('header.navigationLabel')"
                    :aria-expanded="menuOpen.toString()"
                    @click="$emit('toggleMenu')"
                >
                    <Icon name="header-profile" :width="null" :height="null" />
                </button>

                <HeaderLoginButton
                    v-else-if="showLoginButton"
                    data-cy="login-button"
                    :label="$t('header.login')"
                    @click="$emit('joinButtonClick')"
                />
            </div>

            <AppNav
                id="app-nav"
                class="app-nav"
                nav-button-id="nav-button"
                :is-open="menuOpen"
                :is-logged-in="isLoggedIn"
                :display-name="displayName"
                @toggleMenu="$emit('toggleMenu', $event)"
                @logout="$emit('logout')"
            />
        </div>
    </header>
</template>

<script>
import Icon from '@/components/Icon';
import AppLogo from '@/components/AppLogo';
import AppNav from '@/components/AppNav';
import HeaderLoginButton from '@/components/HeaderLoginButton';

export default {
    name: 'AppHeader',
    components: {
        Icon,
        AppLogo,
        AppNav,
        HeaderLoginButton,
    },
    props: {
        menuOpen: {
            type: Boolean,
            required: true,
        },
        showLoginButton: {
            type: Boolean,
            required: true,
        },
        collapsed: {
            type: Boolean,
            required: true,
        },
        isPanelist: {
            type: Boolean,
            default: false,
        },
        isLoggedIn: {
            type: Boolean,
            default: false,
        },
        displayName: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="scss" scoped>
$header-height-mobile: 4.5rem !default;
$header-height-desktop: 7rem !default;
$header-bg-color: #fff !default;
$header-color: #3e4a5c !default;
$header-button-bg-color: #eff1f4 !default;
$header-nav-button-bg-color: #f7f6fa !default;
$header-nav-button-icon-color: #312744 !default;
$header-nav-button-indicator-bg-color: #29cdca !default;
$nav-user-indicator-bg-color: #29cdca !default;
$nav-user-indicator-border-color: #fbfafc !default;

.header {
    position: fixed;
    z-index: $header-z-index;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $header-bg-color;
    transition: box-shadow 300ms ease-in-out;
    will-change: box-shadow;

    &.header--collapsed {
        box-shadow: 0 0.1rem 0.6rem 0 rgba(49, 39, 68, 0.2);
    }
}

.header-container {
    position: relative;
    height: $header-height-mobile;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-query(medium) {
        height: $header-height-desktop;
    }
}

.logo-wrapper {
    position: absolute;
    @include center(y);

    .app-logo {
        font-size: 1.8rem;

        @include media-query(medium) {
            font-size: 2.2rem;
        }
    }
}

.app-nav {
    position: absolute;
    top: #{$header-height-mobile + 1rem};
    right: $page-container-padding;
    margin-left: $page-container-padding;

    @include media-query(medium) {
        top: #{$header-height-desktop + 1rem};
    }
}

.nav-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.3rem;
    height: 3.3rem;
    background-color: $header-nav-button-bg-color;
    color: $header-nav-button-icon-color;
    font-size: 1.4rem;

    &::before {
        content: '';
        position: absolute;
        bottom: -0.5rem;
        right: -0.5rem;
        display: block;
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
        background-color: $nav-user-indicator-bg-color;
        border: 0.1rem solid $nav-user-indicator-border-color;

        @include media-query(medium) {
            bottom: -0.6rem;
            right: -0.6rem;
            width: 1.2rem;
            height: 1.2rem;
            border-width: 0.2rem;
        }
    }

    @include media-query(medium) {
        width: 3.6rem;
        height: 3.6rem;
        font-size: 1.6rem;
    }
}
.join-button {
    padding-left: 1rem;
    padding-right: 1rem;

    @include media-query(medium) {
        padding-left: 1.4rem;
        padding-right: 1.4rem;
    }
}
</style>
