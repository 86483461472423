<template>
    <div class="dot-container">
        <div class="dots" :class="`dots--${dotsStyle}`">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
        </div>
    </div>
</template>

<script>
import { LoadingDotsStyle } from '@/enums';

export default {
    name: 'LoadingDots',
    props: {
        dotsStyle: {
            type: String,
            default: LoadingDotsStyle.Default,
            validator: (value) => Object.values(LoadingDotsStyle).includes(value),
        },
    },
};
</script>

<style lang="scss" scoped>
$loading-dots-color: #ffffff !default;
$loading-dots-dark-color: $dark-grey-blue;

$dotSpacing: 3px;
$dotWidth: 8px;
$dotHeight: 8px;
$dotHeight: 8px;

@mixin dot($width: $dotWidth, $height: $dotHeight) {
    width: $width;
    height: $height;
    border-radius: 50%;
}

.dot-container {
    padding: 6px $dotSpacing;
    display: flex;
    justify-content: center;
}

.dots {
    position: relative;
    display: flex;
    color: $loading-dots-color;

    &--dark {
        color: $loading-dots-dark-color;
    }
}

.dot {
    animation: dotFlashing 600ms infinite linear alternate;
    background-color: currentColor;
    margin: 0 $dotSpacing;
    @include dot;

    &:nth-child(1) {
        animation-delay: 0ms;
    }

    &:nth-child(2) {
        animation-delay: 200ms;
    }

    &:nth-child(3) {
        animation-delay: 400ms;
    }
}

@keyframes dotFlashing {
    0% {
        opacity: 1;
    }

    50%,
    100% {
        opacity: 0.3;
    }
}
</style>
