<template>
    <div :style="widgetCssVars">
        <div v-if="showPlaceholder" class="chat-widget-placeholder-wrapper">
            <ChatPlaceholder
                class="chat-widget-placeholder"
                show-loader
                :show-back-button="showBackButton"
                :loader-text="$t('chatPlaceholder.channelIsLoading')"
                :channel="channel"
            />
        </div>
        <div v-once id="ic-chat-widget" class="ic-chat-widget">
            <iframe
                id="ic-chat-widget-frame"
                class="ic-chat-widget-frame ic-chat-widget-frame--hidden"
                data-hj-allow-iframe
                :src="`${chatWidgetUrl}?appCode=${applicationCode}&origin=${origin}&shareUrl=${host}&locale=${locale}&queryParams=${queryParams}&standalone=0`"
            ></iframe>
        </div>
    </div>
</template>

<script>
import config from '@/config';
import { QueryParam } from '@/enums';
import ChatPlaceholder from '@/components/chat/ChatPlaceholder';

export default {
    name: 'ChatClient',
    components: {
        ChatPlaceholder,
    },
    props: {
        channel: {
            type: Object,
            default: null,
        },
        showPlaceholder: {
            type: Boolean,
            default: false,
        },
        showBackButton: {
            type: Boolean,
            default: true,
        },
        chatWidgetOptions: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            chatWidgetUrl: config.chatWidgetUrl,
            applicationCode: this.$queryParams(QueryParam.appCode) || config.applicationCode,
            host: config.host,
            origin: this.$queryParams(QueryParam.Embedded) === '1' ? config.embedHost : config.host,
            queryParams: this.$queryParams(QueryParam.Referral),
            locale: this.$i18n.locale,
        };
    },
    computed: {
        widgetCssVars() {
            return {
                '--yc-height': this.chatWidgetOptions.clientHeight,
                '--yc-width': this.chatWidgetOptions.clientWidth,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.ic-chat-widget {
    &--host {
        .ic-chat-widget-frame {
            z-index: $chat-widget-z-index;
            position: fixed;
            bottom: 30px;
            right: 96px;
            width: var(--yc-width, 375px);
            height: var(--yc-height, 600px);
            max-height: calc(100vh - 60px);
            border-radius: 10px;
            transform: translateY(20px);
            transition: transform 300ms ease, opacity 300ms ease;

            &--opened {
                transform: translateY(0);
            }
        }
    }

    &--full-screen {
        .ic-chat-widget-frame {
            z-index: $chat-widget-z-index !important;
        }
    }

    .ic-chat-widget-frame--hidden {
        position: fixed !important;
        right: auto !important;
        left: -9999px !important;
        visibility: hidden !important;
    }
}

.chat-widget-placeholder-wrapper {
    display: flex;
    justify-content: center;
}

.chat-widget-placeholder {
    position: fixed;
    top: 0;

    @include media-query(large) {
        max-width: 650px;
    }
}
</style>
