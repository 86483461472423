export enum AppAction {
    Landing = 'landing',
    LandingSms = 'landing-sms',
    Subscribe = 'subscribe',
    Signup = 'signup',
    SignupNotClosable = 'signup-noclose',
    NoSignup = 'no-signup',
    VerifyUser = 'verify-user',
    FacebookLogin = 'facebook-login',
    EmailOnly = 'signup-email',
    SignupSocial = 'signup-social',
}
