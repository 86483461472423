<template>
    <div v-click-outside="clickOutside" class="form-group select mb-3" :class="[{ 'select--is-open': isOpen }]">
        <div
            class="form-field"
            :class="{
                'form-field--is-focused': isOpen,
            }"
            @click="toggleOptions"
        >
            <div
                class="form-field-label"
                :class="{ 'form-field-label--hidden': hideLabel, 'form-field-label--small': !!selectedOption }"
            >
                <span>{{ label }}</span>
            </div>
            <div class="form-field-input">
                <span v-if="selectedOption" class="selected">{{ selectedOption }}</span>
                <span v-else-if="placeholder" class="form-field-placeholder">{{ placeholder }}</span>
            </div>
            <Icon class="arrow" name="arrow-down" :width="12" :height="12" />
        </div>
        <div class="form-field-additional-information">
            <div class="form-field-additional-information__left">
                <div v-if="infoText" class="form-field-info-text">{{ infoText }}</div>
            </div>
        </div>
        <div class="options" :class="{ open: isOpen }">
            <div
                v-for="option of options"
                :key="option.id"
                class="option"
                :class="{ selected: option.id === value }"
                @click="selectOption(option.id)"
            >
                {{ option.value }}
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/Icon';

export default {
    name: 'Select',
    components: {
        Icon,
    },
    props: {
        options: {
            type: Array,
            required: true,
        },
        value: {
            type: [Number, String],
            default: null,
        },
        label: {
            type: String,
            required: true,
        },
        errors: {
            type: Array,
            default: () => [],
        },
        hasMarginBottom: {
            type: Boolean,
            default: true,
        },
        showClearIcon: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hideLabel: {
            type: Boolean,
            default: false,
        },
        infoText: {
            type: String,
            default: undefined,
        },
        placeholder: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            isOpen: false,
            innerValue: this.value,
        };
    },
    computed: {
        selectedOption() {
            const option = this.options.find((o) => o.id === this.value);
            return option && option.value;
        },
        hasOptionSelected() {
            const hasOption = this.value || this.value === 0 || this.value > 0;
            return hasOption;
        },
    },
    watch: {
        value(newValue) {
            this.innerValue = newValue;
            this.$nextTick(() => this.$emit('changed'));
        },
    },
    methods: {
        clickOutside() {
            if (this.isOpen) {
                this.toggleOptions();
            }
        },
        toggleOptions() {
            if (!this.disabled) {
                this.isOpen = !this.isOpen;
            }
        },
        selectOption(value) {
            this.innerValue = value;
            this.$emit('input', value);
            this.toggleOptions();
        },
    },
};
</script>

<style lang="scss" scoped>
.form-field-label {
    font-size: 16px;
    padding: 0 8px;
    opacity: 0.6;
    width: 100%;
    position: absolute;
    transition: all 200ms ease;
    padding: 5px 8px;
    top: 3px;

    &--small {
        padding: 0 8px;
        font-size: 12px;
        transform: translateY(-11px);
    }
}

.form-field-additional-information {
    padding: 4px 8px;
    font-size: 10px;
    font-weight: 300;
    color: #b5b5b5;
    display: inline-block;
}

.form-field-placeholder,
.form-field-input {
    font-family: $font-heading;
    font-size: 16px;
    color: #797979;
    padding: 5px 8px;
    line-height: 1.5;
    height: 35px;
    border-bottom: 1px solid #ada7ba;

    .selected {
        font-weight: 700;
        color: #000000;
    }
}

.select {
    position: relative;

    .arrow {
        color: #ada7ba;
        position: absolute;
        top: calc(50% - 15px);
        right: 15px;
    }

    &--is-open {
        .arrow {
            transform: rotate(180deg);
        }
    }
}

.options {
    font-size: $default-font-size - 2;
    display: none;
    position: absolute;
    top: 43px;
    left: 0;
    padding: 9px 6px;
    background: $default-light-color;
    border: 1px solid #ada7ba;
    border-top: none;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: $layer-10;

    &.open {
        display: block;
    }

    .option {
        min-height: 34px;
        padding: 9px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;

        &:hover {
            background: #f7fcfc;
        }

        &.selected {
            background: #f7fcfc;
        }
    }
}
</style>
