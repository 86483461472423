<template>
    <ValidationObserver v-slot="{ invalid, validated, errors, reset, handleSubmit }" slim>
        <form novalidate @submit.prevent="handleSubmit(onSubmit)">
            <slot :invalid="invalid" :errors="errors" :validated="validated" :reset="reset" />
        </form>
    </ValidationObserver>
</template>

<script>
import { ValidationObserver, extend, configure } from 'vee-validate';
import { required, min, max, email, alpha_spaces as alphaSpaces } from 'vee-validate/dist/rules';

// Regex to match a string with minimum 8 character and/or numbers.
const passwordRegex = new RegExp('^(?=.*[A-Za-z])(?=.*[0-9])(?=.{8,})');

export default {
    name: 'Form',
    components: {
        ValidationObserver,
    },
    beforeCreate() {
        configure({
            defaultMessage: (_, values) => this.$i18n.t(`validation.${values._rule_}`, values),
        });

        extend('required', required);
        extend('min', min);
        extend('max', max);
        extend('email', email);
        extend('alpha_spaces', alphaSpaces);
        extend('password', (value) => passwordRegex.test(value));
        extend('matches', {
            params: ['target'],
            validate(value, { target }) {
                return value === target;
            },
            message: 'Password does not match',
        });
        extend('date', {
            params: ['format'],
            validate: (value) => /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/.test(value),
            message: this.$i18n.t('accountDetails.incorrectDateFormat'),
        });
    },
    methods: {
        onSubmit($event) {
            this.$emit('submit', $event);
        },
    },
};
</script>
