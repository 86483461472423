<template>
    <transition-group name="slide" tag="div" class="system-notifications" :class="notificationCssClass">
        <message-banner
            v-for="(notification, idx) of notifications"
            :key="`notif-${idx}`"
            :type="notification.type"
            :dismissable="notification.dismissable"
            :confirmable="notification.confirmable"
            @dismissed="dismissNotification(notification.id)"
            @confirmed="$notifications.confirm(notification.id)"
        >
            <template #icon>
                <Icon :name="notification.icon || getNotificationIcon(notification.type)" :width="32" :height="32" />
            </template>
            <template v-if="notification.i18n">
                <i18n
                    v-if="notification.i18n.type === $options.NotificationI18nType.TextWithLink"
                    :path="notification.i18n.path"
                    tag="div"
                >
                    <template #lineBreak>
                        <br />
                    </template>
                    <template #link>
                        <a
                            class="system-notifications__notifications-link"
                            :target="notification.i18n.params.linkTarget || '_self'"
                            :href="notification.i18n.params.link"
                        >
                            {{ $t(notification.i18n.params.linkTextPath) }}
                        </a>
                    </template>
                </i18n>
                <span v-else>{{ $t(notification.i18n.path, notification.i18n.params) }}</span>
            </template>
            <template v-else>{{ notification.text }}</template>
        </message-banner>
    </transition-group>
</template>

<script>
import MessageBanner from '@inconvo/components/src/components/MessageBanner.vue';
import Icon from '@inconvo/components/src/components/Icon/index.vue';
import { NotificationI18nType } from '@/enums';

export default {
    name: 'SystemNotifications',
    components: {
        MessageBanner,
        Icon,
    },
    props: {
        notifications: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        notificationCssClass() {
            const cssClass = [];

            // TODO: set notification css class if the chat is open or it is the channel page
            // if (this.showChatWindow) {
            //     cssClass.push('app__notifications--chat');
            // }

            // if (this.$route.meta.layout === 'chat') {
            //     cssClass.push('app__notifications--chat-layout');
            // }

            return cssClass;
        },
    },
    created() {
        this.$options.NotificationI18nType = NotificationI18nType;
    },
    methods: {
        getNotificationIcon(notificationType) {
            switch (notificationType) {
                case 'success':
                    return 'smug-bot';
                case 'warn':
                case 'error':
                    return 'sad-bot';
                default:
                    return 'default-bot';
            }
        },
        dismissNotification(id) {
            this.$notifications.dismiss(id);
        },
    },
};
</script>

<style lang="scss" scoped>
.system-notifications {
    position: fixed;
    z-index: 250;
    top: $header-height-mobile + $default-vertical-space;
    right: 0;
    width: 600px;
    padding: 0 20px 0;

    &--chat-layout {
        @include media-query(large) {
            @include center(x);
            right: auto;
            width: 100%;
            max-width: 650px;
            padding: 0 10px;
        }
    }

    .message-banner:not(:last-child) {
        margin-bottom: 10px;
    }

    @include media-query(only-tiny) {
        top: $header-height-mobile;
        padding: 0;
    }

    @include media-query(only-small) {
        width: 100%;
    }

    @include media-query(large) {
        top: $header-height-desktop + $default-vertical-space;
    }

    &__notifications-link {
        text-decoration: underline;
    }
}

.slide-enter-active,
.slide-leave-active {
    transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
}
.slide-enter,
.slide-leave-to {
    opacity: 0;
    transform: translateY(-100%);
}
</style>
