<template>
    <ContentContainer :size="contentSize" tag="section">
        <div class="hero" data-cy="hero">
            <div class="hero-image-container">
                <div class="hero-image-container-inner">
                    <a :href="url" @click.prevent="$emit('openConvo', hero)">
                        <img :src="`${hero.image}?size=900`" :alt="hero.name" class="hero-image" data-cy="hero-image" />
                    </a>
                </div>
            </div>

            <div class="hero-content">
                <div class="hero-header">
                    <h2 class="hero-title" data-cy="hero-title">
                        <a :href="url" @click.prevent="$emit('openConvo', hero)">
                            Daily<span class="hero-title-section" data-cy="hero-title-section">Chat</span>
                        </a>
                    </h2>

                    <span class="hero-title-divider">|</span>

                    <h3 class="hero-subtitle" data-cy="hero-subtitle">
                        <a :href="url" @click.prevent="$emit('openConvo', hero)">
                            {{ hero.name }}
                        </a>
                    </h3>
                </div>
                <div class="hero-text">{{ hero.description }}</div>
                <AppButton
                    class="hero-button"
                    data-cy="hero-button"
                    :size="$options.ButtonSize.medium"
                    @click="$emit('openConvo', hero)"
                >
                    {{ data.buttonText }}
                </AppButton>
            </div>
        </div>
    </ContentContainer>
</template>

<script>
import AppButton from '@/components/AppButton';
import ContentContainer from '@/components/ContentContainer';
import { UserSource, ButtonVariant } from '@/enums';
import { ButtonSize } from '@/enums/buttons';
export default {
    name: 'AppHero',
    components: {
        AppButton,
        ContentContainer,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        contentSize: {
            type: String,
            required: true,
        },
    },
    computed: {
        hero() {
            return { ...this.data.items[0], userSource: UserSource.DailyChatHero };
        },
        url() {
            return (
                this.$router.resolve({
                    name: 'channels-slug-convoId',
                    params: { slug: this.hero.channel.slug, convoId: this.hero.id },
                }).href || ''
            );
        },
    },
    created() {
        this.$options.ButtonVariant = ButtonVariant;
        this.$options.ButtonSize = ButtonSize;
    },
};
</script>

<style scoped lang="scss">
.hero {
    display: flex;
    flex-direction: column;
    margin-bottom: 4rem;
    margin-top: 0.5rem;
    color: $color-misc-1;

    @include media-query(medium) {
        flex-direction: row;
        align-items: center;
        margin-top: 3.5rem;
        margin-bottom: 11rem;
    }

    .hero-button {
        display: none;
        @include media-query(medium) {
            display: block;
        }
    }
}

.hero-image-container {
    margin-bottom: 1.2rem;
    flex: 0 0 56%;

    @include media-query(medium) {
        margin-bottom: 0;
    }
}

.hero-image-container-inner {
    cursor: pointer;
    width: 100%;
    height: 0;
    position: relative;
    padding-top: #{203/357 * 100}#{'%'};

    @include media-query(medium) {
        padding-top: #{448/648 * 100}#{'%'};
    }
}

.hero-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
}

.hero-content {
    @include media-query(medium) {
        padding: 0 0 0 5rem;
    }

    @include media-query(large) {
        padding: 0 0 0 9rem;
    }
}

.hero-title,
.hero-subtitle {
    display: inline;
    cursor: pointer;

    @include media-query(medium) {
        display: block;
    }
}

.hero-title {
    color: $color-accent-1;

    @include media-query(medium) {
        margin-bottom: 1.875rem;
        font-size: 4rem;
    }
}

.hero-subtitle {
    @include media-query(medium) {
        font-size: $larger-font-size;
        line-height: 1.56;
    }
}

.hero-title-section {
    @include media-query(medium) {
        font-weight: $font-weight-light;
    }
}

.hero-title-divider {
    color: $color-accent-1;

    @include media-query(medium) {
        display: none;
    }
}

.hero-header {
    margin-bottom: 0.8rem;
    font-weight: $font-weight-bold;
    font-size: $large-font-size;
    line-height: 1.33;
    cursor: pointer;

    @include media-query(medium) {
        font-size: $larger-font-size;
    }
}

.hero-text {
    font-size: $smaller-font-size;
    line-height: 1.67;

    @include media-query(medium) {
        margin-bottom: 3rem;
        font-size: $large-font-size;
        line-height: 1.39;
    }
}
</style>
