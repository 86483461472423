<template>
    <div class="daily-chat-loader"></div>
</template>

<script>
export default {
    name: 'DailyChatLoader',
};
</script>

<style scoped lang="scss">
$loader-color-1: #008784 !default;
$loader-color-2: #57e0dd !default;

.daily-chat-loader {
    position: relative;
    width: 15.5rem;
    height: 15.5rem;

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        animation-duration: 0.8s;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
    }

    &::before {
        background: rgba($loader-color-1, 0.5);
        animation-name: beforeElementAnimation;
    }

    &::after {
        background: rgba($loader-color-2, 0.9);
        animation-name: afterElementAnimation;
    }

    @keyframes beforeElementAnimation {
        from {
            transform: scale(0.76);
        }
        to {
            transform: scale(1);
        }
    }

    @keyframes afterElementAnimation {
        from {
            transform: scale(0.66);
        }
        to {
            transform: scale(0.25);
        }
    }
}
</style>
