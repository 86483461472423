<template>
    <ContentContainer :size="contentSize" :class="`dc-subscribe dc-subscribe--${data.style}`" data-cy="dc-subscribe">
        <div class="dc-subscribe-inner">
            <div class="dc-header">
                <DailyChatLogo class="daily-page-dc-logo" :variant="headerVariant ? 'primary' : 'secondary'" />
                <div class="dc-subscribe-container">
                    <div class="dc-form-heading" data-cy="dc-subscribe-heading">
                        <h2 class="dc-title">{{ data.title }}</h2>
                        <p class="dc-text">{{ data.description }}</p>
                    </div>

                    <EmailSubmitForm
                        class="dc-form dc-form--default"
                        :is-loading="isLoading"
                        :join-button-text="data.buttonText"
                        :variant="$options.EmailSubmitFormVariant.Secondary"
                        @submit="onSubmit"
                    />

                    <EmailSubmitForm
                        class="dc-form dc-form--inline"
                        :variant="$options.EmailSubmitFormVariant.Secondary"
                        @submit="onSubmit"
                    >
                        <template v-slot="{ model, invalid }">
                            <InputGroup class="mb-2">
                                <AppInput
                                    :id="$uid('email')"
                                    ref="subscriberEmail"
                                    v-model="model.email"
                                    class="dc-form-input"
                                    :disabled="isLoading"
                                    type="text"
                                    variant="tertiary"
                                    background-color=""
                                    rules="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    label=""
                                    data-cy="email-submit-form-input"
                                />

                                <template #append>
                                    <AppButton
                                        class="dc-form-button"
                                        :is-loading="isLoading"
                                        type="submit"
                                        :disabled="invalid || !model.email"
                                        data-cy="email-submit-form-submit"
                                    >
                                        {{ data.buttonText }}
                                    </AppButton>
                                </template>
                            </InputGroup>
                        </template>
                    </EmailSubmitForm>

                    <footer v-if="headerVariant" class="dc-scroll">
                        <button
                            type="button"
                            class="dc-scroll-button mb-2"
                            aria-label="Scroll down"
                            data-cy="dc-subscribe-scroll"
                            @click="scrollDown"
                        >
                            <Icon name="chevron-down" :width="18" />
                        </button>

                        <p data-cy="dc-subscribe-scroll-text">{{ $t('dailyChatSubscribe.scrollText') }}</p>
                    </footer>
                </div>
            </div>

            <div v-if="headerVariant && data.image" class="dc-visual" data-cy="dc-subscribe-visual">
                <img :src="data.image" alt="DailyChat Subscribe Visual" />
            </div>
        </div>

        <div v-if="headerVariant" ref="scrollTo"></div>
    </ContentContainer>
</template>

<script>
import ContentContainer from '@/components/ContentContainer';
import EmailSubmitForm from '@/components/forms/EmailSubmitForm.vue';
import { InputGroup, AppInput } from '@/components/forms';
import DailyChatLogo from '@/components/daily-chat/DailyChatLogo';
import Icon from '@/components/Icon';
import AppButton from '@/components/AppButton';
import { mapState, mapActions } from 'vuex';
import { LoginSource, QueryParam, EmailSubmitFormVariant } from '@/enums';
import { auth as AuthService } from '@/auth';
import RouterService from '@/services/router.service';

export default {
    name: 'DailyChatSubscribe',
    components: {
        EmailSubmitForm,
        InputGroup,
        AppInput,
        Icon,
        AppButton,
        ContentContainer,
        DailyChatLogo,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        contentSize: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        ...mapState('auth', ['errors']),
        headerVariant() {
            return this.data.style === 'header';
        },
    },
    watch: {
        errors: {
            deep: true,
            immediate: true,
            handler(value) {
                if (value && value.length > 0) {
                    this.isLoading = false;
                }
            },
        },
    },
    mounted() {
        if (this.headerVariant) {
            this.$nuxt.$on('dailyChatSubscriberFocus', () => {
                this.$refs.subscriberEmail.$refs.input.focus();
            });
        }
    },
    created() {
        this.$options.EmailSubmitFormVariant = EmailSubmitFormVariant;
    },
    methods: {
        ...mapActions('auth', {
            handleDailyChatSubscribe: 'onDailyChatSubscribe',
        }),
        async onSubmit({ email }) {
            if (email) {
                this.isLoading = true;

                await this.handleDailyChatSubscribe({
                    email,
                    source: LoginSource.DailyChatSubscribe,
                    redirectPath: RouterService.getRedirectPath(this.$route, '/'),
                    referral: this.$queryParams(QueryParam.Referral),
                    recaptchaToken: await AuthService.getRecaptchaToken(window.grecaptcha),
                    metadata: {
                        utmCampaign: this.$queryParams(QueryParam.utmCampaign),
                        utmSource: this.$queryParams(QueryParam.utmSource),
                    },
                });

                this.isLoading = false;
                this.$analytics.trackEvent({ client: 'gtm', eventName: 'DailyChatSignupEmailSubmitted' });
            }
        },
        scrollDown() {
            this.$refs.scrollTo.scrollIntoView({ behavior: 'smooth' });
        },
    },
};
</script>

<style scoped lang="scss">
.dc-scroll {
    margin-top: auto;
    padding-top: 4rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: $color-accent-1;
    font-size: $smaller-font-size;
    font-weight: $font-weight-medium;

    @include media-query(medium) {
        display: none;
    }
}

.dc-scroll-button {
    border-radius: 50%;
    border: none;
    width: 4rem;
    height: 4rem;
    background-color: $color-white;
    color: $color-accent-1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dc-subscribe {
    &.dc-subscribe--header {
        min-height: calc(100vh - 4.5rem);
        background-color: color($palette, red, 600);
        color: $color-misc-1;

        @include media-query(medium) {
            min-height: auto;
            background-color: $color-misc-1;
            color: $color-white;
        }
    }

    &.dc-subscribe--footer {
        background-color: $color-misc-1;
        color: $color-white;
    }
}

.dc-subscribe--header ::v-deep .container__inner {
    padding: 0;
    min-height: inherit;

    @include media-query(medium) {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
    }
}

.dc-subscribe-inner {
    max-width: $main-max-width;
    margin: 0 auto;
    min-height: inherit;

    .dc-subscribe--header & {
        display: flex;
        flex-direction: column;

        @include media-query(medium) {
            padding-top: 4rem;
            padding-bottom: 6rem;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    }
}

.dc-subscribe-container {
    .dc-subscribe--header & {
        padding: 4rem 1.6rem 3rem;
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;
        background-color: $color-misc-1;
        color: $color-white;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        @include media-query(medium) {
            padding: 2rem 0;
        }
    }

    .dc-subscribe--footer & {
        padding: 2rem 0;
        text-align: center;

        @include media-query(medium) {
            padding-top: 5rem;
            padding-bottom: 5rem;
        }
    }

    @include media-query(large) {
        text-align: left;
    }
}

.dc-header {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.dc-visual {
    display: none;

    @include media-query(large) {
        display: block;
        width: 70%;
    }

    img {
        width: 100%;
        height: auto;
    }
}

.daily-page-dc-logo {
    text-align: center;

    .dc-subscribe--header & {
        padding: 1.2rem 0 2.3rem;
        color: $color-misc-1;

        @include media-query(medium) {
            color: $color-accent-1;
        }

        @include media-query(large) {
            text-align: left;
        }
    }

    .dc-subscribe--footer & {
        padding-top: 4rem;

        @include media-query(medium) {
            padding-top: 6rem;
        }
    }
}

.dc-title {
    margin-bottom: 1.6rem;
    font-size: 2.2rem;
    font-weight: $font-weight-semibold;
    line-height: 1.32;

    .dc-subscribe--header & {
        color: $color-accent-1;
        font-size: 4.4rem;
        margin-bottom: 4rem;
    }

    @include media-query(medium) {
        .dc-subscribe--header & {
            color: $color-white;
            margin-bottom: 2rem;
        }

        .dc-subscribe--footer & {
            font-size: 3rem;
        }
    }
}

.dc-text {
    line-height: 1.36;

    .dc-subscribe--header & {
        font-size: 1.7rem;
        margin-bottom: 5.6rem;

        @include media-query(medium) {
            margin-bottom: 4.5rem;
            font-size: 1.8rem;
            line-height: 1.56;
        }
    }

    .dc-subscribe--footer & {
        font-size: $small-font-size;
        margin-bottom: 2.4rem;

        @include media-query(medium) {
            margin-bottom: 4rem;
            font-size: 2rem;
        }
    }
}

.dc-form-heading {
    margin: 0 auto;

    @include media-query(medium) {
        max-width: 54rem;
    }

    .dc-subscribe--header & {
        text-align: left;

        @include media-query(medium) {
            text-align: center;
        }

        @include media-query(large) {
            margin: initial;
            text-align: left;
        }
    }
}

.dc-form {
    &--inline {
        display: none;
    }
    @include media-query(medium) {
        &-input {
            ::v-deep input {
                border-top-left-radius: 6rem;
                border-bottom-left-radius: 6rem;
            }
        }
        &-button {
            border-radius: 0;
            border-top-right-radius: 6rem;
            border-bottom-right-radius: 6rem;
            padding-left: 2.5rem;
            min-height: 4.4rem;
        }
    }
    .dc-subscribe--header & {
        text-align: center;

        @include media-query(medium) {
            max-width: 37rem;
        }

        @include media-query(large) {
            text-align: left;
        }
    }

    .dc-subscribe--header &--inline {
        @include media-query(large) {
            display: block;
        }
    }

    .dc-subscribe--header &--default {
        max-width: none;
        margin: 0;

        @include media-query(medium) {
            margin: 0 auto;
        }

        @include media-query(large) {
            display: none;
        }
    }

    .dc-subscribe--footer & {
        max-width: 37rem;
        margin: 0 auto;
    }
}
</style>
