import { IRegionDto } from '@/interfaces/dtos.interface';
import { Region } from '@/models';
import { ILocale } from '@/interfaces';

export default class RegionFactory {
    public static make(data: IRegionDto): Region {
        const region = new Region();
        region.countryCode = data.country_code;
        region.countryName = data.country_name;
        region.languageCode = data.language_code;
        region.languageName = data.language_name;
        region.id = `${data.language_code}-${data.country_code}`;
        region.value = `${data.country_name} (${data.language_name})`;

        return region;
    }

    public static makeFromLocale(data: ILocale): Region {
        const region = new Region();
        region.countryCode = data.country;
        region.id = data.code;
        region.value = data.title;

        return region;
    }
}
