import { ConvoListStyle, WidgetType } from '@/enums';
import Convo from './convo';
import Widget from './widget';

export default class Hero extends Widget<Convo> implements Partial<Convo> {
    title: string;
    subtitle: string;
    description: string;
    buttonText: string;
    image: string;
    style: ConvoListStyle;
    locale: string;
    sorting: string;

    constructor() {
        super();
        this.component = WidgetType.Hero;
        this.style = ConvoListStyle.Cards;
        this.title = '';
        this.subtitle = '';
        this.description = '';
        this.image = '';
        this.locale = '';
        this.buttonText = '';
        this.sorting = '';
    }
}
