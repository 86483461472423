// TODO: After turning on the redesigned button, remove all styles except: Flat, Shaded, Link, Login, Outline, OutlineV2
export enum ButtonStyle {
    Default = 'default',
    Outline = 'outline',
    OutlineV2 = 'outline-v2',
    WithoutBorder = 'without-border',
    Warning = 'warning',
    Primary = 'primary',
    PrimaryLink = 'primary-link',
    Facebook = 'facebook',
    Google = 'google',
    Flat = 'flat',
    Shaded = 'shaded',
    Link = 'link',
}
