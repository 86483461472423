import { WidgetType } from '@/enums/widgetType.enum';
import { IPaging } from '@/interfaces/paging.interface';
import { ConvoListStyle, ConvoCardDescription } from '@/enums';
import Convo from './convo';
import Widget from './widget';

export default class ConvoList extends Widget<Convo> implements Partial<IPaging<Convo>> {
    style: ConvoListStyle;
    cardDescription: ConvoCardDescription;
    loadMoreEnabled: boolean;
    loadMoreButtonText: string;
    excludeChannels: string[];
    includeChannels: string[];
    excludeTags: string[];
    excludeConvos: string[];
    includeTags: string[];
    excludeTypes: string[];
    includeTypes: string[];
    sortingFields: string;
    items: Convo[];
    locale: string;
    page: number;
    size: number;
    total: number;
    id: string;
    skip: number;

    constructor() {
        super();
        this.component = WidgetType.ConvoList;
        this.style = ConvoListStyle.List;
        this.cardDescription = ConvoCardDescription.ConvoDescription;
        this.loadMoreEnabled = false;
        this.loadMoreButtonText = 'Load more';
        this.excludeChannels = [];
        this.includeChannels = [];
        this.excludeTags = [];
        this.excludeConvos = [];
        this.includeTags = [];
        this.excludeTypes = [];
        this.includeTypes = [];
        this.sortingFields = '';
        this.items = [];
        this.locale = '';
        this.page = 1;
        this.size = 5;
        this.total = 0;
        this.id = '';
        this.skip = 0;
    }
}
