import { IAuthChallengeResponse, ISocialLoginResponse, IAuthChallenge, IAuthSocial } from '@/interfaces/auth.interface';
import { IdentityProvider } from '@inconvo/types/enums';
import { UserStatus } from '@/enums';
import authMetadataFactory from './authMetadata.factory';
export default class authChallengeResponseFactory {
    public static make(data: IAuthChallengeResponse | null): IAuthChallenge {
        const response = {} as IAuthChallenge;
        if (data) {
            response.userStatus = data.user_status;
            response.countryCode = data.country_code;
            response.locale = data.locale;
            response.redirectPath = data.redirect_path;
            response.identityProvider = data.identity_id as IdentityProvider;
            response.authResult = data.auth_result;
            response.user = data.user;
            response.metadata = authMetadataFactory.makeResponse(data.metadata);
            response.isNewUser = data.is_new_user;
            response.personId = data.yg_person_id || '';
        }
        return response;
    }

    public static makeSocial(data: ISocialLoginResponse | null): IAuthSocial {
        const response = {
            userStatus: UserStatus.anonymous,
        } as IAuthChallenge;

        if (data) {
            response.userStatus = data.user_status || UserStatus.anonymous;
            response.countryCode = data.country_code || '';
            response.locale = data.locale || '';
            response.isNewUser = data.is_new_user;
            response.personId = data.yg_person_id || '';
        }
        return response;
    }
}
