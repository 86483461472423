<template>
    <span v-if="text" class="pill" :style="getColorStyle">{{ text }}</span>
</template>
<script>
export default {
    name: 'Media',
    components: {},
    props: {
        text: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: '',
        },
    },
    computed: {
        getColorStyle() {
            return this.color ? `background: ${this.color}` : '';
        },
    },
};
</script>
<style scoped lang="scss">
.pill {
    color: $white;
    font-size: 10px;
    padding: 2px 6px;
    border-radius: 9px;

    @include media-query(medium) {
        font-size: 12px;
    }
}
</style>
