import { NuxtCookies } from 'cookie-universal-nuxt';
import { Route } from 'vue-router';
import { getWeightedRandom } from '@/services/Utils';
import { IAbTestExperiment } from '@/interfaces';
import { Cookie } from '@/enums';
import { IWidgetDto } from '@/interfaces/dtos.interface';
import config from '@/config';

export default class AbTestService {
    private currentExperiments: IAbTestExperiment[];
    private storedExperiments: IAbTestExperiment[];
    private otherExperiments: IAbTestExperiment[];
    private cookieService: NuxtCookies;
    private route: Route | undefined;
    private storyblokSelectedVariantId: [];

    constructor(cookieService: NuxtCookies, route?: Route) {
        this.cookieService = cookieService;
        this.route = route;
        this.storedExperiments = this.getCookie() || [];
        this.otherExperiments = this.storedExperiments.filter((item) => item.page !== route?.name);
        this.currentExperiments = [];
        this.storyblokSelectedVariantId = [];
    }

    public addExperiment(experiment: IAbTestExperiment): void {
        this.currentExperiments.push({ ...experiment, page: this.route?.name?.toString() });
    }

    public setCookie(): void {
        this.storedExperiments = [...this.otherExperiments, ...this.currentExperiments];
        this.cookieService.set(Cookie.AbTestExperiment, this.storedExperiments, {
            expires: new Date(Date.now() + config.experimentsCookieExpiry),
        });
    }

    public setStoryblokSelectedVariantId(experimentId: string, uid: string) {
        this.storyblokSelectedVariantId[experimentId] = uid;
    }

    public getVariantIndex(experimentId: string, variants: IWidgetDto[]): number | undefined {
        const experiment = this.storedExperiments.find((item) => item.id === experimentId);

        if (this.storyblokSelectedVariantId[experimentId]) {
            return variants.findIndex((o) => o._uid === this.storyblokSelectedVariantId[experimentId]);
        }

        if (
            typeof experiment?.variantId === 'number' &&
            variants[experiment.variantId].ab_test_variant_weight === experiment.weight
        ) {
            return experiment.variantId;
        }

        return getWeightedRandom(
            variants.map((variant: IWidgetDto, index: number) => ({
                name: variant._uid,
                index,
                weight: variant.ab_test_variant_weight || 100, // setting default weight as 100
            })),
        )?.index;
    }

    public setExperimentsOnGtmDataLayer(): void {
        const experiments = this.storedExperiments.filter((item) => item.page === this.route?.name);
        if (experiments.length) {
            const experimentsString = experiments.reduce((acc, curr) => {
                acc += acc ? '!' : '';
                acc += `${curr.id}.${curr.variantId}`;
                return acc;
            }, '');

            (window as any).dataLayer = (window as any).dataLayer || [];
            (window as any).dataLayer.push({ experiments: experimentsString });
        }
    }

    private getCookie(): IAbTestExperiment[] {
        return this.cookieService.get(Cookie.AbTestExperiment);
    }
}
