import { ChatWidgetChannel } from '@/models';

export default class ChatWidgetChannelFactory {
    public static make(data: any): ChatWidgetChannel {
        const channel = new ChatWidgetChannel();

        channel.code = data.code;
        channel.name = data.name;
        channel.slug = data.slug;
        channel.tagLine = data.tagLine || '';
        channel.isStandalone = data.isStandalone || false;
        channel.sharingTitle = data.sharingTitle;
        channel.sharingDescription = data.sharingDescription;
        channel.squareImage = data.squareImage || '';
        channel.activeConvoId = +data.activeConvoId;
        channel.currentThreadId = data.currentThreadId;

        return channel;
    }
}
