import { IChannel, ICategory, IChannelLandingPage } from '@/interfaces';

export class Channel implements IChannel {
    id: string;
    code: string;
    name: string;
    slug: string;
    squareImage: string;
    landscapeImage: string;
    portraitImage: string;
    tagLine: string;
    description: string;
    showMemberCount: boolean;
    totalMembers: number;
    tags: string[];
    sharingTitle: string;
    sharingDescription: string;
    isStandalone: boolean;
    color: string;
    activeUserCount: number;
    landingPage?: IChannelLandingPage;
    category?: ICategory;

    constructor() {
        this.id = '';
        this.code = '';
        this.name = '';
        this.slug = '';
        this.squareImage = '';
        this.landscapeImage = '';
        this.portraitImage = '';
        this.tagLine = '';
        this.description = '';
        this.showMemberCount = false;
        this.totalMembers = 0;
        this.tags = [];
        this.sharingTitle = '';
        this.sharingDescription = '';
        this.isStandalone = false;
        this.color = '';
        this.activeUserCount = 0;
    }
}
