<template>
    <footer class="yougov-footer">
        <div class="content">
            <ul class="icons">
                <li v-for="(item, index) in socialLinks" :key="index" class="icon">
                    <a :href="item.link" class="icon-link" target="_blank">
                        <Icon :name="`social-icons/${item.name}`" :width="16" :height="16" />
                    </a>
                </li>
            </ul>
            <p class="copy">
                At the heart of our company is a global online community, where millions of people and thousands of
                political, cultural and commercial organisations engage in a continuous conversation about their
                beliefs, behaviours and brands.
            </p>
            <ul class="links">
                <li v-for="(item, index) in links" :key="index" class="item">
                    <a :href="item.link" class="link" target="_blank">{{ item.text }}</a>
                </li>
            </ul>
        </div>
        <p class="copyright">Copyright © {{ copyrightYear }} YouGov PLC. All Rights Reserved.</p>
    </footer>
</template>

<script>
import Icon from '@/components/Icon';

export default {
    name: 'YougovFooter',
    components: {
        Icon,
    },
    data() {
        return {
            socialLinks: [
                { name: 'facebook', link: 'https://www.facebook.com/YouGov' },
                { name: 'twitter', link: 'https://twitter.com/YouGov' },
                { name: 'instagram', link: 'https://www.instagram.com/yougovuk/' },
            ],
            links: [
                { text: 'Privacy policy', link: '/privacy' },
                { text: 'Terms and conditions', link: '/terms-and-conditions' },
            ],
            copyrightYear: new Date().getFullYear(),
        };
    },
};
</script>
<style lang="scss" scoped>
.yougov-footer {
    font-family: $font-heading;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 1220px;
    height: 100%;
    font-size: 14px;
    line-height: 1.5;
    padding: 0 16px;
    margin: 40px auto 16px;

    @include media-query(medium) {
        margin: 48px auto 56px;
    }
}

.content {
    color: $yougov-footer-color;
    letter-spacing: 0.015em;
    line-height: 1.5;
    padding: 32px 0 24px 0;
    border-top: 1px solid $yougov-footer-border-color;
    border-bottom: 1px solid $yougov-footer-border-color;

    @include media-query(medium) {
        padding-bottom: 32px;
    }
}

.icons {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    list-style: none;
}

.icon-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 16px;
    padding: 0;
    border-radius: 50%;
    background-color: $yougov-footer-background;

    &:hover {
        background-color: $yougov-footer-color;
    }

    &:hover svg {
        color: $white;
    }
}

.copy {
    margin: 24px 0 16px;
}

.item {
    position: relative;
    height: 16px;
    margin-bottom: 8px;
}

.link {
    position: relative;
    transition: color 0.25s ease;
    color: $yougov-footer-color;
    text-decoration: none;
}

.links {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;

    & .item:not(:last-child) {
        padding-right: 16px;
        margin-right: 16px;
    }

    & .item:not(:last-child):after {
        content: '|';
        position: absolute;
        right: 0;
        left: unset;
    }

    & .link:after {
        content: '';
        position: absolute;
        bottom: -6px;
        left: 0;
        width: 100%;
        height: 1px;
        background: transparent;
        transition: background 0.25s ease, bottom 0.25s ease;
    }

    & .link:hover:after {
        bottom: -4px;
        background: $yougov-footer-color;
    }
}

.copyright {
    margin-top: 32px;
    margin-bottom: 30px;
    color: $yougov-footer-copyright-color;
}
</style>
