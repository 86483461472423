<template>
    <transition name="nav-animation">
        <div v-if="isOpen" v-click-outside="onClickOutside" class="nav-wrapper">
            <div v-if="displayName" class="nav-user-display-name">
                <span class="nav-user-display-name-text">
                    {{ displayName }}
                </span>
            </div>
            <nav class="nav" :aria-labelledby="navButtonId">
                <ul class="nav-list">
                    <template v-for="(node, idx) in nodes">
                        <li
                            v-if="!node.requiresLogin || (node.requiresLogin && isLoggedIn)"
                            :key="idx"
                            class="nav-item"
                        >
                            <a
                                v-if="node.external || node.action"
                                class="nav-link"
                                target="_blank"
                                rel="noopener"
                                :data-cy="node.name"
                                :href="node.routeLink"
                                @click="onClick($event, node)"
                            >
                                <Icon class="nav-icon" :name="node.icon" :width="null" :height="null" />
                                <span>{{ node.label }}</span>
                            </a>
                            <router-link
                                v-else
                                :to="node.routeName"
                                class="nav-link"
                                :data-cy="node.name"
                                @click.native="onClick($event, node)"
                            >
                                <Icon class="nav-icon" :name="node.icon" :width="null" :height="null" />
                                <span>{{ node.label }}</span>
                            </router-link>
                        </li>
                    </template>
                </ul>
            </nav>
        </div>
    </transition>
</template>

<script>
import Icon from '@/components/Icon';

export default {
    name: 'AppNav',
    components: {
        Icon,
    },
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        isLoggedIn: {
            type: Boolean,
            default: false,
        },
        displayName: {
            type: String,
            default: null,
        },
        navButtonId: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            nodes: [
                {
                    name: 'menu-home',
                    label: this.$i18n.t('menu.home'),
                    icon: 'nav-home',
                    routeName: '/',
                },
                {
                    name: 'menu-preferences',
                    label: this.$i18n.t('menu.preferences'),
                    icon: 'nav-preferences',
                    requiresLogin: true,
                    routeName: 'user-preferences',
                },
                {
                    name: 'menu-logout',
                    label: this.$i18n.t('menu.logout'),
                    icon: 'nav-logout',
                    routeLink: 'logout',
                    requiresLogin: true,
                    action: () => this.$emit('logout'),
                },
            ],
        };
    },
    methods: {
        onClick(event, node) {
            this.$emit('toggleMenu', false);

            if (node.action) {
                event.preventDefault();
                node.action();
            }
        },
        onClickOutside(event) {
            if (!this.navButtonId || !event.composedPath()?.find((item) => item.id === this.navButtonId)) {
                this.$emit('toggleMenu', false);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
$nav-animation-duration: 250ms !default;
$nav-bg-color: #fff !default;
$nav-user-name-bg-color: #f7f6fa !default;
$nav-user-indicator-bg-color: #29cdca !default;
$nav-link-hover-bg-color: #e9fafa !default;

.nav-wrapper {
    min-width: 20rem;
    max-width: 22rem;
    background-color: $nav-bg-color;
    box-shadow: 0 0.3rem 0.6rem 0 rgba(0, 0, 0, 0.16);
}

.nav-user-display-name {
    display: flex;
    align-items: center;
    min-height: 4.5rem;
    padding: 1.4rem 1.2rem;
    background-color: $nav-user-name-bg-color;
    font-size: $small-font-size;
    font-weight: $font-weight-semibold;
    line-height: 1.3em;

    &::before {
        flex: 0 0 auto;
        content: '';
        display: block;
        width: 1rem;
        height: 1rem;
        margin-right: 1.2rem;
        border-radius: 1rem;
        background-color: $nav-user-indicator-bg-color;
    }
}

.nav-user-display-name-text {
    overflow: hidden;
    text-overflow: ellipsis;
}

.nav {
    padding: 0.6rem 0;
}

.nav-link {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 3rem;
    padding: 0.8rem 1.2rem;
    border-top: 2px solid $nav-bg-color;
    border-bottom: 2px solid $nav-bg-color;
    font-size: $small-font-size;
    font-weight: $font-weight-medium;
    color: $color-misc-1;
    transition: background-color 0.2s;

    &:hover {
        background-color: $nav-link-hover-bg-color;
    }
}

.nav-icon {
    display: block;
    margin-right: 1.2rem;
    font-size: 1rem;
}

.nav-animation-enter-active {
    animation: nav-animation $nav-animation-duration;
}

.nav-animation-leave-active {
    animation: nav-animation reverse $nav-animation-duration;
}

@keyframes nav-animation {
    0% {
        opacity: 0;
        transform: translateY(-20%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}
</style>
