export default class Region {
    id: string;
    value: string;
    countryCode: string;
    countryName: string;
    languageCode: string;
    languageName: string;

    constructor() {
        this.id = '';
        this.value = '';
        this.countryCode = '';
        this.countryName = '';
        this.languageCode = '';
        this.languageName = '';
    }
}
