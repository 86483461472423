<template>
    <button class="button-reset chat-button" @click="$emit('click', $event)">
        <Icon name="chat" :width="30" :height="28" />
    </button>
</template>

<script>
import Icon from '@/components/Icon';

export default {
    name: 'ChatButton',
    components: {
        Icon,
    },
};
</script>

<style lang="scss" scoped>
.chat-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 55px;
    height: 55px;
    border-radius: 55px;
    background-color: $salmon-dark;
    color: $default-light-color;
    box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.3);
}
</style>
