export enum StorySlug {
    Metadata = 'global/metadata',
    DefaultLayout = 'global/default-layout',
    About = 'about',
    Convos = 'convos',
    Privacy = 'privacy',
    TermsAndConditions = 'terms-and-conditions',
    Sweepstakes = 'sweepstakes',
    ConvoV3 = 'convos-v3',
    DailyChat = 'daily-chat',
}
