<template>
    <div class="layout layout--chat">
        <NuxtChild :key="$route.fullPath" :convo-metadata="convoMetadata" />
        <ModalContainer
            v-if="activeModal.type !== null"
            :modal-type="activeModal.type"
            :backdrop-style="modal.props.backdropStyle"
            :closable="modal.props.closable && modal.props.closable === true"
            :padding-size="modal.props.paddingSize"
            :is-rounded="modal.props.isRounded"
            :shadow="modal.props.shadow"
            :close-callback="modal.props.closeCallback"
            :on-before-enter-callback="modal.props.onBeforeEnterCallback"
            :on-after-leave-callback="modal.props.onAfterLeaveCallback"
            @afterClose="toggleModal({ type: null })"
        >
            <template v-slot:modal="{ close }">
                <Modals
                    :modal="modal"
                    @userLogin="onUserSignupAction"
                    @toggleModal="toggleModal({ type: $event })"
                    @close="close"
                    @resendCode="onResendCode"
                    @userAuthChallenge="onUserAuthChallenge"
                    @updateUserAccount="onUpdateUserAccount"
                    @userSubscribe="onUserSubscribe"
                    @updateModal="onUpdateModal"
                    @confirmCountryOfResidence="onConfirmCountryOfResidence"
                />
            </template>
        </ModalContainer>
        <SystemNotifications :notifications="notifications" />
        <ChatClient
            :channel="activeChannel"
            :show-placeholder="showChatPlaceholder"
            :show-back-button="showBackButton"
        />
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import config from '@/config';
import { EmailValidationStatus, StorySlug, QueryParam } from '@/enums';
import LayoutMixin from '@/mixins/LayoutMixin';
import ModalsMixin from '@/mixins/ModalsMixin';
import SystemNotifications from '@/components/SystemNotifications';
import ChatClient from '@/components/ChatClient';
import ContentService from '@/services/content.service';
import MetadataService from '@/services/metadata.service';
import { auth as AuthService } from '@/auth';
import AbTestService from '@/services/ab-test.service';

export default {
    components: {
        ModalContainer: () => import('@/components/ModalContainer.vue'),
        Modals: () => import('@/components/modals/Modals'),
        SystemNotifications,
        ChatClient,
    },
    mixins: [ModalsMixin, LayoutMixin],
    async fetch() {
        try {
            const abTestService = new AbTestService(this.$cookies);
            const contentService = new ContentService(this.$storyapi, abTestService, this.$logger);
            const story = await contentService.getStory({
                story: StorySlug.Metadata,
                version: this.$storyblokHelper.version,
                locale: this.$i18n.locale,
                hasFallback: true,
            });

            const convoId = this.$route.params.convoId;

            const metadata = contentService.makeMetadataModel(story.content);
            const metadataService = new MetadataService(
                {
                    defaultMetatags: metadata.metatags,
                    channel: this.activeChannel,
                    convoId,
                    shareMetadata: this.$route.query.shareMetadata,
                    i18nSeo: this.$nuxtI18nSeo(),
                    url: `${config.host}${this.$route.fullPath}`,
                    title: metadata.siteTitle,
                },
                this.$logger,
                this.$route,
            );
            if (convoId) {
                this.convoMetadata = await metadataService.getConvoMetadata(convoId);
            }

            this.metadata = metadataService.getMetadata({
                isCookieBannerEnabled: this.showCookieBanner,
                fetchedConvoMetadata: this.convoMetadata,
            });
        } catch (error) {
            this.$logger.error({ msg: 'error.chatLayout.fetch', error });
        }
    },
    data() {
        return {
            metadata: {},
            convoMetadata: {},
            showChatPlaceholder: true,
        };
    },
    computed: {
        ...mapState('auth', ['errors', 'user']),
        ...mapState('notifications', ['notifications']),
        ...mapState('main', ['activeModal', 'modalIframeUrl', 'appOptions']),
        ...mapState('channels', ['activeChannel']),
        ...mapGetters('auth', ['emailValidationStatus']),
        ...mapGetters('channels', ['channelData']),
        ...mapGetters('main', ['showCookieBanner']),
        showBackButton() {
            return this.appOptions?.options?.chatHeader?.showBackButton;
        },
    },
    mounted() {
        this.$chatWidget.addEventListener('started', () => {
            setTimeout(() => {
                this.showChatPlaceholder = false;
            }, 1000);
        });
    },
    methods: {
        ...mapActions('auth', {
            onUserAuthChallenge: 'onUserAuthChallenge',
            onUserSignupAction: 'onUserSignup',
            updateUserAccount: 'updateUserAccount',
            onUserResendCode: 'onUserResendCode',
            onConfirmCountryOfResidence: 'onConfirmCountryOfResidence',
            onUserSubscribeAction: 'onUserSubscribe',
        }),
        ...mapActions('main', ['toggleModal']),
        ...mapActions('channels', ['getChannel']),
        async onUpdateUserAccount(data) {
            try {
                await this.updateUserAccount(data);
            } catch (error) {
                // TODO: use logger
                // Vue.$log.warn(error);
                console.warn(error);
            }
            if (this.user.attributes.name) {
                this.toggleModal();
            }
        },
        async onResendCode() {
            if (this.user && this.user.username) {
                await this.onUserResendCode({
                    username: this.user.username,
                    redirectPath: this.$queryParams(QueryParam.RedirectPath),
                    referral: this.$queryParams(QueryParam.Referral),
                    recaptchaToken: await AuthService.getRecaptchaToken(window.grecaptcha),
                });
            }
        },
        async onUserSubscribe({ email, metadata }) {
            try {
                const subscriberId = await this.onUserSubscribeAction({
                    email,
                    metadata,
                });
                if (subscriberId) {
                    this.$chatWidget.setSubscribedUser({ subscriberId });
                }
                if (this.emailValidationStatus === EmailValidationStatus.Valid) {
                    this.startChat();
                    this.toggleModal();
                }
            } catch (error) {
                // TODO: use logger
                // this.$log.warn(error);
                console.warn(error);
            }
        },
        startChat(convoId = null) {
            this.$chatWidget.startChat({
                channel: this.channelData.slug,
                convo: this.$route.params.convoId || convoId,
            });
        },
    },
    head() {
        return this.metadata;
    },
};
</script>

<style lang="scss" scoped>
.layout {
    &--chat {
        height: 100%;

        @include media-query(large) {
            background-color: $backdrop-color; //fallback
            background-image: url('../assets/images/chat-background-pattern.svg');
        }
    }
}
</style>
