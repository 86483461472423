<template>
    <a :href="url" class="convo-list-item" @click.prevent="$emit('click', $event)">
        <img :src="`${item.image}?size=500`" :alt="item.name" class="convo-list-item-image" />

        <div class="convo-list-item-content">
            <h3 class="convo-list-item-title">{{ item.name }}</h3>
            <p class="convo-list-item-description">{{ item.description }}</p>
            <div v-if="item.relativeShowFrom" class="published-date">
                <Icon name="published" class="published-date-icon" :width="null" :height="null" />
                <span>{{ item.relativeShowFrom }}</span>
            </div>
        </div>
    </a>
</template>
<script>
import Icon from '@/components/Icon';

export default {
    name: 'ConvoListItem',
    components: { Icon },
    props: {
        item: {
            type: Object,
            default: null,
        },
        url: {
            type: String,
            required: true,
        },
    },
};
</script>
<style scoped lang="scss">
.convo-list-item {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.convo-list-item-content {
    padding-left: 1.2rem;

    @include media-query(medium) {
        padding-left: 6rem;
    }

    @include media-query(large) {
        padding-left: 11rem;
    }
}

.convo-list-item-title {
    font-size: $small-font-size;
    font-weight: $font-weight-semibold;
    line-height: 1.23;

    @include media-query(medium) {
        font-size: $larger-font-size;
        font-weight: $bold-font-weight;
        line-height: 1.56;
    }
}

.convo-list-item-description {
    margin-top: 1.2rem;
    font-size: $smaller-font-size;
    font-weight: $font-weight-light;
    line-height: 1.42;
    color: $secondary-text-color;

    @include media-query(medium) {
        margin-top: 2rem;
        font-size: $large-font-size;
        font-weight: $default-font-weight;
        line-height: 1.33;
    }
}

.convo-list-item-image {
    object-fit: cover;
    flex: 0 0 16rem;
    overflow: hidden;
    height: 13.3rem;

    @include media-query(medium) {
        flex: 0 0 35%;
        width: 35%;
        min-width: 30rem;
        height: 26rem;
    }
}

.published-date {
    display: flex;
    align-content: center;
    align-items: center;
    margin-top: 1.4rem;
    font-family: $font-heading;
    font-size: $smaller-font-size;
    font-weight: $font-weight-medium;
    line-height: 1;
    color: map-get($newPalette, shades, 800);
    white-space: nowrap;

    @include media-query(medium) {
        margin-top: 2.8rem;
        font-size: $large-font-size;
    }

    &-icon {
        margin-right: 0.5em;
    }
}
</style>
