<template>
    <div class="testimonial" :class="`testimonial--${variant}`">
        <div class="testimonial-image-wrapper">
            <div class="testimonial-image">
                <img :src="image" :alt="name" />
            </div>
        </div>

        <div class="testimonial-content">
            <p class="testimonial-text">{{ testimonial }}</p>

            <h4 class="testimonial-name">{{ name }}</h4>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppTestimonial',
    props: {
        image: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        testimonial: {
            type: String,
            required: true,
        },
        variant: {
            type: String,
            default: 'even',
            validator: (alignment) => ['even', 'odd'].includes(alignment),
        },
    },
};
</script>

<style scoped lang="scss">
.testimonial {
    margin-top: 2rem; // Compensate for transform of image
    position: relative;
    border-radius: 1px;
    border: 1px solid;

    &--odd {
        border-color: color($palette, red, 400);
    }

    &--even {
        border-color: $color-primary-1;
    }

    @include media-query(medium) {
        margin-top: 0;
        display: flex;
        border: none;

        &--even {
            flex-direction: row-reverse;
        }
    }
}

.testimonial-image-wrapper {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    height: 4rem;

    @include media-query(medium) {
        transform: none;
        position: initial;
        left: auto;
        height: 20rem;
        width: 20rem;
        padding: 3rem;

        .testimonial--even & {
            background-color: color($palette, violet, 400);
        }

        .testimonial--odd & {
            background-color: color($palette, red, 400);
        }
    }
}

.testimonial-image,
.testimonial-image img {
    width: 100%;
    height: 100%;
}

.testimonial-content {
    padding: 2.9rem 1rem 1.2rem;
    text-align: center;
    font-family: $font-body;
    flex: 1;

    @include media-query(medium) {
        padding: 3rem 5rem;

        .testimonial--odd & {
            text-align: left;
        }

        .testimonial--even & {
            text-align: right;
        }
    }
}

.testimonial-text {
    margin-bottom: 1rem;
    font-size: $large-font-size;
    line-height: 1.33;
    font-style: italic;

    @include media-query(medium) {
        margin-bottom: 2rem;
        font-size: 2.4rem;
    }
}

.testimonial-name {
    font-size: $small-font-size;
    font-weight: $font-weight-medium;
    line-height: 1.3;

    .testimonial--odd & {
        color: $color-accent-1;
    }

    .testimonial--even & {
        color: $color-primary-1;
    }

    @include media-query(medium) {
        font-size: 2.2rem;
        font-weight: $font-weight-regular;
    }
}
</style>
