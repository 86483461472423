import { ImagePosition } from '@/enums';
import { IAdditionalInfo } from '@/interfaces';

export default class MediaObject {
    text: string;
    image: string;
    imagePosition: ImagePosition;
    additionalInfo: IAdditionalInfo;

    constructor() {
        this.text = '';
        this.image = '';
        this.imagePosition = ImagePosition.Left;
        this.additionalInfo = {} as IAdditionalInfo;
    }
}
