import { WidgetType } from '@/enums/widgetType.enum';
import { StoryData } from '@/types/storyblok';
import {
    IAbTestDto,
    IConvoListDto,
    IDailyChatOpinionDto,
    IHeroDto,
    IHeroSubscriberDto,
    IMediaObjectDto,
    IWidgetDto,
} from '@/interfaces/dtos.interface';
import { IWidgetsData } from '@/interfaces/widget.interface';
import { ConvoListStyle } from '@/enums';
import { IAbTestExperiment } from '@/interfaces';
import AbTestService from '@/services/ab-test.service';
import Page from '../page';
import ConvoListFactory from './convoList.factory';
import MediaObjectFactory from './mediaObject.factory';
import HeroFactory from './hero.factory';
import HeroSubscriberFactory from './heroSubscriber.factory';
import DailyChatOpinionFactory from './dailyChatOpinion.factory';

export default class PageFactory {
    public static make(
        abTestService: AbTestService,
        story: StoryData,
        widgetsData: IWidgetsData,
        sections: string[],
    ): Page {
        const page = new Page();
        page._editable = story.content._editable;
        page.id = story.id;

        const widgetFactoryMap = {
            [WidgetType.ConvoList]: (widget: IConvoListDto) => ConvoListFactory.make(widget, widgetsData),
            [WidgetType.MediaObject]: (widget: IMediaObjectDto) => MediaObjectFactory.make(widget),
            [WidgetType.Hero]: (widget: IHeroDto) => HeroFactory.make(widget, widgetsData),
            [WidgetType.HeroSubscriber]: (widget: IHeroSubscriberDto) => HeroSubscriberFactory.make(widget),
            [WidgetType.DailyChatOpinion]: (widget: IDailyChatOpinionDto) => DailyChatOpinionFactory.make(widget),
        };

        for (const section of sections) {
            page.sections[section] = {};
            page.sections[section].widgets = [];

            for (const widget of story.content[section] as IWidgetDto[]) {
                if (
                    widget.component === WidgetType.ConvoList &&
                    (widget as IConvoListDto).style === ConvoListStyle.Combo
                ) {
                    const convoListComboListData = PageFactory.getConvoListComboList(
                        widget as IConvoListDto,
                        widgetsData,
                    );
                    if (convoListComboListData) {
                        page.sections[section].widgets.push(`list-${widget._uid}`);
                        page.widgetsData[`list-${widget._uid}`] = convoListComboListData;
                    }

                    const convoListComboGridData = PageFactory.getConvoListComboGrid(
                        widget as IConvoListDto,
                        widgetsData,
                    );
                    if (convoListComboGridData) {
                        page.sections[section].widgets.push(widget._uid);
                        page.widgetsData[widget._uid] = convoListComboGridData;
                    }
                } else if (widget.component === WidgetType.Hero && !widgetsData[widget._uid]) {
                    continue;
                } else if (widget.component === WidgetType.AbTest) {
                    const abTestWidget = widget as IAbTestDto;
                    const variantId = abTestService.getVariantIndex(abTestWidget.experimentId, abTestWidget.variants);

                    // if variantId is undefined, no widget will be shown.
                    if (variantId === undefined) {
                        continue;
                    }
                    const variant = abTestWidget.variants[variantId];

                    const experiment: IAbTestExperiment = {
                        id: abTestWidget.experimentId,
                        variantId,
                        weight: variant.ab_test_variant_weight || 0,
                    };
                    abTestService.addExperiment(experiment);

                    page.widgetsData[abTestWidget._uid] = abTestWidget;

                    if (widgetFactoryMap[variant.component]) {
                        page.sections[section].widgets.push(variant._uid);
                        page.widgetsData[variant._uid] = widgetFactoryMap[variant.component](variant);
                    }
                } else if (widgetFactoryMap[widget.component]) {
                    page.sections[section].widgets.push(widget._uid);
                    page.widgetsData[widget._uid] = widgetFactoryMap[widget.component](widget);
                }
            }
        }

        return page;
    }

    public static makeWidget(widgetDto: IWidgetDto): IWidgetsData {
        const widgetFactoryMap = {
            [WidgetType.MediaObject]: (widget: IMediaObjectDto) => MediaObjectFactory.make(widget),
            [WidgetType.HeroSubscriber]: (widget: IHeroSubscriberDto) => HeroSubscriberFactory.make(widget),
            [WidgetType.DailyChatOpinion]: (widget: IDailyChatOpinionDto) => DailyChatOpinionFactory.make(widget),
        };
        return widgetFactoryMap[widgetDto.component](widgetDto);
    }

    static getConvoListComboList(widget: IConvoListDto, widgetsData: IWidgetsData, numberOfListItems: number = 4) {
        if (!widgetsData[widget._uid]?.items) {
            return;
        }

        const list = {
            _uid: widget._uid,
            style: ConvoListStyle.List,
            number_of_items: `${numberOfListItems}`,
            card_description: widget.card_description,
        } as Partial<IConvoListDto>;
        const data = {
            [widget._uid]: {
                page: 1,
                size: numberOfListItems,
                total: numberOfListItems,
                items: widgetsData[widget._uid].items.slice(0, numberOfListItems),
            },
        };
        return ConvoListFactory.make(list as IConvoListDto, data);
    }

    static getConvoListComboGrid(widget: IConvoListDto, widgetsData: IWidgetsData, numberOfListItems: number = 4) {
        if (!widgetsData[widget._uid]?.items) {
            return;
        }

        const grid = { ...(widget as IConvoListDto) };
        grid.style = ConvoListStyle.Grid;
        grid.skip = numberOfListItems.toString();
        grid.number_of_items = `${parseInt(grid.number_of_items, 10) - numberOfListItems}`;
        const data = {
            [widget._uid]: {
                ...widgetsData[widget._uid],
                items: widgetsData[widget._uid].items.slice(numberOfListItems),
            },
        };
        return ConvoListFactory.make(grid as IConvoListDto, data);
    }
}
