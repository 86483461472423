<template>
    <button
        class="button-reset chat-bubble"
        data-cy="chat-bubble"
        :class="{ 'chat-bubble--active': isActive, 'chat-bubble--closable': isClosable }"
        @click="$emit('click', $event)"
    >
        <span
            v-if="isActive"
            key="active"
            v-tooltip="{ position: $options.Position.Top, content: $t('chatHeader.minimise') }"
            class="chat-bubble-image"
            :style="{ backgroundImage: `url(${image}?size=120)` }"
            data-cy="chat-bubble-minimise"
        >
        </span>
        <span v-else key="not-active" class="chat-bubble-image" :style="{ backgroundImage: `url(${image})` }"></span>
        <span v-if="name" class="chat-bubble-name" :data-cy="`chat-bubble-${name}`">
            {{ name }}
        </span>
    </button>
</template>

<script>
import Tooltip from '@inconvo/components/src/directives/tooltip';
import { Position } from '@inconvo/components/src/enums';

export default {
    name: 'ChatBubble',
    directives: {
        Tooltip,
    },
    props: {
        image: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            default: undefined,
        },
        isActive: {
            type: Boolean,
            required: true,
        },
        isClosable: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        this.$options.Position = Position;
    },
};
</script>

<style lang="scss" scoped>
.chat-bubble {
    display: flex;
    flex-direction: column;
    align-items: center;

    &--active {
        .chat-bubble-image {
            transform: scale(1.375);
            box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.3);
        }
    }
}

.chat-bubble-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-size: cover;
    transition: transform 0.3s, box-shadow 0.3s;

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: background-color 0.3s;
    }
}

.chat-bubble-name {
    margin-top: 23px;
    font-family: $heading-font-family;
    font-size: 14px;
    font-weight: $bold-font-weight;
    text-align: center;
    color: $dark-grey;
}
</style>
