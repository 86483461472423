<template>
    <Icon name="logo-header" class="app-logo" :width="null" :height="null" />
</template>
<script>
import Icon from '@/components/Icon';

export default {
    name: 'AppLogo',
    components: {
        Icon,
    },
};
</script>
<style lang="scss" scoped>
svg.app-logo {
    display: block;
    width: em(133, 18);
    height: 1em;
    font-size: 1.8rem;
    color: $color-accent-1;
}
</style>
