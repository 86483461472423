import config from '@/config';
import { YgdRoutes } from '@/enums';

export default function ({ route, redirect, from, store }) {
    const pathParts = route.path.split('/');
    const routeName = pathParts[pathParts.length - 1];

    if (['privacy', 'terms-and-conditions'].includes(routeName)) {
        const redirectMap = {
            privacy: '/account/privacy-policy',
            'terms-and-conditions': '/account/terms-and-conditions',
        };
        const redirectId = redirectMap[routeName];
        if (redirectId) {
            const locale = store.$i18n?.locale?.split('-').reverse().join('-') || 'gb-en';
            const route = 'https://account.yougov.com/' + locale + redirectId;
            if (from) {
                window.open(route, '_blank');
            } else {
                return redirect(302, route);
            }
        }

        return redirect(from?.path || '/');
    }
    if (!config.featureToggle.useDailyChat && route.name.startsWith('daily-chat')) {
        return redirect('/');
    }

    if (!config.featureToggle.dailyChatProductPageEnabled && route.name === 'daily') {
        return redirect('/');
    }

    if (!config.featureToggle.useDailyChat && route.name === 'user-preferences') {
        return redirect('/');
    }

    if (route.path === '/channels' || route.path === '/channels/') {
        return redirect('/');
    }

    const ygdRouteNames = [YgdRoutes.Tasks, YgdRoutes.History, YgdRoutes.Profile];

    if (ygdRouteNames.some((path) => route.path.startsWith(path))) {
        return redirect(YgdRoutes.Balance);
    }
}
