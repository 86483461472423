import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { browser } from '@inconvo/utils';
import config from '@/config';
import { IRootState } from '@/types/rootState';
import { ChatWidgetChannelFactory } from '@/models/factories';
import { ChatWidgetChannel } from '@/models';
import { IChatWidgetChannel, IChatWidgetLastMessage, IChatWidgetMessage } from '@/interfaces';

const ADD_CHANNEL = 'addChannel';
const REMOVE_CHANNEL = 'removeChannel';
const SET_CHANNEL_LIST = 'setChannelList';
const SET_IS_CHAT_OPEN = 'setIsChatOpen';
const SET_IS_VISIBLE = 'setIsVisible';
const CLEAR_CHANNEL_LIST = 'clearChannelList';
const CLEAR_IS_CHAT_OPEN = 'clearIsChatOpen';
const UPDATE_CHANNEL = 'updateChannel';

const storageKey = `${config.storageKeyPrefix ? `${config.storageKeyPrefix}.` : ''}chatController`;

export interface IChatControllerState {
    channelList: ChatWidgetChannel[];
    isChatOpen: boolean;
    isVisible: boolean;
}

const saveStateToStorage = (state: IChatControllerState) => {
    browser.storage.local.setItem(storageKey, JSON.stringify(state));
};

const state = () => {
    const state: IChatControllerState = {
        channelList: [],
        isChatOpen: false,
        isVisible: false,
    };
    return state;
};

const getters: GetterTree<IChatControllerState, IRootState> = {
    activeChannel: (state): ChatWidgetChannel | null => {
        return state.isChatOpen ? state.channelList[state.channelList.length - 1] : null;
    },
};

const actions: ActionTree<IChatControllerState, any> = {
    startChat({ dispatch }, channel: ChatWidgetChannel): void {
        dispatch('removeChannel', channel);
        dispatch('addChannel', channel);
        dispatch('toggleIsChatOpen', true);
    },
    removeActive({ state, dispatch }): void {
        dispatch('removeChannel', state.channelList[state.channelList.length - 1]);
        dispatch('toggleIsChatOpen', false);
    },
    addChannel({ commit, state }, channel: IChatWidgetChannel): void {
        const chatWidgetChannel = ChatWidgetChannelFactory.make(channel);
        commit(ADD_CHANNEL, chatWidgetChannel);
        saveStateToStorage(state);
    },
    removeChannel({ commit, state }, channel: ChatWidgetChannel): void {
        commit(REMOVE_CHANNEL, channel);
        saveStateToStorage(state);
    },
    setActiveConvo({ commit, state }, { channelCode, convoId }: { channelCode: string; convoId: number }): void {
        const selectedChannel = state.channelList.find((item) => item.code === channelCode);
        if (selectedChannel) {
            const channel = { ...selectedChannel };
            channel.activeConvoId = convoId;
            commit(UPDATE_CHANNEL, { channelCode, channel });
        }

        saveStateToStorage(state);
    },
    removeActiveConvo({ commit, state }, convoId: string): void {
        const selectedChannel: IChatWidgetChannel = state.channelList[state.channelList.length - 1];
        if (selectedChannel && +convoId === selectedChannel.activeConvoId) {
            const channel = { ...selectedChannel };
            delete channel.activeConvoId;
            commit(UPDATE_CHANNEL, { channelCode: channel.code, channel });
        }
    },
    setCurrentThreadId({ commit, state }, threadId: string): void {
        const selectedChannel: IChatWidgetChannel = state.channelList[state.channelList.length - 1];
        if (selectedChannel) {
            const channel = { ...selectedChannel };
            channel.currentThreadId = threadId;
            commit(UPDATE_CHANNEL, { channelCode: channel.code, channel });
            saveStateToStorage(state);
        }
    },
    setChannelList({ commit, state }, channelList: ChatWidgetChannel[]): void {
        commit(SET_CHANNEL_LIST, channelList);
        saveStateToStorage(state);
    },
    toggleIsChatOpen({ commit, state }, isOpen: boolean): void {
        commit(SET_IS_CHAT_OPEN, isOpen);
        saveStateToStorage(state);
    },
    loadState({ dispatch }): void {
        const loadedStore = JSON.parse(browser.storage.local.getItem(storageKey) || '{}') as IChatControllerState;
        const channelList = loadedStore.channelList;
        const isChatOpen = !!loadedStore.isChatOpen;

        if (channelList) {
            dispatch('setChannelList', channelList);
        }

        dispatch('toggleIsChatOpen', isChatOpen);
    },
    toggleVisibility({ commit }, isVisible: boolean): void {
        commit(SET_IS_VISIBLE, isVisible);
    },
    onUserLogout({ commit }): void {
        browser.storage.local.removeItem(storageKey);
        commit(CLEAR_CHANNEL_LIST);
        commit(CLEAR_IS_CHAT_OPEN);
    },
    setLastMessage(
        { commit, state },
        { channelCode, message }: { channelCode: string; message: IChatWidgetMessage },
    ): void {
        const selectedChannel = state.channelList.find((item) => item.code === channelCode);
        if (selectedChannel) {
            const channel = { ...selectedChannel };
            channel.lastMessage = {
                type: message.type,
                convoType: message.convoType,
            } as IChatWidgetLastMessage;
            commit(UPDATE_CHANNEL, { channelCode, channel });
            saveStateToStorage(state);
        }
    },
};

const mutations: MutationTree<IChatControllerState> = {
    [ADD_CHANNEL](state: IChatControllerState, channel: ChatWidgetChannel): void {
        if (state.channelList.length === config.maxNumberOfChatBubbles) {
            state.channelList.shift();
        }

        state.channelList.push(channel);
    },
    [REMOVE_CHANNEL](state: IChatControllerState, channel: ChatWidgetChannel): void {
        const channelIndex = state.channelList.findIndex((item) => item.code === channel.code);

        if (channelIndex >= 0) {
            state.channelList.splice(channelIndex, 1);
        }
    },
    [SET_CHANNEL_LIST](state: IChatControllerState, channelList: ChatWidgetChannel[]): void {
        state.channelList = channelList;
    },
    [SET_IS_CHAT_OPEN](state: IChatControllerState, isOpen: boolean): void {
        state.isChatOpen = isOpen;
    },
    [SET_IS_VISIBLE](state: IChatControllerState, isVisible: boolean): void {
        state.isVisible = isVisible;
    },
    [CLEAR_CHANNEL_LIST](state: IChatControllerState): void {
        state.channelList = [];
    },
    [CLEAR_IS_CHAT_OPEN](state: IChatControllerState): void {
        state.isChatOpen = false;
    },
    [UPDATE_CHANNEL](
        state: IChatControllerState,
        { channelCode, channel }: { channelCode: string; channel: ChatWidgetChannel },
    ): void {
        const channelIndex = state.channelList.findIndex((item) => item.code === channelCode);
        state.channelList[channelIndex] = channel;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
} as Module<IChatControllerState, IRootState>;
