import { Channel } from './channel';

export default class Convo {
    channel!: Channel;
    createdAt: string;
    publishDate: string;
    updatedAt: string;
    showFromDate: string;
    relativeShowFrom: string | null;
    description: string;
    id: string;
    image: string;
    name: string;
    type: string;

    constructor() {
        this.createdAt = '';
        this.publishDate = '';
        this.updatedAt = '';
        this.showFromDate = '';
        this.relativeShowFrom = '';
        this.description = '';
        this.id = '';
        this.image = '';
        this.name = '';
        this.type = '';
    }
}
