<template>
    <Form
        v-slot="{ invalid, validated, errors }"
        :class="`email-submit-form email-submit-form--${variant}`"
        @submit="onSubmit"
    >
        <slot :model="model" :errors="errors" :invalid="invalid">
            <div class="mb-2">
                <AppInput
                    :id="$uid('email')"
                    v-model="model.email"
                    :disabled="isLoading"
                    :variant="inputVariant"
                    :placeholder="inputPlaceholder"
                    background-color=""
                    :autofocus="autofocus"
                    :error-style="inputErrorStyle"
                    type="email"
                    name="email"
                    rules="required|email"
                    class="email-input mb-2"
                    :label="inputLabel"
                    data-cy="email-submit-form-input"
                />
                <div class="text-left">
                    <AppButton
                        :variant="$options.ButtonVariant.solid"
                        :color="$options.ButtonColor.primary"
                        :size="$options.ButtonSize.medium"
                        :disabled="isButtonDisabled"
                        :is-loading="isLoading"
                        :class="{ 'w-full': variant !== $options.EmailSubmitFormVariant.outline }"
                        type="submit"
                        data-cy="email-submit-form-submit"
                    >
                        {{ joinButtonText }}
                    </AppButton>
                </div>
            </div>
        </slot>

        <div class="form-conditions" role="contentinfo">
            <i18n path="emailSubmitForm.termsAndPrivacy" tag="p">
                <a
                    slot="terms"
                    class="link"
                    target="_blank"
                    rel="noopener"
                    data-cy="email-submit-form-terms"
                    href="/terms-and-conditions"
                    >{{ $t('emailSubmitForm.termsOfUse') }}</a
                >
                <a
                    slot="privacy"
                    class="link"
                    target="_blank"
                    rel="noopener"
                    data-cy="email-submit-form-privacy"
                    href="/privacy"
                    >{{ $t('emailSubmitForm.privacyPolicy') }}</a
                >
            </i18n>
        </div>
    </Form>
</template>

<script>
import Form from '@/components/forms/Form';
import AppInput from '@/components/forms/AppInput';
import AppButton from '@/components/AppButton';
import { InputVariant, InputErrorStyle, EmailSubmitFormVariant } from '@/enums';
import { ButtonVariant, ButtonSize, ButtonColor } from '@/enums/buttons';

export default {
    name: 'EmailSubmitForm',
    components: {
        Form,
        AppInput,
        AppButton,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        variant: {
            default: EmailSubmitFormVariant.Primary,
            validator: (value) => Object.values(EmailSubmitFormVariant).includes(value),
        },
        joinButtonText: {
            type: String,
            default() {
                return this.$t('emailSubmitForm.joinButton');
            },
        },
        inputLabel: {
            type: String,
            default: null,
        },
        inputPlaceholder: {
            type: String,
            default() {
                return this.$t('emailSubmitForm.emailPlaceholder');
            },
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            model: {
                email: '',
            },
        };
    },
    computed: {
        inputVariant() {
            switch (this.variant) {
                case 'primary':
                    return InputVariant.Rounded;
                case 'secondary':
                    return InputVariant.Tertiary;
                case 'outline':
                    return InputVariant.outline;
                default:
                    return InputVariant.Secondary;
            }
        },

        inputErrorStyle() {
            if ([EmailSubmitFormVariant.Primary, EmailSubmitFormVariant.outline].includes(this.variant)) {
                return InputErrorStyle.highlighted;
            }

            return InputErrorStyle.default;
        },
        isButtonDisabled() {
            if (![EmailSubmitFormVariant.Primary, EmailSubmitFormVariant.outline].includes(this.variant)) {
                return this.invalid || !this.model.email;
            }

            return false;
        },
    },
    created() {
        this.$options.EmailSubmitFormVariant = EmailSubmitFormVariant;
        this.$options.ButtonVariant = ButtonVariant;
        this.$options.ButtonColor = ButtonColor;
        this.$options.ButtonSize = ButtonSize;
    },
    methods: {
        onSubmit() {
            this.$emit('submit', { email: this.model.email });
        },
    },
};
</script>

<style lang="scss" scoped>
.email-input {
    line-height: 1.6rem;
    transition: border-color 100ms ease-in-out;
    text-align: left;

    &:focus {
        outline: none;
        border-color: darken($color-primary-2, 5%);
    }
}

.form-conditions {
    font-family: $font-alternate;
    font-size: $smaller-font-size;
    line-height: 1.67;
    color: $color-white;
    text-align: inherit;

    .email-submit-form--tertiary & {
        color: $default-text-color;
    }
}

.link {
    .email-submit-form--primary & {
        color: $color-white;
    }

    .email-submit-form--tertiary & {
        color: $color-accent-1;
    }

    .email-submit-form--secondary & {
        color: color($palette, violet, 400);
    }
}
</style>
