import { IUserProfileRequest, IUserProfileResponse } from '@/api/interfaces/userProfileApiClient.interface';
import ProfileApiClient from '@/api/server/userProfileClient';
import { IUserProfile } from '@/interfaces/user.interface';
import format from 'date-fns/format';
import { dateStringToDate } from './Utils';

class ProfileService {
    public async getUserProfile(): Promise<IUserProfile> {
        const userProfileResponse: IUserProfileResponse = await ProfileApiClient.getUserProfile();

        const userProfile: IUserProfile = this.mapUserProfile(userProfileResponse);

        return userProfile;
    }

    public async updateUserProfile(data: IUserProfile): Promise<IUserProfile> {
        const birthdate: string | undefined =
            data.birthdate && typeof data.birthdate !== 'string'
                ? format(data.birthdate as Date, 'dd/MM/yyyy')
                : data.birthdate;

        const request: IUserProfileRequest = {
            name: data.name,
            email: data.email,
            phone_number: data.phoneNumber,
            birthdate,
            gender: data.gender,
            consent_sms_update: data.consentSmsUpdate,
            consent_email_update: data.consentEmailUpdate,
            user_status: data.userStatus,
            country_code: data.countryCode,
            locale: data.locale,
        };

        if (data.sourceChannel) {
            request.source_channel = data.sourceChannel;
        }

        const userProfileResponse: IUserProfileResponse = await ProfileApiClient.updateUserProfile(request);

        const userProfile: IUserProfile = this.mapUserProfile(userProfileResponse);

        return userProfile;
    }

    public async deleteUserProfile(): Promise<any> {
        await ProfileApiClient.deleteUserProfile();
    }

    private mapUserProfile(userProfileResponse: IUserProfileResponse): IUserProfile {
        const birthdate = userProfileResponse.birthdate
            ? dateStringToDate(userProfileResponse.birthdate as string)
            : '';

        const userProfile: IUserProfile = {
            name: userProfileResponse.name,
            birthdate,
            gender: userProfileResponse.gender,
            phoneNumber: userProfileResponse.phone_number,
            email: userProfileResponse.email,
            consentSmsUpdate: userProfileResponse.consent_sms_update,
            consentEmailUpdate: userProfileResponse.consent_email_update,
            usernameType: userProfileResponse.username_type,
            subscriptions: userProfileResponse.subscriptions,
        };

        return userProfile;
    }
}

export default new ProfileService();
