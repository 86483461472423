import Vue from 'vue';
import ConvoList from '@/components/ConvoList.vue';
import AppHero from '@/components/AppHero.vue';
import DailyChatSubscribe from '@/components/daily-chat/DailyChatSubscribe.vue';
import DailyChatOpinion from '@/components/daily-chat/DailyChatOpinion.vue';

Vue.component('ConvoList', ConvoList);
Vue.component('Hero', AppHero);
Vue.component('HeroSubscriber', DailyChatSubscribe);
Vue.component('DailyChatOpinion', DailyChatOpinion);
