const locales = [
    {
        code: 'en-gb',
        iso: 'en-gb',
        country: 'gb',
        language: 'en',
        countryName: 'United Kingdom',
        languageName: 'English',
        title: 'United Kingdom',
        file: 'en.js',
    },
    {
        code: 'en-us',
        iso: 'en-us',
        country: 'us',
        language: 'en',
        countryName: 'United States',
        languageName: 'English',
        title: 'United States of America',
        file: 'en-us.js',
    },
];

export default locales;
