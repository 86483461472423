import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { INotificationsState, INotification } from '@/interfaces';
import { IRootState } from '@/types/rootState';

const ADD_NOTIFICATION = 'addNotification';
const REMOVE_NOTIFICATION = 'removeNotification';
const REMOVE_NOTIFICATION_BY_ID = 'removeNotificationById';
const SET_NOTIFICATIONS = 'setNotifications';

const state = () =>
    ({
        notifications: [] as INotification[],
    } as INotificationsState);

const getters: GetterTree<INotificationsState, IRootState> = {
    notificationsByContext: (state) => (context) => {
        return state.notifications.filter((notification) => notification.context === context);
    },
};

const actions: ActionTree<INotificationsState, any> = {
    addNotification({ commit, dispatch }, { notification, clearStack }) {
        commit(ADD_NOTIFICATION, { notification, clearStack });
        if (!notification.autohide) {
            return;
        }
        setTimeout(() => dispatch('removeNotificationById', notification.id), notification.timeout);
    },
    removeNotification({ commit }, idx: number) {
        commit(REMOVE_NOTIFICATION, idx);
    },
    removeNotificationById({ commit }, id: string) {
        commit(REMOVE_NOTIFICATION_BY_ID, id);
    },
    clearNotifications({ commit, state }, context?: string) {
        let notifications: INotification[] = [];

        if (context) {
            notifications = state.notifications.filter((o) => o.context !== context);
        }

        commit(SET_NOTIFICATIONS, notifications);
    },
    dissmissNotification({ dispatch, state }, id: string) {
        const notification = state.notifications.find((o) => o.id === id);

        if (notification && notification.dismissCallback) {
            notification.dismissCallback(id);
        }

        dispatch('removeNotificationById', id);
    },
    confirmNotification({ dispatch, state }, id: string) {
        const notification = state.notifications.find((o) => o.id === id);

        if (notification && notification.confirmationCallback) {
            notification.confirmationCallback(id);
        }

        dispatch('removeNotificationById', id);
    },
};

const mutations: MutationTree<INotificationsState> = {
    [ADD_NOTIFICATION](state: INotificationsState, { notification, clearStack }): void {
        if (clearStack) {
            state.notifications = [notification];
        } else {
            state.notifications = [...state.notifications, notification];
        }
    },
    [REMOVE_NOTIFICATION](state: INotificationsState, idx: number): void {
        const notificationsCopy = [...state.notifications];
        notificationsCopy.splice(idx, 1);
        state.notifications = notificationsCopy;
    },
    [REMOVE_NOTIFICATION_BY_ID](state: INotificationsState, id: string): void {
        let notificationsCopy = [...state.notifications];
        notificationsCopy = notificationsCopy.filter((o) => o.id !== id);
        state.notifications = notificationsCopy;
    },
    [SET_NOTIFICATIONS](state: INotificationsState, notifications: INotification[]): void {
        state.notifications = notifications;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
} as Module<INotificationsState, IRootState>;
