import {
    IUserProfileApiClient,
    IUserProfileRequest,
    IUserProfileResponse,
} from '@/api/interfaces/userProfileApiClient.interface';
import { auth as AuthService } from '@/auth';
import config from '@/config';
import RequestService from '@/services/request.service';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

class ProfileApiClient implements IUserProfileApiClient {
    axiosClient: AxiosInstance;
    constructor() {
        this.axiosClient = axios.create({
            baseURL: config.messagingApiBaseUrl,
        });
    }

    public async getUserProfile(): Promise<IUserProfileResponse> {
        const session = await AuthService.getCurrentSession();
        const token = session.getAccessToken().getJwtToken();
        const response = await RequestService.get('v2/profile', {
            headers: { Authorization: `Bearer ${token}` },
        } as AxiosRequestConfig);
        return (response as unknown) as Promise<any>;
    }

    public async updateUserProfile(data: IUserProfileRequest): Promise<IUserProfileResponse> {
        const session = await AuthService.getCurrentSession();
        const token = session.getAccessToken().getJwtToken();
        const response = await RequestService.put('v2/profile', data, {
            headers: { Authorization: `Bearer ${token}` },
        } as AxiosRequestConfig);
        return (response as unknown) as Promise<any>;
    }

    public async deleteUserProfile(): Promise<void> {
        const session = await AuthService.getCurrentSession();
        const token = session.getAccessToken().getJwtToken();
        await RequestService.delete('v2/profile', {
            headers: { Authorization: `Bearer ${token}` },
        } as AxiosRequestConfig);
    }
}

export default new ProfileApiClient();
