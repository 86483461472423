<template>
    <a :href="url" class="convo-grid-item" @click.prevent="$emit('click', $event)">
        <div class="convo-grid-item-image">
            <div class="convo-grid-item-image-inner">
                <img :src="`${item.image}?size=500`" :alt="item.name" />
            </div>
        </div>

        <div class="convo-grid-item-content">
            <h3 class="convo-grid-item-title">{{ item.name }}</h3>
            <div v-if="item.relativeShowFrom" class="published-date">
                <Icon name="published" class="published-date-icon" :width="null" :height="null" />
                <span>{{ item.relativeShowFrom }}</span>
            </div>
        </div>
    </a>
</template>
<script>
import Icon from '@/components/Icon';

export default {
    name: 'ConvoGridItem',
    components: { Icon },
    props: {
        item: {
            type: Object,
            default: null,
        },
        url: {
            type: String,
            required: true,
        },
    },
};
</script>
<style scoped lang="scss">
.convo-grid-item-title {
    margin-top: 1rem;
    font-size: $smaller-font-size;
    font-weight: $font-weight-semibold;
    line-height: 1.33;

    @include media-query(medium) {
        margin-top: 2rem;
        font-size: 2.4rem;
        font-weight: $bold-font-weight;
        line-height: 1.75;
    }
}

.convo-grid-item-image {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 0;
    padding-top: #{133/160 * 100}#{'%'};

    @include media-query(medium) {
        padding-top: #{260/328 * 100}#{'%'};
    }
}

.convo-grid-item-image-inner {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.published-date {
    display: flex;
    align-content: center;
    align-items: center;
    margin-top: 1.4rem;
    font-family: $font-heading;
    font-size: $smaller-font-size;
    font-weight: $font-weight-medium;
    line-height: 1;
    color: map-get($newPalette, shades, 800);
    white-space: nowrap;

    @include media-query(medium) {
        margin-top: 2.8rem;
        font-size: $large-font-size;
    }

    &-icon {
        margin-right: 0.5em;
    }
}
</style>
