import * as crypto from 'crypto';

export const capitalize = (s: string): string => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
};

export const timeout = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const dateStringToDate = (dateString: string): Date | undefined => {
    try {
        const dateParts: any[] = dateString.split('/');
        const date = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        if (dateParts.length === 3 && !isNaN(date.getTime())) {
            return date;
        }
    } catch (error) {}
};

export const dedupeList = <T>(list: T[], prop: string) => {
    return list.reduce((list: T[], current: T) => {
        const duplicate = list.find((channel) => channel[prop] === current[prop]);
        return duplicate ? list : list.concat(current);
    }, [] as T[]);
};

export const getUrlParams = (search: string = window.location.search) => {
    if (!search) return {};

    const hashes: string[] = search.slice(search.indexOf('?') + 1).split('&');
    const params = {};
    if (hashes[0] === '') {
        return params;
    }
    hashes.forEach((hash) => {
        const [key, val] = hash.split('=');
        params[key] = decodeURIComponent(val);
    });
    return params;
};

export const hasSameProps = (reference: any, obj: any, enableLog = false): boolean => {
    if (reference === null && obj === null) {
        return true;
    }

    const referenceProps = reference === null ? [] : Object.keys(reference);
    const objProps = obj === null ? [] : Object.keys(obj);

    if (referenceProps.length !== objProps.length) {
        if (enableLog) {
            referenceProps.forEach((element) => {
                if (!objProps.includes(element)) {
                    console.log(`"${element}" key missing.`);
                }
            });
        }
        // Different number of own properties
        return false;
    }

    const hasOwn = Object.prototype.hasOwnProperty;
    return referenceProps.every((key) => {
        if (!hasOwn.call(obj, key)) {
            if (enableLog) {
                console.log(`"${key}" key missing.`);
            }
            // Different keys
            return false;
        }
        // Get the values and their types
        const v1 = reference[key];
        const v2 = obj[key];
        const t1 = typeof v1;
        const t2 = typeof v2;
        if (t1 === 'object' && t2 === 'object' && !hasSameProps(v1, v2, enableLog)) {
            return false;
        }
        return true;
    });
};

export const removeProperty = (object: any, removeProp: string): any => {
    const removeFunc = (prop: any) => ({ [prop]: _, ...rest }) => rest;
    const remove = removeFunc(removeProp);
    return remove(object);
};

export const removeUnsetProperties = (object: any): any => {
    for (const key in object) {
        if (object[key] === null || object[key] === undefined) {
            delete object[key];
        }
    }
    return object;
};

export const isTouchDevice = () => {
    // @ts-ignore
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
};

export const scrollToAsync = (options: { top: number; left: number; behavior: 'smooth' | 'auto' }): Promise<void> => {
    return new Promise((resolve) => {
        const onScroll = () => {
            if (window.scrollY === options.top && window.scrollX === options.left) {
                window.removeEventListener('scroll', onScroll, false);
                resolve();
            }
        };
        window.addEventListener('scroll', onScroll, false);
        window.scrollTo(options);
    });
};

export const randomBetween = (min: number, max: number): number => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

export const getWeightedRandom = (
    weightedArr: Array<{ name: string; index: number; weight: number }>,
): { name: string; index: number; weight: number } | undefined => {
    const totalWeight: number = weightedArr.map(({ weight }) => Number(weight)).reduce((acc, i) => acc + i, 0);

    let randomNumber = Math.random() * totalWeight;
    for (const obj of weightedArr) {
        if (randomNumber < obj.weight) {
            return obj;
        }
        randomNumber -= obj.weight;
    }
};

export const decrypt = (secret: string, text: string): string | undefined => {
    try {
        const hash = crypto.createHash('sha1');
        hash.update(secret);
        const key: Buffer = hash.digest().slice(0, 16);
        const textParts = text.split(':');
        // @ts-ignore
        const iv = Buffer.from(textParts.shift(), 'hex');
        const encryptedText = Buffer.from(textParts.join(':'), 'hex');
        const decipher = crypto.createDecipheriv('aes-128-ctr', key, iv);
        let decrypted = decipher.update(encryptedText);
        decrypted = Buffer.concat([decrypted, decipher.final()]);
        return decrypted.toString();
    } catch (error) {
        return undefined;
    }
};

export const validateUuid = (uuid: string): boolean => {
    const pattern = /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/i;
    return typeof uuid === 'string' && pattern.test(uuid);
};
