import { IEditable } from '@/interfaces/storyblok.interface';
import { LinkStyle } from '@/enums';

export default class Link implements IEditable {
    _editable: string;
    _uid: string;
    name: string;
    href: string;
    target: string;
    style: LinkStyle;
    isExternal: boolean;
    ygdPanelist: boolean;
    rel?: string;

    constructor() {
        this._editable = '';
        this._uid = '';
        this.name = '';
        this.href = '';
        this.target = '';
        this.style = LinkStyle.Primary;
        this.isExternal = false;
        this.ygdPanelist = false;
    }
}
