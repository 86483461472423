<template>
    <Card
        :pill-color="item.channel.color"
        :pill-text="item.channel.name"
        :title="item.name"
        :text="item.description"
        :image="item.image"
        @click="$emit('click', $event)"
    >
        <template #footer>
            <div class="convo-card-footer">
                <div v-if="numberOfPeople">
                    <Icon name="speech-bubble" :height="18" :width="18" />
                    <span class="convo-card-footer__text text-smaller">{{
                        $t('convoPage.footer', { numberOfPeople })
                    }}</span>
                </div>
                <div v-if="item.relativeShowFrom" class="posted-date">
                    <Icon name="posted-date" class="posted-date-icon" :width="17" :height="17" />
                    <span>{{ item.relativeShowFrom }}</span>
                </div>
            </div>
        </template>
    </Card>
</template>

<script>
import Card from '@/components/Card';
import Icon from '@/components/Icon';

export default {
    name: 'ConvoCard',
    components: {
        Card,
        Icon,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
        numberOfPeople: {
            type: Number,
            default: undefined,
        },
    },
};
</script>

<style scoped lang="scss">
.convo-card-footer {
    display: flex;
    align-items: center;
    margin-top: $default-vertical-space;

    &__text {
        margin-left: 5px;
    }
}

.posted-date {
    display: flex;
    align-items: center;
    font-family: $font-heading;
    font-size: $smaller-font-size;
    color: $gull-grey;
    white-space: nowrap;
}

.posted-date-icon {
    margin-right: 5px;
    color: $dark-grey-blue;
}
</style>
