<template>
    <div class="loading text-center" :class="{ 'loading--daily-chat': isDailyChatEnabled }">
        <div v-if="isDailyChatEnabled" class="loading-content">
            <div class="loading-title">
                <nuxt-child :is-daily-chat="isDailyChatEnabled" />
            </div>
            <daily-chat-loader class="loading-loader-icon" />
        </div>
        <div v-else>
            <AppLogo class="yc-logo" />
            <SpeechBalloonLoader class="loader-icon" />
            <nuxt />
        </div>

        <ModalContainer
            v-if="activeModal.type !== null"
            :modal-type="activeModal.type"
            :modal-z-index="modal.props.modalZIndex"
            :backdrop-style="modal.props.backdropStyle"
            :closable="modal.props.closable && modal.props.closable === true"
            :padding-size="modal.props.paddingSize"
            :is-rounded="modal.props.isRounded"
            :shadow="modal.props.shadow"
            :close-callback="modal.props.closeCallback"
            :on-before-enter-callback="modal.props.onBeforeEnterCallback"
            :on-after-leave-callback="modal.props.onAfterLeaveCallback"
            @afterClose="toggleModal({ type: null })"
        >
            <template v-slot:modal="{ close }">
                <Modals
                    :modal="modal"
                    @toggleModal="toggleModal($event)"
                    @updateModal="onUpdateModal"
                    @close="close"
                    @confirmCountryOfResidence="onConfirmCountryOfResidence"
                />
            </template>
        </ModalContainer>

        <SystemNotifications :notifications="notifications" />
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SpeechBalloonLoader from '@/components/SpeechBalloonLoader';
import SystemNotifications from '@/components/SystemNotifications';
import AppLogo from '@/components/AppLogo';
import DailyChatLoader from '@/components/daily-chat/DailyChatLoader';
import ModalsMixin from '@/mixins/ModalsMixin';

export default {
    name: 'Loading',
    components: {
        SpeechBalloonLoader,
        SystemNotifications,
        DailyChatLoader,
        AppLogo,
        ModalContainer: () => import('@/components/ModalContainer.vue'),
        Modals: () => import('@/components/modals/Modals'),
    },
    mixins: [ModalsMixin],
    computed: {
        ...mapState('notifications', ['notifications']),
        ...mapState('main', ['activeModal', 'isDailyChatEnabled']),
    },
    methods: {
        ...mapActions('main', ['toggleModal']),
        ...mapActions('auth', ['onConfirmCountryOfResidence']),
    },
};
</script>

<style scoped lang="scss">
.loading {
    height: 100vh;
    width: 100vw;
    background-color: $white;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    color: $purple-dark-2;

    &--daily-chat {
        background-color: $color-misc-1;
        text-align: center;

        .loading-content {
            width: 100%;
            max-width: 44rem;
        }

        .loading-loader-icon {
            margin: 0 auto;
        }

        .loading-title {
            > p {
                margin-bottom: 5rem;
                font-size: 2.2rem;
                color: $color-accent-1;
                font-weight: $font-weight-medium;

                @include media-query(medium) {
                    font-size: $larger-font-size;
                    color: $color-accent-1;
                }
            }
        }
    }

    .yc-logo {
        font-size: 4rem;
    }
}

.yc-logo,
.loader-icon {
    margin: 0 auto 3rem auto;
    transform: scale(0.8);

    @include media-query(medium) {
        transform: scale(1);
        margin-bottom: 7rem;
    }
}
</style>
