export enum QueryParam {
    Action = 'action',
    RecontactId = 'recontact_id',
    RecontactToken = 'recontact_token',
    SignInCode = 'signincode',
    RedirectPath = 'redirect_path',
    Referral = 'referral',
    Channel = 'channel',
    State = 'state',
    UserEmail = 'useremail',
    StoryblokSpaceId = '_storyblok_tk[space_id]',
    StoryblokTimestamp = '_storyblok_tk[timestamp]',
    StoryblokToken = '_storyblok_tk[token]',
    StoryblokLang = '_storyblok_lang',
    ClientId = 'clientid',
    Restart = 'restart',
    Error = 'error',
    SessionId = 'sessionId',
    ContentItemId = 'contentItemId',
    RID = 'rid',
    Disposition = 'disposition',
    Code = 'code',
    Embedded = 'embedded',
    CountryOfResidenceEnabled = 'cor',
    ChatWidgetVersion = 'chat_widget_version',
    Source = 'source',
    Subscription = 'subscription',
    Notification = 'notification',
    utmCampaign = 'utm_campaign',
    utmSource = 'utm_source',
    convoType = 'convo_type',
    identityProvider = 'identity_provider',
    convoId = 'source_convo_id',
    sourceChannelCode = 'source_channel_code',
    sourceChannel = 'source_channel',
    appCode = 'app_code',
}
