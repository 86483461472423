<template>
    <div class="card" :class="{ 'card--placeholder': isPlaceholder }" @click="$emit('click', $event)">
        <media-object class="card__media-object" :is-placeholder="isPlaceholder">
            <template #image>
                <div class="card__image" :style="image ? `background-image: url(${image})` : ''"></div>
            </template>
            <div v-if="!isPlaceholder" class="card__content">
                <div class="card__body">
                    <div v-if="pillText" class="mb-1">
                        <Pill :text="pillText" :color="pillColor" />
                    </div>
                    <h2 class="card__title font-bold mb-1">{{ title }}</h2>
                    <p class="card__description text-small">{{ text }}</p>
                </div>
                <div v-if="!!$slots.footer" class="card__footer m-t-1">
                    <slot name="footer" />
                </div>
            </div>
        </media-object>
    </div>
</template>

<script>
import MediaObject from '@/components/MediaObject.vue';
import Pill from '@/components/Pill.vue';

export default {
    name: 'Card',
    components: { MediaObject, Pill },
    props: {
        image: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        pillText: {
            type: String,
            default: '',
        },
        pillColor: {
            type: String,
            default: '',
        },
        isPlaceholder: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
<style scoped lang="scss">
.card {
    $component: &;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    min-height: 120px;
    border-radius: 10px;
    box-shadow: 0 5px 14px 0 rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s;
    cursor: pointer;

    @include media-query(medium) {
        min-height: 175px;
    }

    &:hover {
        box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.1);
    }

    &__media-object {
        flex: 1 1 auto;
    }

    &__image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        flex: 0 0 35%;
        width: 35%;
        min-width: 120px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 200px;
        height: 100%;
        padding: 10px 10px 10px 0;

        @include media-query(medium) {
            padding: 18px 25px 18px 15px;
        }
    }

    &__title {
        font-size: 13px;
        line-height: 1.2;

        @include media-query(medium) {
            font-size: 22px;
        }
    }

    &__description {
        font-size: 11px;
        color: $gull-grey;

        @include media-query(medium) {
            font-size: 14px;
        }
    }

    &--placeholder {
        #{$component}__image {
            background-color: $grey-lighter-26;
        }
    }
}
</style>
