<template>
    <div class="not-found-page">
        <div class="not-found-page__container">
            <div class="not-found-page__image">
                <img src="../../assets/images/404-not-found.png" alt="Page not found" />
            </div>
            <div class="not-found-page__content">
                <h2 class="not-found-page__title">{{ $t('errorPage.title') }}</h2>
                <div class="underline-text">{{ $t('errorPage.header') }}</div>
                <p class="not-found-page__description">
                    {{ $t('errorPage.body') }}
                </p>
                <div class="not-found-page__links">
                    <div class="not-found-page__link">
                        <button class="button" @click="backButtonClick">{{ $t('errorPage.homeButton') }}</button>
                    </div>
                    <div class="not-found-page__link">
                        <button class="button" @click="reloadButtonClick">{{ $t('errorPage.reloadButton') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFoundPage',
    methods: {
        backButtonClick() {
            window.location.href = '/';
        },
        reloadButtonClick() {
            // eslint-disable-next-line no-self-assign
            window.location.reload();
        },
    },
};
</script>

<style lang="scss" scoped>
.not-found-page {
    padding: 5rem 0 2rem;

    &__container {
        margin: 0 auto;
        display: flex;
        flex-direction: column;

        @include media-query(medium) {
            flex-direction: row;
        }
    }

    &__content {
        @include media-query(medium) {
            order: 1;
            width: 45%;
            margin-left: 3.5rem;
            margin-top: 1.5rem;
        }

        @include media-query(large) {
            margin-left: 29rem;
            margin-top: 5rem;
        }
    }
    &__image {
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-query(medium) {
            margin-left: 3.8rem;
            order: 2;
            width: 50%;
        }

        img {
            margin-top: -8.6rem;
            width: 28rem;
            height: 32.5rem;

            @include media-query(medium) {
                width: 49.3rem;
                height: 57.1rem;
                transform: scale(1.1);
            }
        }
    }
    &__title {
        font-family: $font-heading;
        font-size: 2.4rem;
        text-align: center;
        color: #010101;
        padding-bottom: 2.3rem;

        @include media-query(medium) {
            font-size: 2.8rem;
            text-align: left;
        }
    }

    .underline-text {
        position: relative;
        width: 25.4rem;
        margin: 0 auto;
        font-size: 3.8rem;
        font-weight: bold;
        color: $default-dark-color;

        &::before {
            content: '';
            z-index: -1;
            position: absolute;
            left: -1rem;
            bottom: 0;
            height: 1.5rem;
            width: calc(100% + 2rem);
            background-color: rgba($color-primary, 0.2);
        }

        @include media-query(medium) {
            display: inline-block;
            font-size: 4.8rem;
            margin: 0;
            width: auto;
        }
    }

    &__description {
        margin-bottom: 3rem;
        font-family: $font-heading;
        font-size: $default-font-size;
        font-weight: 300;
        line-height: 1.63;
        text-align: left;
        color: $default-dark-color;
        padding: 3.2rem 2.2rem 0 2.2rem;

        @include media-query(medium) {
            margin-bottom: 4.8rem;
            font-size: 2.4rem;
            padding: 3.2rem 0 0 0;
        }
    }

    &__links {
        // flex-direction: column;
        // display: inline-block;
        text-align: center;
        -ms-flex-align: center;
    }

    &__link {
        width: 17.5rem;
        margin: 0 auto;
        display: inline;

        @include media-query(medium) {
            margin: 0;
            width: auto;
        }
    }

    &__link_reload {
        width: 17.5rem;
        margin: 0 auto;
        @include media-query(medium) {
            margin: 0;
            width: auto;
        }
    }

    .button {
        border: 0.2rem solid $default-dark-color;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 4rem;
        min-width: 16rem;
        font-size: 1.2rem;
        font-family: $font-body;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.192rem;
        cursor: pointer;
        background: $default-light-color;
    }
}
</style>
