import { browser } from '@inconvo/utils';

if (process.server || browser.storage.isSupported()) {
    const API = require('@aws-amplify/api').default;

    const config = process.env.config as any;

    API.configure({
        endpoints: [
            {
                name: 'messaging',
                endpoint: config.messagingApiBaseUrl,
                region: config.awsRegion,
            },
            {
                name: 'yougov',
                endpoint: config.yougovProxyApiBaseUrl,
                region: config.awsRegion,
            },
        ],
    });
}
