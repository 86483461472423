import { IdentityProvider } from '@inconvo/types/enums';
import { IAuthSessionMetadata } from '@/interfaces/auth.interface';

interface IAnalyticUserRegister {
    loginType?: string;
    channel?: string;
    convoType?: string;
    convo?: number;
    utmCampaign?: string;
    utmSource?: string;
}
export default class AnalyticsPayloadFactory {
    public static make(data: IAuthSessionMetadata = {}): IAnalyticUserRegister {
        const payload = {
            loginType:
                data.identityProvider === IdentityProvider.cognito ? 'email' : data.identityProvider || undefined,
            channelCode: data.sourceChannelCode || undefined,
            convoId: data.sourceConvoId || undefined,
            convoType: data.convoType || undefined,
            utmCampaign: data.utmCampaign || undefined,
            utmSource: data.utmSource || undefined,
        } as IAnalyticUserRegister;

        return payload;
    }
}
