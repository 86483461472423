<template>
    <div class="chat-controller">
        <div
            v-for="(channel, idx) in channelList"
            :key="idx"
            class="chat-bubble-wrapper"
            :class="{ 'chat-bubble-wrapper--active': isActive(idx) }"
        >
            <ChatBubble
                :image="channel.squareImage"
                :is-active="isActive(idx)"
                :is-closable="isActive(idx)"
                @click="$emit('bubbleClick', channel)"
            />
        </div>
        <ChatButton v-if="channelList.length" class="mobile-button" @click="$emit('mobileButtonClick')" />
    </div>
</template>

<script>
import ChatBubble from './ChatBubble';
import ChatButton from './ChatButton';

export default {
    name: 'ChatController',
    components: {
        ChatBubble,
        ChatButton,
    },
    props: {
        channelList: {
            type: Array,
            required: true,
        },
        isChatOpen: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        isActive(idx) {
            return this.isChatOpen && idx === this.channelList.length - 1;
        },
    },
};
</script>

<style lang="scss" scoped>
.chat-bubble-wrapper {
    margin: 10px;

    &--active {
        transform: translate(0, 8px);
    }

    @include media-query(fullscreen-chat-widget) {
        display: none;
    }
}

.mobile-button {
    display: none;

    @include media-query(fullscreen-chat-widget) {
        display: flex;
    }
}
</style>
