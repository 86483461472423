<template>
    <div :is="tag" :class="[`container container--${size}`, { 'container--styled': defaultStyling }]">
        <div v-if="$slots.default" class="container__inner" :data-cy="dataCyKey">
            <slot />
        </div>
    </div>
</template>
<script>
import { ContentSize } from '@/enums';

export default {
    name: 'ContentContainer',
    props: {
        size: {
            type: String,
            default: ContentSize.Normal,
            validator: (value) => Object.values(ContentSize).includes(value),
        },
        defaultStyling: {
            type: Boolean,
            default: false,
        },
        tag: {
            type: String,
            default: 'div',
        },
        dataCyKey: {
            type: String,
            default: undefined,
        },
    },
};
</script>

<style scoped lang="scss">
.container {
    width: 100%;
}

.container__inner {
    margin: 0 auto;
    padding-left: 1.6rem;
    padding-right: 1.6rem;

    .container--full & {
        max-width: none;
    }

    @include media-query(large) {
        .container--small & {
            max-width: 600px;
        }

        .container--normal & {
            max-width: 730px;
        }

        .container--medium & {
            max-width: 812px;
        }

        .container--large & {
            max-width: 992px;
        }

        .container--xlarge & {
            max-width: $main-max-width;
        }
    }
}

.container--styled {
    font-family: $font-body;
    line-height: 1.1;

    ::v-deep {
        p {
            margin-bottom: 1.6rem;
            font-size: 14px;
            line-height: 24px;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-top: 3.2rem;
            margin-bottom: 0.8rem;
            font-family: $font-heading;
            font-weight: 700;
        }

        h1 {
            font-size: 3.2rem;
        }

        h2 {
            font-size: 2.8rem;
        }

        h3 {
            font-size: 2.4rem;
        }

        h4 {
            font-size: 2rem;
        }

        h5 {
            font-size: 1.76rem;
        }

        h6 {
            font-size: 1.6rem;
        }

        ul,
        ol {
            margin-bottom: 1.6rem;
            list-style: initial;
            line-height: 2.14;
            letter-spacing: -0.34px;
            padding-left: 3.2rem;

            p {
                margin: 0;
            }
        }

        b {
            font-weight: bold;
        }

        i {
            font-style: italic;
        }
    }
}
</style>
