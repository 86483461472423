export default class Testimonial {
    testimonial: string;
    image: string;
    name: string;

    constructor() {
        this.testimonial = '';
        this.image = '';
        this.name = '';
    }
}
