import ApiClient from '@/api/server/apiClient';
import YougovDirectClient from '@/api/server/yougovDirectClient';
import config from '@/config';
import { AppAction, LoginSource, ModalType } from '@/enums';
import {
    IRegisterData,
    IRegisterUserPayload,
    IRegisterUserResponse,
    IRegisterYougovUserPayload,
    IYgdProfile,
} from '@/interfaces';
import {
    IAuthChallengeResponse,
    IAuthSessionMetadata,
    ISendAuthChallengeAnswerPayload,
    ISocialLoginData,
    ISocialLoginRequest,
    ISocialLoginResponse,
    IUserAttribute,
    IVerifyUserPayload,
    IVerifyUserResponse,
} from '@/interfaces/auth.interface';
import { IdentityProvider } from '@inconvo/types/enums';

import { LOGIN_METADATA_KEY, PERSON_ID_STORAGE_KEY, SUBSCRIBED_ID_STORAGE_KEY } from '@/constants/storageKeys';
import { authMetadataFactory } from '@/models/factories';
import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth';
import * as authTypes from '@aws-amplify/auth/lib-esm/types';
import { ICredentials } from '@aws-amplify/core';
import { browser } from '@inconvo/utils';
import {
    CognitoAccessToken,
    CognitoIdToken,
    CognitoUser,
    CognitoUserPool,
    CognitoUserSession,
} from 'amazon-cognito-identity-js';
import { config as AwsConfig } from 'aws-sdk/global';
import VueRouter from 'vue-router';
import { Dictionary as RouterDictionary } from 'vue-router/types/router';
import { Dispatch } from 'vuex';
import LoggerService from '@/services/logger.service';
import { getUrlParams } from './Utils';

export default class AuthService {
    private logger;
    private auth: AuthClass;

    constructor(authOptions: authTypes.AuthOptions) {
        this.logger = LoggerService(true);
        this.auth = new AuthClass(authOptions);
    }

    public async signOut(username: string): Promise<void> {
        await ApiClient.signOut(username);
        this.clearSession();
    }

    public getUserSubId(username: string): string | null {
        const userDataItem = browser.storage.local.getItem(
            `CognitoIdentityServiceProvider.${config.userPoolWebClientId}.${username}.userData`,
        );

        if (userDataItem) {
            const userData = JSON.parse(userDataItem);
            const userAttributes: IUserAttribute[] = userData.user_attributes || userData.UserAttributes;

            if (userAttributes.length) {
                const dataMapped = userAttributes.map((o) => ({ [o.Name]: o.Value }));
                const attributes = Object.assign({}, ...dataMapped);
                return attributes?.sub;
            }
        }

        return null;
    }

    public async register(data: IRegisterData): Promise<IRegisterUserResponse> {
        const urlParams: { [key: string]: string } = getUrlParams();
        let source: LoginSource | undefined = data.source;

        if (!source) {
            source = urlParams?.action === AppAction.Signup ? LoginSource.NonOrganic : LoginSource.Organic;
        }

        const payload = {
            username: data.email || data.phoneNumber || data.username,
            application_code: config.applicationCode,
            tenant_code: config.tenantCode,
            locale: data.locale,
            phone_number: data.phoneNumber,
            source: data.source || source,
            redirect_path: data.redirectPath,
            referral: data.referral,
            recaptchaToken: data.recaptchaToken,
            metadata: authMetadataFactory.makePayload(data.metadata),
        } as IRegisterUserPayload;

        if (config.featureToggle.useRecaptcha) {
            return await ApiClient.registerUserWithRecaptcha(payload);
        }

        return await ApiClient.registerUser(payload);
    }

    public async authenticateYougovUser(code: string) {
        const payload = {
            code,
            application_code: config.applicationCode,
            tenant_code: config.tenantCode,
            redirect_path: `${config.embedHost}/login-internal`,
        } as IRegisterYougovUserPayload;

        return await ApiClient.registerYougovUser(payload);
    }

    public async socialLogin(data: ISocialLoginData): Promise<ISocialLoginResponse | null> {
        const payload: ISocialLoginRequest = {
            email: data.email,
            locale: data.locale,
            user_id: data.userId,
            identity_provider: data.identityProvider,
            referral: data.referral,
            application_code: config.applicationCode,
            tenant_code: config.tenantCode,
            metadata: authMetadataFactory.makePayload(data.metadata),
        };

        const response: ISocialLoginResponse = await ApiClient.socialLogin(payload);

        if (response) {
            const keyPrefix = `CognitoIdentityServiceProvider.${config.userPoolWebClientId}`;
            const userData = {
                UserAttributes: response.user_attributes || [],
                Username: response.username || '',
                UserId: response.user_id || '',
            };

            browser.storage.local.setItem(
                `${keyPrefix}.${response.username}.userData`,
                JSON.stringify(userData).toString(),
            );

            browser.storage.local.setItem(PERSON_ID_STORAGE_KEY, response.yg_person_id);

            return response;
        }

        return null;
    }

    public loginWithIdentityProvider({
        provider,
        redirectPath,
        referral,
        metadata,
    }: {
        provider: IdentityProvider;
        redirectPath?: string;
        referral?: string;
        metadata?: IAuthSessionMetadata;
    }): void {
        if (![IdentityProvider.facebook, IdentityProvider.google].includes(provider)) {
            return;
        }

        const cognitoAuthorizeEndpoint = `https://${config.authDomain}/oauth2/authorize`;
        const redirectParam = `redirect_uri=${encodeURIComponent(config.socialLoginRedirectUri)}`;
        const responseTypeParam = 'response_type=code';
        const clientIdParam = `client_id=${config.userPoolWebClientId}`;
        const providerName = provider ? provider.charAt(0).toUpperCase() + provider.slice(1) : '';
        const identityProviderParam = `identity_provider=${providerName}`;
        const scopeParam = 'scope=email openid';
        const callbackOptions: any = {
            redirect: redirectPath || window.location.pathname,
            provider,
        };

        if (referral) {
            callbackOptions.referral = referral;
        }

        const stateParam = `state=${encodeURIComponent(JSON.stringify(callbackOptions))}`;
        const url = `${cognitoAuthorizeEndpoint}?${redirectParam}&${responseTypeParam}&${clientIdParam}&${identityProviderParam}&${scopeParam}&${stateParam}`;

        browser.storage.session.setItem(LOGIN_METADATA_KEY, JSON.stringify(authMetadataFactory.makePayload(metadata)));

        window.location.href = url;
    }

    public async verifyUserAndShowModal(
        dispatch: Dispatch,
        router: VueRouter,
        data: { id: string; forceEmail: boolean; redirectPath: string; referral: string; locale: string },
    ): Promise<void> {
        try {
            if (data.id) {
                dispatch('main/showFullPageLoader', true);
                const response = await this.verifyUser({
                    id: data.id,
                    forceEmail: data.forceEmail,
                    locale: data.locale,
                });
                dispatch('main/toggleModal', {
                    type: ModalType.VerifyUser,
                    payload: {
                        closable: true,
                        redirectPath: data.redirectPath,
                        email: response.masked_email,
                        onBeforeEnterCallback: () => {
                            dispatch('main/showFullPageLoader', false);
                        },
                        onAfterLeaveCallback: () => {
                            router.push({ path: '/' });
                        },
                    },
                });
            } else {
                throw new Error('Id is not defined');
            }
        } catch (error) {
            this.logger.error({ msg: 'verifyUser failed', error });

            dispatch('main/showFullPageLoader', false);
            dispatch('main/toggleModal', {
                type: ModalType.LoginEmail,
                payload: {
                    closable: false,
                    redirectPath: data.redirectPath,
                    referral: data.referral,
                    backdropStyle: 'solid',
                },
            });
        }
    }

    public async verifyUser(data: { id: string; forceEmail: boolean; locale: string }): Promise<IVerifyUserResponse> {
        const payload: IVerifyUserPayload = {
            id: data.id,
            application_code: config.applicationCode,
            tenant_code: config.tenantCode,
            force_email: data.forceEmail,
            locale: data.locale,
        };

        return await ApiClient.verifyUser(payload);
    }

    public answerAuthChallengeWithAmplify(user: CognitoUser, code: string): Promise<CognitoUser> {
        return this.auth.sendCustomChallengeAnswer(user, code);
    }

    public async answerAuthChallenge(
        sessionId: string,
        clientId: string,
        answer: string,
        referral: string,
    ): Promise<IAuthChallengeResponse> {
        const payload = {
            session_id: sessionId,
            client_id: clientId,
            answer,
            referral,
        } as ISendAuthChallengeAnswerPayload;

        return await ApiClient.sendAuthChallengeAnswer(payload);
    }

    public setSession(data: IAuthChallengeResponse): void {
        const userData = {
            UserAttributes: data.user ? data.user.user_attributes : [],
            Username: data.user ? data.user.username : '',
            UserId: data.user ? data.user.user_id : '',
        };

        const username = data.user?.username;
        const keyPrefix = `CognitoIdentityServiceProvider.${config.userPoolWebClientId}`;
        browser.storage.local.setItem(`${keyPrefix}.LastAuthUser`, username);
        browser.storage.local.setItem(
            `${keyPrefix}.${username}.refreshToken`,
            data.auth_result.refresh_token.toString(),
        );
        browser.storage.local.setItem(`${keyPrefix}.${username}.accessToken`, data.auth_result.access_token.toString());
        browser.storage.local.setItem(`${keyPrefix}.${username}.idToken`, data.auth_result.id_token.toString());
        browser.storage.local.setItem(`${keyPrefix}.${username}.userData`, JSON.stringify(userData).toString());
        browser.storage.local.setItem(`${keyPrefix}.${username}.clockDrift`, '0');

        this.setIdentityId(data.identity_id.toString());
    }

    public setIdentityId(identityId: string) {
        browser.storage.local.setItem(`CognitoIdentityId-${config.identityPoolId}`, identityId);
    }

    public clearSession(): void {
        const keyPrefix = `CognitoIdentityServiceProvider.${config.userPoolWebClientId}`;
        const username = browser.storage.local.getItem(`${keyPrefix}.LastAuthUser`);

        browser.storage.local.removeItem(`${keyPrefix}.LastAuthUser`);
        browser.storage.local.removeItem(`CognitoIdentityId-${config.identityPoolId}`);
        browser.storage.local.removeItem(`${keyPrefix}.${username}.refreshToken`);
        browser.storage.local.removeItem(`${keyPrefix}.${username}.accessToken`);
        browser.storage.local.removeItem(`${keyPrefix}.${username}.idToken`);
        browser.storage.local.removeItem(`${keyPrefix}.${username}.userData`);
        browser.storage.local.removeItem(`${keyPrefix}.${username}.clockDrift`);
        browser.storage.local.removeItem(PERSON_ID_STORAGE_KEY);
    }

    public setSubscriberId(subscriberId: string): void {
        browser.storage.local.setItem(SUBSCRIBED_ID_STORAGE_KEY, subscriberId);
    }

    public getSubscriberId(): string | null {
        return browser.storage.local.getItem(SUBSCRIBED_ID_STORAGE_KEY);
    }

    public clearSubscriberId(): void {
        browser.storage.local.removeItem(SUBSCRIBED_ID_STORAGE_KEY);
    }

    public async getCredentials(): Promise<ICredentials> {
        const result = await this.auth.currentCredentials();
        // eslint-disable-next-line dot-notation
        if (result['message'] && result['message'].toLowerCase().includes('access to identity')) {
            browser.storage.local.removeItem(`CognitoIdentityId-${config.identityPoolId}`);
            return await this.auth.currentCredentials();
        }
        return result;
    }

    public async refreshCredentials(): Promise<void> {
        const credentials = await this.getCredentials();
        AwsConfig.credentials = credentials;
    }

    public async getCurrentUser(): Promise<CognitoUser | any> {
        return await this.auth.currentAuthenticatedUser();
    }

    public async getCurrentUserInfo(): Promise<any> {
        return await this.auth.currentUserInfo();
    }

    public async getCurrentSession(): Promise<CognitoUserSession> {
        return await this.auth.currentSession();
    }

    public async getIdToken(): Promise<CognitoIdToken> {
        const session = await this.auth.currentSession();
        return session.getIdToken();
    }

    public async getAccessToken(): Promise<CognitoAccessToken> {
        const session = await this.auth.currentSession();
        return session.getAccessToken();
    }

    public async refreshSession(): Promise<CognitoUserSession> {
        const cognitoUser = await this.getCurrentUser();
        const currentSession = await this.getCurrentSession();
        const session: CognitoUserSession = await new Promise((resolve, reject) =>
            cognitoUser.refreshSession(currentSession.getRefreshToken(), (error, session) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(session);
                }
            }),
        );
        return session;
    }

    public forgotPassword(username: string): Promise<any> {
        return this.auth.forgotPassword(username);
    }

    public async changePassword(oldPassword: string, newPassword: string): Promise<any> {
        const user: CognitoUser = await this.getCurrentUser();
        return this.auth.completeNewPassword(user, oldPassword, newPassword);
    }

    public getPageLoadCounter(): number | null {
        const value = browser.storage.local.getItem('pageLoadCounter');
        if (value) {
            return parseInt(value, 10);
        }
        return null;
    }

    public setPageLoadCounter(value: number): void {
        browser.storage.local.setItem('pageLoadCounter', String(value));
    }

    public removePageLoadCounter() {
        browser.storage.local.removeItem('pageLoadCounter');
    }

    public makeCognitoUser(data: any): CognitoUser {
        const cognitoUser: CognitoUser = new CognitoUser({
            Username: data.userName,
            Pool: new CognitoUserPool({
                UserPoolId: data.userPoolId,
                ClientId: data.clientId,
            }),
        });
        // @ts-ignore
        cognitoUser.Session = data.session;
        return cognitoUser;
    }

    public async ygdGuard({ store, redirect, route, $sentry }) {
        try {
            const idToken = await this.getIdToken();
            const token = idToken.getJwtToken();
            const profile: IYgdProfile = await YougovDirectClient.getProfile(token);
            const isPanelist = profile?.isPanelist;
            const redirectPath = profile?.redirect;

            store.dispatch('yougovDirect/setIsPanelist', isPanelist);

            if (!isPanelist) {
                redirect(redirectPath || '/');
            } else {
                return true;
            }
        } catch (error) {
            if (error !== 'No current user') {
                $sentry.captureException('authService > ygdGuard error', { extra: { error } });
            }
            const path = '/';

            let redirectQuery: RouterDictionary<string>;
            if (config.featureToggle.userVerifyUserEndpoint) {
                redirectQuery = {
                    ...route.query,
                    action: AppAction.VerifyUser,
                    redirect_path: route.fullPath,
                };
            } else {
                redirectQuery = {
                    ...route.query,
                    action: AppAction.Signup,
                    redirect_path: window.location.pathname,
                };
            }
            redirect(path, redirectQuery);
        }

        return false;
    }

    public async getRecaptchaToken(grecaptcha) {
        if (config.featureToggle.useRecaptcha && grecaptcha) {
            return await grecaptcha.enterprise.execute(config.googleSiteKey, { action: 'SINGUP' });
        }
        return null;
    }
}
