import { IAuthSessionMetadata } from '@/interfaces/auth.interface';
import { IAuthSessionMetadataDto } from '@inconvo/types/interfaces';
import { IdentityProvider } from '@inconvo/types/enums';
import { removeUnsetProperties } from '@/services/Utils';

export default class authMetadataFactory {
    public static makePayload(data: IAuthSessionMetadata = {}): IAuthSessionMetadataDto {
        const payload: IAuthSessionMetadataDto = {
            source_channel_code: data.sourceChannelCode,
            source_convo_id: data.sourceConvoId,
            convo_type: data.convoType,
            identity_provider: data.identityProvider || IdentityProvider.cognito,
            utm_campaign: data.utmCampaign,
            utm_source: data.utmSource,
        };

        return removeUnsetProperties(payload);
    }

    public static makeResponse(data: IAuthSessionMetadataDto = {}): IAuthSessionMetadata {
        const response: IAuthSessionMetadata = {
            sourceChannelCode: data.source_channel_code,
            sourceConvoId: data.source_convo_id,
            convoType: data.convo_type,
            identityProvider: data.identity_provider,
            utmCampaign: data.utm_campaign,
            utmSource: data.utm_source,
        };

        return removeUnsetProperties(response);
    }
}
