import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _083388e8 = () => interopDefault(import('../src/pages/about.vue' /* webpackChunkName: "pages/about" */))
const _a8b16012 = () => interopDefault(import('../src/pages/balance.vue' /* webpackChunkName: "pages/balance" */))
const _61babcf7 = () => interopDefault(import('../src/pages/daily/index.vue' /* webpackChunkName: "pages/daily/index" */))
const _dbb755f6 = () => interopDefault(import('../src/pages/embed-redirect.vue' /* webpackChunkName: "pages/embed-redirect" */))
const _1cd9f278 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _1016d654 = () => interopDefault(import('../src/pages/login-internal.vue' /* webpackChunkName: "pages/login-internal" */))
const _e4424b82 = () => interopDefault(import('../src/pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _c08005dc = () => interopDefault(import('../src/pages/rejoin.vue' /* webpackChunkName: "pages/rejoin" */))
const _13220fec = () => interopDefault(import('../src/pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _67aeb26b = () => interopDefault(import('../src/pages/user-preferences.vue' /* webpackChunkName: "pages/user-preferences" */))
const _24ccd65a = () => interopDefault(import('../src/pages/welcome-survey.vue' /* webpackChunkName: "pages/welcome-survey" */))
const _5aa11bc8 = () => interopDefault(import('../src/pages/daily-chat/country.vue' /* webpackChunkName: "pages/daily-chat/country" */))
const _01ee8eb7 = () => interopDefault(import('../src/pages/daily-chat/verify.vue' /* webpackChunkName: "pages/daily-chat/verify" */))
const _c55a5234 = () => interopDefault(import('../src/pages/social-login/callback.vue' /* webpackChunkName: "pages/social-login/callback" */))
const _8da9d4d4 = () => interopDefault(import('../src/pages/channels/_slug/_convoId.vue' /* webpackChunkName: "pages/channels/_slug/_convoId" */))
const _27eb63ad = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _741e738c = () => interopDefault(import('../src/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _083388e8,
    name: "about"
  }, {
    path: "/balance",
    component: _a8b16012,
    name: "balance"
  }, {
    path: "/daily",
    component: _61babcf7,
    name: "daily"
  }, {
    path: "/embed-redirect",
    component: _dbb755f6,
    name: "embed-redirect"
  }, {
    path: "/login",
    component: _1cd9f278,
    name: "login"
  }, {
    path: "/login-internal",
    component: _1016d654,
    name: "login-internal"
  }, {
    path: "/logout",
    component: _e4424b82,
    name: "logout"
  }, {
    path: "/rejoin",
    component: _c08005dc,
    name: "rejoin"
  }, {
    path: "/unsubscribe",
    component: _13220fec,
    name: "unsubscribe"
  }, {
    path: "/user-preferences",
    component: _67aeb26b,
    name: "user-preferences"
  }, {
    path: "/welcome-survey",
    component: _24ccd65a,
    name: "welcome-survey"
  }, {
    path: "/daily-chat/country",
    component: _5aa11bc8,
    name: "daily-chat-country"
  }, {
    path: "/daily-chat/verify",
    component: _01ee8eb7,
    name: "daily-chat-verify"
  }, {
    path: "/social-login/callback",
    component: _c55a5234,
    name: "social-login-callback"
  }, {
    path: "/channels/:slug?/:convoId?",
    component: _8da9d4d4,
    name: "channels-slug-convoId"
  }, {
    path: "/",
    component: _27eb63ad,
    name: "index"
  }, {
    path: "/*",
    component: _741e738c,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
