<template>
    <header :class="[`daily-chat-header daily-chat-header--${variant}`, variant === 'default' && 'yougov-arrow']">
        <div v-if="logoClickable" class="text-center">
            <a v-if="logoClickable" href="/" class="daily-chat-header-logo-link">
                <AppLogo class="daily-chat-header-logo" />
            </a>
        </div>

        <AppLogo v-else class="daily-chat-header-logo" />
    </header>
</template>

<script>
import AppLogo from '@/components/AppLogo';

export default {
    name: 'DailyChatHeader',
    components: {
        AppLogo,
    },
    props: {
        variant: {
            type: String,
            default: 'default',
            validator: (value) => ['product', 'default'].includes(value),
        },
        logoClickable: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="scss">
.daily-chat-header {
    padding: 3rem;
    @include media-query(medium) {
        padding: 3.8rem;
    }
    &--default {
        color: map-get($newPalette, white);
        .daily-chat-header-logo {
            color: map-get($newPalette, white);
        }
    }

    &--product {
        background-color: color($palette, red, 600);
        color: $color-misc-1;

        @include media-query(medium) {
            background-color: $color-misc-1;
            color: $color-accent-1;
        }

        .daily-chat-header-logo {
            color: $color-misc-1;

            @include media-query(medium) {
                color: $color-accent-1;
            }
        }
    }

    .daily-chat-header-logo-link {
        display: inline-block;
    }

    .daily-chat-header-logo {
        margin: 0 auto;
        font-size: 1.8rem;
    }
}
</style>
