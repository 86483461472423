export interface IEvent {
    name: string;
    method: Function;
}

export default class EventService {
    private items: IEvent[];

    constructor() {
        this.items = [];
    }

    public attach(name: string, method: Function) {
        this.items.push({ name, method });
    }

    public detach(name: string, method: Function) {
        this.items = this.items.filter((item) => !(item.name === name && item.method === method));
    }

    public emit(name, data) {
        this.items.filter((item) => item.name === name).forEach((item) => item.method(data));
    }
}
