import { WidgetType } from '@/enums';
import { IWidgetAbTestable } from '@/interfaces';
import Convo from './convo';
import Widget from './widget';

export default class HeroSubscriber extends Widget<Convo> implements Partial<Convo>, IWidgetAbTestable {
    title: string;
    description: string;
    buttonText: string;
    style: string;
    locale: string;
    image: string;
    weight: number;

    constructor() {
        super();
        this.component = WidgetType.HeroSubscriber;
        this.style = '';
        this.title = '';
        this.description = '';
        this.locale = '';
        this.buttonText = '';
        this.image = '';
        this.weight = 0;
    }
}
