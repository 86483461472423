import { Inject } from '@nuxt/types/app';
import EventService from '@/services/event.service';

// use this solution until the fix is pending: https://github.com/storyblok/storyblok-nuxt/pull/29
export default ({ $storybridge, redirect, route, $storyblokHelper }, inject: Inject) => {
    const eventService = new EventService();

    $storybridge.on(['input', 'enterComponent', 'change', 'published'], (event) => {
        if (event.action === 'input' || event.action === 'enterComponent') {
            eventService.emit(event.action, event);
        } else {
            redirect(route.fullPath);
        }
    });

    const storyblokHelper = {
        ...$storyblokHelper,
        addEventListener: (name: string, method: Function): void => {
            eventService.attach(name, method);
        },
        removeEventListener: (name: string, method: Function): void => {
            eventService.detach(name, method);
        },
    };

    inject('storyblokHelper', storyblokHelper);
};
