import { WidgetType } from '@/enums';
import Widget from './widget';
import { Testimonial } from '.';

export default class DailyChatOpinion extends Widget<Testimonial> {
    title: string;
    items: Testimonial[];

    constructor() {
        super();
        this.component = WidgetType.DailyChatOpinion;
        this.items = [];
        this.title = '';
    }
}
