import { IEditable, IWidgetsData } from '@/interfaces';

export class PageError<T = any> extends Error {
    constructor(error: Error, additionalData?: T) {
        super(error.message);
        this.error = error;
        this.additionalData = additionalData;
    }

    error: Error;
    additionalData: T | any;
}
export default class Page implements IEditable {
    _editable: string | undefined;
    id: number;
    sections: any;
    widgetsData: IWidgetsData;
    errors: PageError[];

    constructor() {
        this._editable = '';
        this.id = 0;
        this.sections = {};
        this.widgetsData = {};
        this.errors = [];
    }
}
