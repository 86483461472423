import { NotificationType } from '@/enums';
import { INotification, INotificationOptions } from '@/interfaces';

const NOTIFICATION_TIMEOUT: number = 4000;

const init = ({ store }: { store: object | any }) => {
    if (!store) {
        throw new Error('Please provide vuex store.');
    }

    const addNotification = (text: string, type: NotificationType, options: INotificationOptions = {}) => {
        const notification: INotification = {
            id: Math.round(Date.now() / 1000).toString(),
            text,
            context: options.context || 'app',
            type,
            dismissable: !!options.dismissable,
            timeout: options.timeout || NOTIFICATION_TIMEOUT,
            autohide: options.autohide !== undefined ? options.autohide : true,
            confirmationCallback: options.confirmationCallback,
            confirmable: !!options.confirmationCallback,
            dismissCallback: options.dismissCallback,
            icon: options.icon,
            i18n: options.i18n,
        };

        store.dispatch('notifications/addNotification', {
            notification,
            clearStack: options.clearStack !== false,
        });

        return notification.id;
    };

    const notifications = {
        success: (text: string, options: INotificationOptions = {}) =>
            addNotification(text, NotificationType.Success, options),
        error: (text: string, options: INotificationOptions = {}) =>
            addNotification(text, NotificationType.Error, options),
        warn: (text: string, options: INotificationOptions = {}) =>
            addNotification(text, NotificationType.Warning, options),
        info: (text: string, options: INotificationOptions = {}) =>
            addNotification(text, NotificationType.Info, options),
        dismiss(id: string) {
            store.dispatch('notifications/dissmissNotification', id);
        },
        dismissAll() {
            store.dispatch('notifications/clearNotifications');
        },
        dismissByContext(context: string) {
            store.dispatch('notifications/clearNotifications', context);
        },
        confirm(id: string) {
            store.dispatch('notifications/confirmNotification', id);
        },
    };

    return notifications;
};

export default ({ store }, inject) => {
    inject('notifications', init({ store }));
};
