import { IWidgetsData } from '@/interfaces';
import { IHeroDto } from '@/interfaces/dtos.interface';
import { ConvoCardDescription } from '@/enums';
import Hero from '../hero';
import ConvoFactory from './convo.factory';

export default class HeroFactory {
    public static make(data: IHeroDto, widgetsData: IWidgetsData) {
        const heroObject: Hero = new Hero();

        if (widgetsData[data._uid] instanceof Error) {
            heroObject.showPlaceholder = true;
        }

        heroObject._editable = data._editable;
        heroObject._uid = data._uid;
        heroObject.title = data.title!;
        heroObject.buttonText = data.button_text;
        heroObject.sorting = data.sorting;
        heroObject.items = [];

        if (!heroObject.showPlaceholder) {
            for (const convo of widgetsData[data._uid].items) {
                heroObject.items.push(ConvoFactory.make(convo, ConvoCardDescription.ConvoDescription));
            }
        }

        return heroObject;
    }
}
