import { Route } from 'vue-router';
import { QueryParam } from '@/enums';
import RouterService from '@/services/router.service';

export default ({ route, $sentry }: { route: Route; $sentry: any }, inject: Function) => {
    inject('queryParams', (key: string) => {
        if (!key) {
            return route.query;
        } else if (key === QueryParam.RedirectPath) {
            return RouterService.getRedirectPath(route);
        } else if (key === QueryParam.Referral) {
            return RouterService.getReferral(route, $sentry);
        } else {
            const value = route.query[key];
            return value || '';
        }
    });
};
