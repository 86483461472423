import { IHeroSubscriberDto } from '@/interfaces/dtos.interface';
import HeroSubscriber from '@/models/heroSubscriber';

export default class HeroSubscriberFactory {
    public static make(data: IHeroSubscriberDto) {
        const heroSubscriberObject = new HeroSubscriber();

        heroSubscriberObject._editable = data._editable;
        heroSubscriberObject._uid = data._uid;
        heroSubscriberObject.title = data.title!;
        heroSubscriberObject.description = data.description;
        heroSubscriberObject.style = data.style;
        heroSubscriberObject.buttonText = data.button_text;
        heroSubscriberObject.weight = data.ab_test_variant_weight || 0;

        if (data?.image?.filename) {
            heroSubscriberObject.image = data.image.filename;
        }

        return heroSubscriberObject;
    }
}
