<script>
import { mapActions, mapState } from 'vuex';
import { auth as AuthService } from '@/auth';
import { QueryParam } from '@/enums';

export default {
    data() {
        return {
            channelSlug: this.$queryParams(QueryParam.Channel),
        };
    },
    computed: {
        ...mapState('channels', ['activeChannel']),
        ...mapState('auth', ['clientId', 'isLoggedIn']),
        ...mapState('main', ['skipChatWidgetInit']),
    },
    created() {
        this.meta = { ...this.meta, ...this.$route.meta };
    },
    async mounted() {
        await this.refreshAuth();
        this.onAuth();
        if (!this.skipChatWidgetInit) {
            this.$chatWidget.addEventListener('userSubscribe', (data) => {
                this.setSubscribedUser(data.subscriberId);
            });

            if (this.channelSlug) {
                this.$chatWidget.startChat({ channel: this.channelSlug });
            }
        }
    },
    beforeDestroy() {
        if (!this.skipChatWidgetInit) {
            this.$chatWidget.removeEventListener('userSubscribe');
        }
    },
    methods: {
        ...mapActions('auth', ['refreshAuth', 'setSubscribedUser']),
        onAuth() {
            const pageLoadCounter = AuthService.getPageLoadCounter() || 0;
            AuthService.setPageLoadCounter(pageLoadCounter + 1);
            if (!this.isLoggedIn) {
                return;
            }

            if (!pageLoadCounter) {
                this.$notifications.success(this.$i18n.t('notification.logInSuccessFul'));
            }
        },
    },
};
</script>
