import { IConvoDto } from '@/interfaces/dtos.interface';
import { ConvoCardDescription } from '@/enums';
import { Channel } from '../channel';
import Convo from '../convo';

export default class ConvoFactory {
    public static make(data: IConvoDto, convoCardDescription: ConvoCardDescription): Convo {
        const convo = new Convo();
        convo.createdAt = data.createdAt;
        convo.publishDate = data.publishDate;
        convo.updatedAt = data.updatedAt;
        convo.showFromDate = data.showFromDate;
        convo.relativeShowFrom = data.relativeShowFrom;
        convo.description = this.getDescription(data, convoCardDescription);
        convo.id = data.id;
        convo.image = data.image;
        convo.name = data.name;
        convo.type = data.type;

        const channel = new Channel();
        channel.code = data.channel.code;
        channel.id = data.channel.id;
        channel.name = data.channel.name;
        channel.slug = data.channel.slug;
        channel.tagLine = data.channel.tagLine;
        channel.isStandalone = data.channel.isStandalone;
        channel.sharingTitle = data.channel.sharingTitle;
        channel.sharingDescription = data.channel.sharingDescription;
        channel.squareImage = data.channel.squareImage;
        channel.color = data.channel.color || '#ff6d4a';
        convo.channel = channel;

        return convo;
    }

    private static getDescription(data: IConvoDto, convoCardDescription: ConvoCardDescription): string {
        const map = {
            [ConvoCardDescription.ConvoDescription]: data.description,
            [ConvoCardDescription.ChannelName]: data.channel?.name,
            [ConvoCardDescription.Empty]: '',
        };
        return map[convoCardDescription] ?? '';
    }
}
