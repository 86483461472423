const config = process.env.config as any;

const params = {
    isProduction: config.isProduction === 'true',
    identityPoolId: config.cognitoIdentityPool,
    userPoolWebClientId: config.userPoolWebClientId || '',
    userPoolId: config.userPoolId,
    region: config.awsRegion,
    redirectUriLogIn: config.cognitoRedirectUri,
    redirectUriLogOut: config.redirectUriLogOut,
    authDomain: config.authDomain,
    chatWidgetUrl: config.chatWidgetUrl || '',
    tenantCode: config.tenantCode || '',
    applicationCode: config.applicationCode || '',
    tenantAppKey: config.tenantAppKey || '',
    defaultLocale: config.defaultLocale || 'en-gb',
    defaultLocaleUS: 'en-us',
    defaultCountryCode: config.defaultCountryCode || 'GB',
    defaultCountryCodeUS: 'US',
    usCountryCodeList: ['ca', 'us'],
    host: config.host || '',
    embedHost: config.embedHost || config.host,
    contentfulSpace: config.contentfulSpace || '',
    contentfulAccessToken: config.contentfulAccessToken || '',
    socialLoginRedirectUri: config.socialLoginRedirectUri || '',
    yougovProxyApiBaseUrl: config.yougovProxyApiBaseUrl || '',
    messagingS3BucketUrl: config.messagingS3BucketUrl || '',
    messagingApiBaseUrl: config.messagingApiBaseUrl || '',
    siteName: config.siteName || '',
    storyblokPreviewAccessToken: config.storyblokPreviewAccessToken || '',
    storyblokPublicAccessToken: config.storyblokPublicAccessToken || '',
    maxNumberOfChatBubbles: config.maxNumberOfChatBubbles || 4,
    storageKeyPrefix: config.storageKeyPrefix || '',
    serviceEmail: config.serviceEmail || '',
    featureToggle: {
        authClientLocalStorage: config.featureToggleAuthClientLocalStorage === 'true',
        categoryFilters: config.featureToggleCategoryFilters === 'true',
        showMyConvos: config.featureToggleShowMyConvos === 'true',
        termsPrivacyView: config.featureToggleTermsPrivacyView === 'true',
        userVerifyUserEndpoint: config.featureToggleUserVerifyUserEndpoint === 'true',
        showGoogleLogin: config.featureToggleShowGoogleLogin === 'true',
        useRecaptcha: config.useRecaptcha === 'true',
        useRecontactToken: config.useRecontactToken === 'true',
        countryOfResidenceModal: config.featureToggleCountryOfResidenceModal === 'true',
        useDailyChat: config.useDailyChat === 'true',
        dailyChatProductPageEnabled: config.dailyChatProductPageEnabled === 'true',
        dailyChatSignupOverlayEnabled: config.dailyChatSignupOverlayEnabled === 'true',
        recontactSessionVerificationEnabled: config.recontactSessionVerificationEnabled === 'true',
    },
    facebookPixelId: config.facebookPixelId,
    keyweeId: config.keyweeId,
    gtmId: config.gtmId,
    googleSiteKey: config.googleSiteKey,
    authRefreshTimerInterval: config.authRefreshTimerInterval || '',
    ip2cApi: config.ip2cApi,
    dailyChatAllowedCountryCodes: config.dailyChatAllowedCountryCodes,
    experimentsCookieExpiry: config.experimentsCookieExpiry,
    localeCookieExpiry: config.localeCookieExpiry,
    countryCodeCookieExpiry: config.countryCodeCookieExpiry,
    dailyChatChannels: config.dailyChatChannels,
    subcriptionTopicOptions: config.subcriptionTopicOptions,
    cognitoTokenRefreshExpiryOffset: config.cognitoTokenRefreshExpiryOffset || 2 * 60 * 1000,
    storageKeys: {
        identityId: `${config.storageKeyPrefix}.CognitoIdentityId.${config.cognitoIdentityPool}`,
        credentials: `${config.storageKeyPrefix}.Credentials`,
    },
    cache: {
        ttl: {
            appOptions: config.cache?.ttl?.appOptions,
        },
    },
    sentryIgnoreErrors: config.sentryIgnoreErrors,
} as any;

export default params;
