<template>
    <svg-icon :name="name" :style="{ fill: color, width: width && `${width}px`, height: height && `${height}px` }" />
</template>

<script>
export default {
    name: 'Icon',
    props: {
        name: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            default: undefined,
        },
        width: {
            type: Number,
            default: 24,
        },
        height: {
            type: Number,
            default: 24,
        },
    },
};
</script>

<style lang="scss" scoped>
.icon {
    display: block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
}
</style>
