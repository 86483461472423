import { IFooterDto } from '@/interfaces/dtos.interface';
import Footer from '../footer';
import SocialButton from '../socialButton';
import FooterItemFactory from './footerItem.factory';

export default class FooterFactory {
    public static make(data: IFooterDto): Footer {
        const year = new Date().getFullYear();
        const footer = new Footer();
        footer._editable = data._editable;
        footer._uid = data._uid;
        footer.copyright = data.copyright || '';
        footer.copyright = footer.copyright.replace('{year}', year.toString());
        footer.items = [];
        footer.socialButtons = [];

        for (const item of data.items) {
            footer.items.push(FooterItemFactory.make(item));
        }

        for (const socialButtonData of data.social_buttons) {
            const socialButton = new SocialButton();
            socialButton._editable = socialButtonData._editable;
            socialButton._uid = socialButtonData._uid;
            socialButton.title = socialButtonData.title;
            socialButton.href = socialButtonData.url;
            socialButton.icon = `footer-${socialButtonData.icon}`;

            footer.socialButtons.push(socialButton);
        }

        return footer;
    }
}
