import { IEditable } from '@/interfaces/storyblok.interface';

export default class SocialButton implements IEditable {
    _editable: string;
    _uid: string;
    title: string;
    href: string;
    icon: string;
    target: string;
    rel: string;

    constructor() {
        this._editable = '';
        this._uid = '';
        this.title = '';
        this.href = '';
        this.icon = '';
        this.target = '_blank';
        this.rel = 'noopener';
    }
}
