const middleware = {}

middleware['channel-redirect'] = require('../src/middleware/channel-redirect.ts')
middleware['channel-redirect'] = middleware['channel-redirect'].default || middleware['channel-redirect']

middleware['redirects'] = require('../src/middleware/redirects.ts')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

middleware['storyblok'] = require('../src/middleware/storyblok.ts')
middleware['storyblok'] = middleware['storyblok'].default || middleware['storyblok']

export default middleware
