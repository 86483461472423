<template>
    <ValidationProvider
        v-slot="{ validated, invalid, errors }"
        ref="provider"
        :name="label || name"
        :rules="rules"
        tag="div"
    >
        <div
            class="input-wrapper"
            :class="[
                `input-wrapper--variant-${variant}`,
                `input-wrapper--error-${errorStyle}`,
                { 'input-wrapper--has-error': validated && invalid, 'input-wrapper--disabled': disabled },
            ]"
        >
            <label :for="id" class="label" :class="{ 'label--small': innerValue !== '' || isFocused }">
                {{ label }}
            </label>

            <input
                :id="id"
                ref="input"
                v-model="innerValue"
                :class="classArray"
                :type="type"
                :name="name"
                :placeholder="placeholder"
                :style="{ backgroundColor }"
                :autocomplete="autocomplete"
                :maxlength="maxLength"
                :disabled="disabled"
                v-bind="$attrs"
                @focus="onFocus"
                @blur="onBlur"
                @input="onInput"
            />

            <div v-if="errors && errors.length" class="error-message">{{ errors[0] }}</div>
            <span v-if="help !== ''" class="help">{{ help }}</span>
        </div>
    </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import debounce from 'lodash/debounce';
import { InputVariant, InputErrorStyle } from '@/enums';

export default {
    name: 'AppInput',
    components: {
        ValidationProvider,
    },
    props: {
        type: {
            type: String,
            required: true,
        },
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: '',
        },
        variant: {
            type: String,
            default: InputVariant.Primary,
            validator: (value) => Object.values(InputVariant).includes(value),
        },
        rules: {
            type: [Object, String],
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
        backgroundColor: {
            type: String,
            default: 'transparent',
        },
        help: {
            type: String,
            default: '',
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        externalErrors: {
            type: Array,
            default: () => [],
        },
        autocomplete: {
            type: String,
            default: undefined,
        },
        maxLength: {
            type: Number,
            default: undefined,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        errorStyle: {
            type: String,
            default: InputErrorStyle.default,
            validator: (value) => Object.values(InputErrorStyle).includes(value),
        },
    },
    data() {
        return {
            isFocused: false,
            innerValue: this.value,
        };
    },
    computed: {
        classArray() {
            return [this.class, 'input', `input--${this.variant}`];
        },
    },
    watch: {
        value(value) {
            this.innerValue = value;
            this.$emit('changed');
        },
        innerValue: debounce(function (value) {
            this.$emit('input', value);
        }, 250),
        externalErrors(newErr, oldErr) {
            if (newErr.length > oldErr.length) {
                this.$refs.provider.applyResult({
                    errors: newErr,
                    valid: false,
                    failedRules: {},
                });
            }
        },
    },
    async mounted() {
        if (this.autofocus) {
            await this.$nextTick();
            this.$refs.input.focus();
            this.$refs.input.click();
        }
    },
    methods: {
        onInput(event) {
            this.$emit('input', event.target.value);
        },
        onFocus() {
            this.isFocused = true;
            this.$emit('focus');
        },
        onBlur() {
            this.isFocused = false;
            this.$emit('blur');
        },
        onClear() {
            this.innerValue = '';
            this.$emit('onClear', this.innerValue);
        },
        focus() {
            this.$refs.input.focus();
        },
    },
};
</script>

<style scoped lang="scss">
$input-placeholder-color-secondary: #e6e3ec !default;
$input-placeholder-color-tertiary: #ada7ba !default;

.input-wrapper {
    position: relative;

    &--has-error {
        .help {
            color: $error-color;
        }

        &.input-wrapper--error-default {
            .input {
                border-color: $error-color;
            }
        }
    }

    &--disabled {
        opacity: 0.8;
    }
}
.input {
    outline: 0;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $form-input-border-color;
    padding: 5px $default-horizontal-space;
    font-size: $default-font-size;
    font-weight: $bold-font-weight;
    font-family: $font-heading;
    line-height: 1.5;
    letter-spacing: 0.15px;
    width: 100%;

    &--secondary,
    &--tertiary,
    &--transparent {
        height: 4rem;

        @include media-query(medium) {
            height: 4.4rem;
        }
    }

    &--secondary,
    &--tertiary {
        border-bottom: none;
        font-weight: $font-weight-semibold;
        padding: 1rem 2rem;
    }

    &--secondary {
        color: $color-white;
        background-color: rgba($color-white, 0.2);

        &::placeholder {
            color: $input-placeholder-color-secondary;
        }
    }

    &--tertiary {
        background-color: $color-white;

        &::placeholder {
            color: $input-placeholder-color-tertiary;
        }
    }

    &--slim {
        height: 3.8rem;
        border-bottom: none;
        padding: 0.9rem 1rem;
        font-weight: $font-weight-medium;
        font-size: 1.2rem;
        color: $default-text-color;

        @include media-query(medium) {
            font-size: 1.6rem;
        }
    }

    &--transparent {
        color: $default-text-color;
        background-color: transparent;
        border: 0.1rem solid $color-near-black;
        border-radius: 0.1rem;
        font-weight: $font-weight-semibold;
    }

    &--rounded {
        height: 4.5rem;
        padding: 0.5rem 2.6rem 0.8rem;
        border: solid 2px $light-grey;
        border-radius: 2.3rem;
        background: transparent;
        color: $light-grey;
        font-size: 1.8rem;
        font-weight: $font-weight-medium;

        &::placeholder {
            color: $light-grey;
            opacity: 1;
        }
    }
}
.input-wrapper:not(.input-wrapper--variant-outline) {
    .label {
        pointer-events: none;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        padding: 0 $default-horizontal-space;
        position: absolute;
        top: 7px;
        left: 0;
        font-size: 16px;
        white-space: nowrap;
        opacity: 0.6;
        transition: all 200ms ease;

        &--small {
            font-size: 12px;
            transform: translateY(-17px);
        }
    }
}

.help {
    padding: 4px $default-horizontal-space;
    font-size: 10px;
    font-weight: 300;
    color: #b5b5b5;
    line-height: normal;
    display: inline-block;
}
.error-message {
    padding: 4px $default-horizontal-space;
    font-size: 10px;
    font-weight: 500;
    color: $error-color;

    .input-wrapper--error-highlighted & {
        display: inline-block;
        position: relative;
        margin-top: 1.2rem;
        background: $color-white;
        border-radius: 0.4rem;

        &:before {
            content: '';
            position: absolute;
            top: -0.6rem;
            left: 1.2rem;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0.7rem 0.6rem 0.7rem;
            border-color: transparent transparent $color-white transparent;
        }
    }
}

.input-wrapper--variant {
    &-outline {
        .label {
            display: inline-block;
            font-size: $small-font-size;
            @include media-query(medium) {
                font-size: $default-font-size;
            }
        }
        .input {
            outline: 0;
            border-radius: 0.2rem;
            border: 0.1rem solid map-get($newPalette, shades, 600);
            margin-top: 1rem;
            padding: 1.4rem;
            font-size: $default-font-size;
            font-weight: $bold-font-weight;
            font-family: $sans-serif;
            line-height: 1.2;
            letter-spacing: 0.15px;
        }
        &.input-wrapper--disabled {
            opacity: 1;
        }
        &:disabled {
            pointer-events: none;
        }
    }
}
</style>
