import { IYougovApiClient } from '@/api/interfaces/yougovApiClient.interface';
import config from '@/config';
import { IRegionDto } from '@/interfaces/dtos.interface';
import RequestService from '@/services/request.service';
import { AxiosRequestConfig } from 'axios';
import { IWelcomeSurveyRequest, IWelcomeSurveyResponse } from '../interfaces/yougovApiClient.interface';

class YougovClient implements IYougovApiClient {
    public async getRegions(): Promise<IRegionDto[]> {
        const requestConfig: AxiosRequestConfig = {
            baseURL: config.yougovProxyApiBaseUrl,
        };

        const response = await RequestService.get('regions', requestConfig);
        return (response as unknown) as Promise<IRegionDto[]>;
    }

    public async getWelcomeSurveyLink(payload: IWelcomeSurveyRequest): Promise<IWelcomeSurveyResponse> {
        const requestConfig: AxiosRequestConfig = {
            baseURL: config.yougovProxyApiBaseUrl,
        };
        const response = await RequestService.get(
            `members/welcome-survey/?user_id=${payload.user_id}&sub_id=${payload.sub_id}`,
            requestConfig,
        );
        return (response as unknown) as Promise<IWelcomeSurveyResponse>;
    }
}

export default new YougovClient();
