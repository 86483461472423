export enum ModalType {
    CompleteProfile = 'completeProfile',
    ForgotPassword = 'forgotPassword',
    AuthChallenge = 'authChallenge',
    NewPassword = 'newPassword',
    UserDetails = 'userDetails',
    LoginEmail = 'loginEmail',
    Subscribe = 'subscribe',
    EmailSent = 'emailSent',
    Register = 'register',
    Iframe = 'iframe',
    Signup = 'signup',
    VerifyUser = 'verifyUser',
    ChatController = 'ChatController',
    CountryOfResidenceModal = 'countryOfResidenceModal',
    DailyChatSignupModal = 'dailyChatSignupModal',
}
