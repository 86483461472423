import { IMediaObjectDto } from '@/interfaces/dtos.interface';
import { ImagePosition } from '@/enums';
import MediaObject from '../mediaObject';

export default class MediaObjectFactory {
    public static make(data: IMediaObjectDto): MediaObject {
        const mediaObject = new MediaObject();

        mediaObject.text = data.text;
        mediaObject.image = data.image;
        mediaObject.imagePosition = data.image_position as ImagePosition;

        if (data.user_name) {
            mediaObject.additionalInfo.userName = data.user_name;
        }

        if (data.user_icon) {
            mediaObject.additionalInfo.userIcon = data.user_icon;
        }

        return mediaObject;
    }
}
