<template>
    <div class="layout layout--default" :class="{ 'layout--collapse-header': embedded }">
        <full-page-loading-spinner type="dots" :show="isFullPageLoaderVisible" />
        <div class="layout__main">
            <AppHeader
                v-if="!embedded"
                :menu-open="menuOpen"
                :show-login-button="showLoginButton"
                :is-panelist="isPanelist"
                :collapsed="scrollTop > 0"
                :is-logged-in="userIsLoggedIn"
                :display-name="displayName"
                @toggleMenu="toggleMenu"
                @toggleModal="toggleModal"
                @joinButtonClick="onJoinButtonClick"
                @logout="onLogout"
            />
            <div class="layout__content-container">
                <Nuxt />
            </div>
        </div>
        <AppFooter
            v-if="!embedded"
            :data="footer"
            :is-auth-refreshed="authRefreshed"
            :is-ygd-panelist="isPanelist"
            @setRegion="onSetRegion"
        />

        <YougovFooter v-else />

        <ChatController
            v-if="chatControllerIsVisible"
            class="chat-controller"
            :channel-list="chatControllerChannelList"
            :is-chat-open="chatControllerIsChatOpen"
            @bubbleClick="onChatBubbleClick"
            @mobileButtonClick="onChatMobileButtonClick"
        />

        <ModalContainer
            v-if="activeModal.type !== null"
            :modal-type="activeModal.type"
            :modal-z-index="modal.props.modalZIndex"
            :backdrop-style="modal.props.backdropStyle"
            :closable="modal.props.closable && modal.props.closable === true"
            :padding-size="modal.props.paddingSize"
            :is-rounded="modal.props.isRounded"
            :shadow="modal.props.shadow"
            :close-callback="modal.props.closeCallback"
            :on-before-enter-callback="modal.props.onBeforeEnterCallback"
            :on-after-leave-callback="modal.props.onAfterLeaveCallback"
            @afterClose="toggleModal({ type: null })"
        >
            <template v-slot:modal="{ close }">
                <Modals
                    :modal="modal"
                    @userLogin="onUserSignupAction"
                    @toggleModal="toggleModal($event)"
                    @close="close"
                    @resendCode="onResendCode"
                    @userAuthChallenge="onUserAuthChallenge"
                    @updateUserAccount="onUpdateUserAccount"
                    @userSubscribe="onUserSubscribe"
                    @verifyUser="verifyUser"
                    @updateModal="onUpdateModal"
                    @confirmCountryOfResidence="onConfirmCountryOfResidence"
                />
            </template>
        </ModalContainer>
        <SystemNotifications :notifications="notifications" />
        <ChatClient :chat-widget-options="appOptions.options && appOptions.options.chatWidget" />
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { ChatWigetType, EmailValidationStatus, StorySlug, ModalType, MediaQuery, QueryParam } from '@/enums';
import throttle from 'lodash.throttle';
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';
import YougovFooter from '@/components/YougovFooter';
import SystemNotifications from '@/components/SystemNotifications';
import ChatClient from '@/components/ChatClient';
import ModalsMixin from '@/mixins/ModalsMixin';
import LayoutMixin from '@/mixins/LayoutMixin';
import ChatMixin from '@/mixins/ChatMixin';
import FullPageLoadingSpinner from '@inconvo/components/src/components/FullPageLoadingSpinner.vue';
import ChatController from '@/components/chat/ChatController';
import config from '@/config';
import { auth as AuthService } from '@/auth';
import ContentService from '@/services/content.service';
import MetadataService from '@/services/metadata.service';
import RouterService from '@/services/router.service';
import AbTestService from '@/services/ab-test.service';
import { browser } from '@inconvo/utils';

export default {
    scrollToTop: true,
    name: 'DefaultLayout',
    components: {
        ModalContainer: () => import('@/components/ModalContainer.vue'),
        Modals: () => import('@/components/modals/Modals'),
        FullPageLoadingSpinner,
        AppHeader,
        AppFooter,
        SystemNotifications,
        ChatController,
        ChatClient,
        YougovFooter,
    },
    mixins: [LayoutMixin, ModalsMixin, ChatMixin],
    async fetch() {
        try {
            const abTestService = new AbTestService(this.$cookies);
            const contentService = new ContentService(this.$storyapi, abTestService, this.$logger);
            const story = await contentService.getStory({
                story: StorySlug.DefaultLayout,
                version: this.$storyblokHelper.version,
                locale: this.$i18n.locale,
                options: {
                    resolve_relations: 'metadata',
                },
                hasFallback: true,
            });

            this.storyId = story.id;
            this.footer = contentService.makeFooterModel(story.content.footer[0]);
            const metadata = contentService.makeMetadataModel(story.content.metadata.content);
            const metadataService = new MetadataService(
                {
                    defaultMetatags: metadata.metatags,
                    i18nSeo: this.$nuxtI18nSeo(),
                    url: `${config.host}${this.$route.fullPath}`,
                    title: metadata.siteTitle,
                },
                this.$logger,
            );

            this.metadata = metadataService.getMetadata({
                isCookieBannerEnabled: this.embedded ? false : this.showCookieBanner,
            });
        } catch (error) {
            this.$logger.error({ msg: 'error.defaultLayout.fetch', error });
        }
    },
    data() {
        return {
            scrollTop: 0,
            storyId: undefined,
            footer: {},
            metadata: {},
            embedded: this.$queryParams(QueryParam.Embedded) === '1',
        };
    },
    computed: {
        ...mapGetters('auth', ['emailValidationStatus', 'subscriberId', 'email']),
        ...mapState('main', [
            'activeModal',
            'menuOpen',
            'modalIframeUrl',
            'isFullPageLoaderVisible',
            'skipChatWidgetInit',
            'locale',
            'isDailyChatEnabled',
            'appOptions',
        ]),
        ...mapGetters('main', ['showCookieBanner']),
        ...mapState('auth', ['isLoggedIn', 'errors', 'user', 'authRefreshed', 'userId', 'userProfile']),
        ...mapState('notifications', ['notifications']),
        ...mapState('yougovDirect', ['isPanelist']),
        ...mapGetters('chatController', {
            chatControllerActiveChannel: 'activeChannel',
        }),
        ...mapState('chatController', {
            chatControllerChannelList: 'channelList',
            chatControllerIsChatOpen: 'isChatOpen',
            chatControllerIsVisible: 'isVisible',
        }),
        ...mapState('channels', ['activeChannel']),
        modalData() {
            const payload = this.activeModal.payload ? this.activeModal.payload : {};
            return {
                ...payload,
                disabledTimer: this.resendButtonDisabledTimer,
                username: this.username,
            };
        },
        userIsLoggedIn() {
            return this.authRefreshed && this.isLoggedIn;
        },
        showLoginButton() {
            return this.authRefreshed && !this.isLoggedIn;
        },
        displayName() {
            return this.userProfile.name || this.userProfile.email || this.email;
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll, true);
        const abTestService = new AbTestService(this.$cookies);
        this.contentService = new ContentService(this.$storyapi, abTestService, this.$logger);
        this.$storyblokHelper.addEventListener('input', this.onStoryblokInput);
        if (!this.skipChatWidgetInit) {
            this.$chatWidget.addEventListener('channelJump', this.onChannelLogicJump);
        }
        this.chatControllerLoadState();

        this.chatWidgetMediaQuery = new browser.MediaQueryService(MediaQuery.FullScreenChatWidget);

        const unwatch = this.$watch(
            'authRefreshed',
            (newValue) => {
                if (newValue) {
                    if (unwatch) {
                        unwatch();
                    }

                    this.prepareChat();

                    if (this.isLoggedIn && (!this.userProfile || !this.userProfile?.name)) {
                        this.getUserAccount();
                    }
                }
            },
            {
                immediate: true,
            },
        );

        this.chatWidgetMediaQuery.addEventListener(this.onChatWidgetMediaQueryChange);
        this.onChatWidgetMediaQueryChange(this.chatWidgetMediaQuery);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll, true);
        this.$storyblokHelper.removeEventListener('input', this.onStoryblokInput);
        if (!this.skipChatWidgetInit) {
            this.$chatWidget.removeEventListener('channelJump', this.onChannelLogicJump);
        }

        this.chatWidgetMediaQuery.removeEventListener(this.onChatWidgetMediaQueryChange);
    },
    methods: {
        ...mapActions('main', [
            'toggleModal',
            'updateModal',
            'toggleMenu',
            'showFullPageLoader',
            'setLocale',
            'setCountryIso2Code',
        ]),
        ...mapActions('auth', {
            onUserAuthChallenge: 'onUserAuthChallenge',
            onUserSignupAction: 'onUserSignup',
            updateUserAccount: 'updateUserAccount',
            onUserResendCode: 'onUserResendCode',
            getAuthData: 'getAuthData',
            onConfirmCountryOfResidence: 'onConfirmCountryOfResidence',
            onUserSubscribeAction: 'onUserSubscribe',
            getUserAccount: 'getUserAccount',
        }),
        ...mapActions('chatController', {
            chatControllerStartChat: 'startChat',
            chatControllerRemoveActive: 'removeActive',
            chatControllerLoadState: 'loadState',
            chatControllerToggleIsChatOpen: 'toggleIsChatOpen',
            chatControllerOnUserLogout: 'onUserLogout',
        }),
        ...mapActions('channels', ['getChannel']),
        onLogout() {
            this.$chatWidget.resetChat();
            this.chatControllerOnUserLogout();
            this.$router.push({ path: '/logout' });
        },
        onUpdatedSubscription() {
            if (config.featureToggle.showMyConvos && this.isLoggedIn) {
                this.getSubscribedChannels();
            }
        },
        async onUpdateUserAccount(data) {
            try {
                await this.updateUserAccount(data);
            } catch (error) {
                this.$logger.warn({ msg: 'error.onUpdateUserAccount', error });
            }
            if (this.user.attributes.name) {
                this.toggleModal();
            }
        },
        async onResendCode() {
            if (this.user && this.user.username) {
                await this.onUserResendCode({
                    username: this.user.username,
                    redirectPath: this.$queryParams(QueryParam.RedirectPath),
                    referral: this.$queryParams(QueryParam.Referral),
                    recaptchaToken: await AuthService.getRecaptchaToken(window.grecaptcha),
                });
            }
        },
        handleScroll: throttle(function () {
            this.scrollTop = window.scrollY;
        }, 100),
        async onUserSubscribe({ email, metadata }) {
            try {
                const subscriberId = await this.onUserSubscribeAction({ email, metadata });
                if (subscriberId) {
                    this.$chatWidget.setSubscribedUser({ subscriberId });
                }
                if (this.emailValidationStatus === EmailValidationStatus.Valid) {
                    this.toggleModal();
                }
            } catch (error) {
                this.$logger.warn({ msg: 'error.onUserSubscribe', error });
            }
        },
        async verifyUser(modalData) {
            const id = this.subscriberId || this.userId;
            try {
                this.updateModal({
                    payload: {
                        ...modalData,
                        resendIsLoading: true,
                    },
                });
                await AuthService.verifyUser({ id, forceEmail: true, locale: this.locale });
                this.$notifications.success(this.$t('verifyUserModal.resendNotification.success'));
            } catch (error) {
                this.$logger.warn({ msg: 'error.verifyUser', error });
                this.$notifications.error(this.$t('verifyUserModal.resendNotification.error'));
            } finally {
                this.updateModal({
                    payload: {
                        ...modalData,
                        resendIsLoading: false,
                        onAfterLeaveCallback: () => {
                            this.$router.push({ path: '/' });
                        },
                    },
                });
            }
        },
        onStoryblokInput(event) {
            if (event.story.id === this.storyId) {
                this.footer = this.contentService.makeFooterModel(event.story.content.footer[0]);
            }
        },
        async prepareChat() {
            if (!this.skipChatWidgetInit) {
                const authData = await this.getAuthData();
                this.$chatWidget.setAuthData(authData);
            }

            if (
                !this.chatControllerIsVisible ||
                (this.chatControllerActiveChannel && this.chatWidgetMediaQuery.matches)
            ) {
                this.chatControllerToggleIsChatOpen(false);
            } else if (
                this.chatControllerActiveChannel &&
                !this.chatWidgetMediaQuery.matches &&
                this.chatControllerIsVisible
            ) {
                const options = {
                    channel: this.chatControllerActiveChannel,
                    currentThreadId: this.chatControllerActiveChannel.currentThreadId,
                };
                if (this.chatControllerActiveChannel.activeConvoId) {
                    options.convo = this.chatControllerActiveChannel.activeConvoId;
                    options.activeConvoId = this.chatControllerActiveChannel.activeConvoId;
                }
                if (!this.skipChatWidgetInit) {
                    this.$chatWidget.startChat(options);
                }
            }
        },
        onChatBubbleClick(channel) {
            if (this.chatControllerActiveChannel && this.chatControllerActiveChannel.slug === channel.slug) {
                this.chatControllerToggleIsChatOpen(false);
                this.$chatWidget.hideChat();
            } else {
                this.chatControllerStartChat(channel);
                const options = {
                    currentThreadId: this.chatControllerActiveChannel.currentThreadId,
                    channel,
                };
                if (this.chatControllerActiveChannel.activeConvoId) {
                    options.activeConvoId = this.chatControllerActiveChannel.activeConvoId;
                }
                this.$chatWidget.startChat(options);
            }
        },
        onChatMobileButtonClick() {
            this.toggleModal({
                type: ModalType.ChatController,
                payload: {
                    closable: true,
                    backdropStyle: 'none',
                    channelList: this.chatControllerChannelList,
                    modalZIndex: 101,
                    onChatBubbleClick: (channel) => {
                        this.onChatBubbleClick(channel);
                        setTimeout(() => {
                            this.toggleModal();
                        }, 800);
                    },
                },
            });
        },
        onChatWidgetMediaQueryChange(event) {
            if (!this.skipChatWidgetInit) {
                if (event.matches) {
                    this.$chatWidget.changeMode(ChatWigetType.FullScreen);
                } else {
                    this.$chatWidget.changeMode(ChatWigetType.Host);
                }
            }
        },
        async onChannelLogicJump({ channelSlug, convoId }) {
            await this.getChannel(channelSlug);
            let channel = this.chatControllerChannelList.find((item) => item.slug === this.activeChannel.slug);

            if (!channel) {
                channel = this.activeChannel;
            }
            channel.activeConvoId = convoId;
            this.chatControllerStartChat(channel);
            this.$chatWidget.startChat({ channel, convo: convoId });
        },
        onJoinButtonClick() {
            const id = this.subscriberId || this.userId;

            if (id) {
                AuthService.verifyUserAndShowModal(this.$store.dispatch, this.$router, {
                    id,
                    forceEmail: false,
                    redirectPath: this.$queryParams(QueryParam.RedirectPath),
                    referral: this.$queryParams(QueryParam.Referral),
                    locale: this.locale,
                });
            } else {
                this.toggleModal({
                    type: ModalType.LoginEmail,
                    payload: {
                        closable: true,
                        backdropStyle: 'solid',
                        redirectPath: RouterService.getRedirectPath(this.$route),
                        referral: this.$queryParams(QueryParam.Referral),
                    },
                });
            }
        },
        async onSetRegion(region) {
            const locale = region.id;
            const countryCode = region.countryCode?.toUpperCase();

            if (this.$route.query.countryCode && this.$route.query.countryCode !== countryCode) {
                this.$router.push({ query: { countryCode } });
            }

            if (this.$route.query.locale && this.$route.query.locale !== locale) {
                this.$router.push({ query: { locale } });
            }

            if (locale !== this.$i18n.locale) {
                await this.setLocale({ locale });
                this.setCountryIso2Code(countryCode);

                this.$nuxt.refresh();
                window.scrollTo(0, 0);
            }
        },
    },
    head() {
        return this.metadata;
    },
};
</script>

<style lang="scss" scoped>
$border-radius: 40px;

.layout {
    &--default {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding-top: $header-height-mobile;

        @include media-query(medium) {
            padding-top: $header-height-desktop;
        }
    }

    &--collapse-header {
        padding-top: 0;
    }

    &__main {
        flex: 1 1 auto;
    }

    &__content-container {
        position: relative;
        z-index: 2;
        min-height: 100%;
        background-color: $default-light-color;
        color: $title-text-color;
    }
}

.chat-controller {
    position: fixed;
    bottom: 36px;
    right: 28px;
    z-index: $chat-controller-z-index;
}
</style>
