<script>
import { ModalType } from '@/enums/modalType.enum';
import { mapState, mapActions } from 'vuex';

export default {
    computed: {
        // ...mapState('auth', ['errors']),
        ...mapState('main', ['activeModal']),
        // ...mapGetters('auth', ['emailValidationStatus']),
        modal() {
            const data = this.activeModal.payload ? this.activeModal.payload : {};
            return {
                type: this.activeModal.type || ModalType.LoginEmail,
                props: {
                    ...data,
                    emailValidationStatus: this.emailValidationStatus,
                    errors: this.errors,
                },
            };
        },
    },
    methods: {
        ...mapActions('main', ['updateModal']),
        onUpdateModal(payload) {
            this.updateModal(payload);
        },
    },
};
</script>
