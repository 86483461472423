import { IEditable } from '@/interfaces/storyblok.interface';
import LocaleSelector from './localeSelector';
import SocialButton from './socialButton';
import LinkList from './linkList';

export default class Footer implements IEditable {
    _editable: string;
    _uid: string;
    copyright: string;
    socialButtons: SocialButton[];
    items: (LinkList | LocaleSelector)[];

    constructor() {
        this._editable = '';
        this._uid = '';
        this.copyright = '';
        this.socialButtons = [];
        this.items = [];
    }
}
