import { ILinkDto } from '@/interfaces/dtos.interface';
import { LinkStyle } from '@/enums';
import Link from '../link';

export default class LinkFactory {
    public static make(data: ILinkDto): Link {
        const link = new Link();
        link._editable = data._editable;
        link._uid = data._uid;
        link.href = data.url && data.url.cached_url;
        link.style = data.style as LinkStyle;
        link.target = data.target;
        link.name = data.name;
        link.isExternal = data.is_external;
        link.ygdPanelist = data.ygd_panelist;
        link.rel = link.target === '_blank' ? 'noopener' : undefined;

        return link;
    }
}
