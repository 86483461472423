<template>
    <div :class="`section-header section-header--${variant}`">
        <div v-if="variant === $options.SectionHeaderVariant.DailyChat" class="section-header-icon">
            <Icon v-if="icon" :name="icon" :height="null" :width="null" />
        </div>
        <h2 class="section-header-text">{{ text }}</h2>
    </div>
</template>

<script>
import Icon from '@/components/Icon';
import { SectionHeaderVariant } from '@/enums';

export default {
    name: 'SectionHeader',
    components: {
        Icon,
    },
    props: {
        icon: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            required: true,
        },
        variant: {
            type: String,
            default: SectionHeaderVariant.Default,
            validator: (value) => Object.values(SectionHeaderVariant).includes(value),
        },
    },
    created() {
        this.$options.SectionHeaderVariant = SectionHeaderVariant;
    },
};
</script>

<style lang="scss" scoped>
.section-header {
    display: flex;
    align-items: center;
    color: $color-accent-1;
    position: relative;
    padding: 0 0 1.2rem 0;

    &:before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 2px;
        z-index: -1;
        background-image: linear-gradient(
            to left,
            map-get($newPalette, orchid, 300),
            map-get($newPalette, blue, 300) 35%,
            map-get($newPalette, red, 300) 60%,
            map-get($newPalette, red, 300)
        );
    }

    &--daily-chat {
        padding: 2rem 2.4rem;

        @include media-query(medium) {
            border-top: none;
            border-left: none;
            border-right: none;
        }
    }
}

.section-header-text {
    font-size: 2rem;
    font-weight: $font-weight-medium;
    color: $color-accent-1;
    line-height: 1.4;

    @include media-query(medium) {
        font-size: $larger-font-size;
        font-weight: $font-weight-semibold;
        line-height: 1.39;
    }

    .section-header--daily-chat & {
        text-align: center;
        width: 100%;

        @include media-query(medium) {
            text-align: left;
        }
    }
}

.section-header-icon {
    .section-header--daily-chat & {
        width: 4rem;
        height: 3rem;
        background-color: $color-white;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: auto;
            height: 3rem;
        }

        @include media-query(medium) {
            margin-right: 0.4rem;
            position: initial;
            left: 0;
            transform: none;
            background-color: transparent;

            svg {
                height: 2.7rem;
            }
        }
    }
}
</style>
