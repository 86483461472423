import { IGetConvosRequest } from '@/api/interfaces/contentApiClient.interface';
import ApiClient from '@/api/server/apiClient';
import config from '@/config';
import { ErrorCodes, StoryBlokVersion, WidgetType } from '@/enums';
import { IGetStoryParams, IPaging, IWidgetsData } from '@/interfaces';
import {
    IAbTestDto,
    IConvoDto,
    IConvoListDto,
    IFooterDto,
    IHeroDto,
    IMediaObjectDto,
    IMetadataDto,
    IWidgetDto,
} from '@/interfaces/dtos.interface';
import { Convo, ConvoList, Footer, Metadata, Page, Widget } from '@/models';
import { ConvoFactory, FooterFactory, MetadataFactory, PageFactory } from '@/models/factories';
import { PageError } from '@/models/page';
import AbTestService from '@/services/ab-test.service';
import StoryblokService from '@/services/storyblok.service';
import { Storyblok, StoryData } from '@/types/storyblok';
import { retry, retryAsync } from '@inconvo/retry';

export default class ContentService {
    private readonly storyapi: Storyblok;
    private readonly storyblokService: StoryblokService;
    private readonly abTestService: AbTestService;
    private readonly locale: string;
    private readonly $logger: any;

    constructor(storyapi: Storyblok, abTestService: AbTestService, $logger, locale: string = config.defaultLocale) {
        this.locale = locale;
        this.storyapi = storyapi;
        this.storyblokService = new StoryblokService();
        this.abTestService = abTestService;
        this.$logger = $logger;
    }

    public async getStory({
        story,
        version = StoryBlokVersion.Published,
        locale = config.defaultLocale,
        options = {},
        hasFallback = false,
    }: IGetStoryParams): Promise<StoryData> {
        let response;
        let storyLocale = locale;

        if (locale === config.defaultLocale) {
            storyLocale = '';
        }

        const token =
            version === StoryBlokVersion.Draft ? config.storyblokPreviewAccessToken : config.storyblokPublicAccessToken;

        try {
            response = await this.storyapi.get(`cdn/stories/${story}/${storyLocale}`, {
                token,
                version,
                timestamp: this.storyblokService.getTimestamp(),
                ...options,
            });
        } catch (error: any) {
            if (hasFallback && error?.response?.status === 404) {
                response = await this.storyapi.get(`cdn/stories/${story}`, {
                    token,
                    version,
                    timestamp: this.storyblokService.getTimestamp(),
                    ...options,
                });
            } else {
                throw error;
            }
        }

        return response.data.story as StoryData;
    }

    public makeFooterModel(footerDto: IFooterDto): Footer {
        return FooterFactory.make(footerDto);
    }

    public makeMetadataModel(metadataDto: IMetadataDto): Metadata {
        return MetadataFactory.make(metadataDto);
    }

    @retry({ maxAttempts: 2, backoffStrategy: [500, 500] })
    public async getPageContent(story: StoryData, sections: string[]): Promise<Page | undefined> {
        let widgetsData: IWidgetsData = {};
        try {
            widgetsData = await this.getWidgetsData(story, sections);

            const fulfilledWidgetsData: any = {};
            for (const [key, value] of Object.entries(widgetsData)) {
                if (!(value instanceof Error) && !value.isAxiosError) {
                    fulfilledWidgetsData[key] = value;
                }
            }

            const page: Page = PageFactory.make(this.abTestService, story, fulfilledWidgetsData, sections);

            const errors = Object.entries(widgetsData).filter(
                (item) => item[1] instanceof Error || item[1].isAxiosError,
            );

            page.errors = errors.map((item) => item[1]);

            return page;
        } catch (error: any) {
            throw new PageError(error, { story, widgetsData, sections });
        }
    }

    public async getMore(widget: Widget<any>): Promise<Convo[] | []> {
        if (widget.component === WidgetType.ConvoList) {
            const items: Convo[] = [];
            const convos = await this.getConvos(widget);

            for (const convo of convos.items) {
                items.push(ConvoFactory.make(convo, (widget as ConvoList).cardDescription));
            }

            return items;
        }

        return [];
    }

    public getSelectedAbTestVariant(widgets: IWidgetDto, currentComponentId: string): IWidgetsData | undefined {
        const AbTestComponents: IAbTestDto[] = Object.values(widgets).filter((o) => o.component === WidgetType.AbTest);
        for (const AbTestComponent of AbTestComponents) {
            const variant = AbTestComponent.variants?.find((o) => o._uid === currentComponentId);
            if (variant) {
                return PageFactory.makeWidget(variant);
            }
        }
    }

    public replaceContentWithAbTestVariant(widgets: IWidgetDto, variant: IWidgetsData): void {
        const AbTestComponents: IAbTestDto[] = Object.values(widgets).filter((o) => o.component === WidgetType.AbTest);

        for (const AbTestComponent of AbTestComponents) {
            const currentComponent =
                variant.component === AbTestComponent.variants[0].component &&
                AbTestComponent.variants.find((o) => Object.keys(widgets).includes(o._uid));
            if (currentComponent) {
                this.abTestService.setStoryblokSelectedVariantId(AbTestComponent.experimentId, variant._uid);
                widgets[currentComponent._uid] = variant;
                return;
            }
        }
    }

    private async getWidgetsData(story: StoryData, sections: string[]): Promise<IWidgetsData> {
        const queriesMap: any = {};
        const queries: Promise<any>[] = [];
        const result: any = {};
        const getWidgetDataMap = {
            [WidgetType.ConvoList]: (item) => this.getConvosByConvoListDto(item),
            [WidgetType.MediaObject]: (item) => this.getMediaObject(item),
        };

        for (const section of sections) {
            const excludeConvos: string[] = [];
            const heroComponent: IWidgetDto[] = story.content[section].filter((o) => o.component === WidgetType.Hero);

            if (heroComponent.length) {
                try {
                    const heroObject = await this.getHeroObject(heroComponent[0] as IHeroDto);

                    if (heroObject?.items?.length) {
                        excludeConvos.push(heroObject.items[0].id);
                        result[heroComponent[0]._uid] = heroObject;
                    }
                } catch (error) {
                    this.$logger.error({
                        msg: 'Could not load hero section',
                        error,
                        errorCode: ErrorCodes.FailedToLoadHeroSection,
                    });
                }
            }

            const otherComponents: IWidgetDto[] = story.content[section].filter((o) => o.component !== WidgetType.Hero);
            for (const item of otherComponents) {
                if (getWidgetDataMap[item.component]) {
                    if (item.component === WidgetType.ConvoList && excludeConvos.length) {
                        (item as IConvoListDto).exclude_convos = excludeConvos;
                    }
                    const promise = getWidgetDataMap[item.component](item);
                    queries.push(promise);
                    queriesMap[item._uid] = queries.length - 1;
                }
            }
        }

        const response = await Promise.all(queries.map((promise) => promise.catch((e) => e)));

        for (const [key, value] of Object.entries(queriesMap)) {
            result[key] = response[value as any];
        }
        return result;
    }

    private async getConvosByConvoListDto(widgetData: IConvoListDto, page: number = 1): Promise<IPaging<IConvoDto>> {
        const options: Partial<ConvoList> = {
            page,
            size: +widgetData.number_of_items,
            excludeChannels: widgetData.exclude_channels,
            includeChannels: widgetData.include_channels,
            excludeTags: widgetData.exclude_tags,
            includeTags: widgetData.include_tags,
            excludeTypes: widgetData.exclude_types,
            includeTypes: widgetData.include_types,
            sortingFields: widgetData.sorting_fields,
            excludeConvos: widgetData.exclude_convos,
        };
        return await this.getConvos(options);
    }

    private async getConvos(data: Partial<ConvoList>): Promise<IPaging<IConvoDto>> {
        const options = {} as IGetConvosRequest;

        if (data.page) options.page = data.page;
        if (data.size) options.size = data.size;
        if (data.excludeChannels) options.exclude_channels = data.excludeChannels.join(',');
        if (data.includeChannels) options.include_channels = data.includeChannels.join(',');
        if (data.excludeTags) options.exclude_tags = data.excludeTags.join(',');
        if (data.excludeConvos) options.exclude_convos = data.excludeConvos.join(',');
        if (data.includeTags) options.include_tags = data.includeTags.join(',');
        if (data.excludeTypes) options.exclude_types = data.excludeTypes.join(',');
        if (data.includeTypes) options.include_types = data.includeTypes.join(',');
        if (data.sortingFields) options.sorting = encodeURIComponent(data.sortingFields);
        if (data.skip) options.skip = data.skip;
        options.locale = this.locale;

        return await ApiClient.getConvos(options);
    }

    private getMediaObject(widgetData: IMediaObjectDto): Promise<IMediaObjectDto> {
        const mediaObject = {} as IMediaObjectDto;

        if (widgetData.text) mediaObject.text = widgetData.text;
        if (widgetData.image) mediaObject.image = widgetData.image;
        if (widgetData.image_position) mediaObject.image_position = widgetData.image_position;

        const promise = new Promise<IMediaObjectDto>((resolve) => {
            resolve(mediaObject);
        });
        return promise;
    }

    private async getHeroObject(data: IHeroDto): Promise<IPaging<IConvoDto>> {
        const options = {} as IGetConvosRequest;
        options.page = 1;
        options.size = 1;

        options.include_channels = data.include_channel;
        options.is_daily_chat = true;
        options.locale = this.locale;
        options.sorting = data.sorting
            ? encodeURIComponent(data.sorting)
            : encodeURIComponent('{ "showFromDate": "desc" }');

        const convos: IPaging<IConvoDto> = await retryAsync(() => ApiClient.getConvos(options), {
            maxAttempts: 2,
            backoffStrategy: [200, 200],
        });

        return convos;
    }
}
