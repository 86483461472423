import { ISubscribeChannelRequest } from '@/api/interfaces/channelsApiClient.interface';
import config from '@/config';
import { dedupeList } from '@/services/Utils';
import { LoadingFlag } from '@/enums/loadingFlag.enum';
import { IChannel, IDiscoveryItem, IPaging } from '@/interfaces';
import { ICategory, ISubscribeToChannel } from '@/interfaces/channel.interface';
import { ChannelFactory } from '@/models/factories';
import {
    ADD_CHANNELS,
    SET_ACTIVE_CHANNEL,
    SET_CATEGORIES,
    SET_CATEGORY_FILTER,
    SET_CHANNELS,
    SET_DISCOVERY_ITEM,
    SET_FEATURED_CHANNELS,
    SET_LOAD_MORE_CURSOR,
    SET_SUBSCRIBED_CHANNELS,
    ADD_TO_CHANNELS_CACHE,
} from '@/types/mutations.types';
import { IRootState } from '@/types/rootState';
import ApiClient from '@/api/server/apiClient';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IChannelDto } from '../interfaces/dtos.interface';

export interface IChannelsState {
    activeChannel: IChannel;
    channelsCache: {
        [key: string]: IChannel;
    };
    channelList: IPaging<IChannel>;
    discoveryItems: IPaging<IDiscoveryItem>;
    featuredChannelList: IChannel[];
    loadMoreCursor: string | null;
    subscribedChannelList: IChannel[];
    subscriptions: IChannelSubscription[];
    categories: ICategory[];
    categoryFilter: ICategory;
}

export interface IChannelSubscription {
    channelCode: string;
    memberId: string;
    publishingTopic: string;
}

export const state = () => {
    const state: IChannelsState = {
        channelsCache: {},
        channelList: {
            items: [] as IChannel[],
        } as IPaging<IChannel>,
        featuredChannelList: [] as IChannel[],
        activeChannel: {} as IChannel,
        discoveryItems: {
            items: [] as IDiscoveryItem[],
        } as IPaging<IDiscoveryItem>,
        subscriptions: [] as IChannelSubscription[],
        subscribedChannelList: [] as IChannel[],
        loadMoreCursor: null,
        categoryFilter: {} as ICategory,
        categories: [] as ICategory[],
    };
    return state;
};

const getters: GetterTree<IChannelsState, IRootState> = {
    channelData: (state): IChannel => state.activeChannel,
    hasCategoryFilter: (state): boolean => !!state.categoryFilter.slug,
    selectedCategorySlug: (state): string => state.categoryFilter.slug,
    filteredChannelListItems: (state): IChannel[] => state.channelList.items.filter((item) => !item.isStandalone),
};

const actions: ActionTree<IChannelsState, any> = {
    getCategories: async ({ commit }) => {
        const categories: ICategory[] = await ApiClient.getCategories();
        commit(SET_CATEGORIES, categories);
    },
    getChannels: async ({ commit, state, dispatch }, append: boolean = true) => {
        dispatch('wait/start', LoadingFlag.GetChannels, { root: true });
        if (!state.categories.length && config.featureToggle.categoryFilters === true) {
            await dispatch('getCategories');
        }
        if (!append) {
            commit(SET_CHANNELS, []);
        }

        const channelList: IPaging<IChannelDto> = await ApiClient.getChannels({
            size: 16,
            onlySubscribed: 0,
            next: append ? state.loadMoreCursor : null,
            category: state.categoryFilter.slug || null,
        });
        if (!channelList) {
            return;
        }
        commit(SET_LOAD_MORE_CURSOR, channelList.next);
        const channels: IChannel[] = channelList.items.map((channel: IChannelDto) => {
            const category = state.categories.find((cat: ICategory) => cat.slug === channel.category);
            return ChannelFactory.make(channel, category);
        });
        commit(append ? ADD_CHANNELS : SET_CHANNELS, channels);
        dispatch('wait/end', LoadingFlag.GetChannels, { root: true });
    },
    getFeaturedChannels: async ({ commit }) => {
        const FEATURED_LIMIT = 6;
        let featuredList: IPaging<IChannelDto> = await ApiClient.getChannels({
            isFeatured: 1,
        });
        if (!featuredList) {
            featuredList = {
                page: 0,
                size: 0,
                total: 0,
                next: null,
                items: [],
            };
        }
        const featuredChannels = featuredList.items
            .map((channel) => ChannelFactory.make(channel))
            .slice(0, FEATURED_LIMIT);
        commit(SET_FEATURED_CHANNELS, featuredChannels);
        return featuredChannels;
    },
    getSubscribedChannels: async ({ commit }) => {
        try {
            const subscribedList: IPaging<IChannel> = await ApiClient.getSubscribedChannels();
            if (!subscribedList.items.length) {
                commit(SET_SUBSCRIBED_CHANNELS, []);
                return;
            }
            const subscribedChannels = subscribedList.items.map((channel) => ChannelFactory.make(channel));
            commit(SET_SUBSCRIBED_CHANNELS, subscribedChannels);
        } catch (error) {
            // @ts-ignore
            this.$logger.error({ msg: 'error.getSubscribedChannels', error });
        }
    },
    async getChannel({ commit, state }, slug: string) {
        try {
            let channel = state.channelsCache[slug];

            if (!channel) {
                const channelData = await ApiClient.getChannel(slug);
                channel = ChannelFactory.make(channelData);
            }

            if (channel) {
                commit(ADD_TO_CHANNELS_CACHE, channel);
                commit(SET_ACTIVE_CHANNEL, channel);
            }
        } catch (error) {
            // @ts-ignore
            this.$logger.warn({ msg: 'error.getChannel', error });
        }
    },
    setCategoryFilter: ({ commit, dispatch }, category: ICategory) => {
        commit(SET_CATEGORY_FILTER, category);
        dispatch('getChannels', false);
    },
    subscribeToChannel: async ({ state, rootState }, { isLoggedIn, subscriberId }: ISubscribeToChannel) => {
        const channelCode = state.activeChannel.code;
        if (isLoggedIn) {
            const result = await ApiClient.subscribeToChannel({
                clientId: rootState.auth.clientId,
                channelCode,
                applicationCode: config.applicationCode.toString(),
            });
            return result;
        }
        const options: ISubscribeChannelRequest = {
            clientId: rootState.auth.clientId,
            channelCode,
            applicationCode: config.applicationCode.toString(),
        };
        if (subscriberId) {
            options.subscriberId = subscriberId;
        }
        const result = await ApiClient.subscribeToChannelAsGuest(options);
        return result;
    },
    unsubscribeFromChannel: async ({ dispatch, rootState }, channel: IChannel) => {
        const channelCode = channel.code;
        dispatch('wait/start', LoadingFlag.UnsubscribeChannel, { root: true });
        await ApiClient.unsubscribeFromChannel({
            clientId: rootState.auth.clientId,
            channelCode,
        });
        dispatch('wait/end', LoadingFlag.UnsubscribeChannel, { root: true });
    },
    clearDiscoveryItems: ({ commit }) => {
        commit(SET_DISCOVERY_ITEM, {} as IPaging<IDiscoveryItem>);
    },
};

export const mutations: MutationTree<IChannelsState> = {
    [ADD_CHANNELS](state: IChannelsState, channels: IChannel[]): void {
        state.channelList.items = [...state.channelList.items, ...channels];
    },
    [SET_CHANNELS](state: IChannelsState, channels: IChannel[]): void {
        state.channelList.items = channels;
    },
    [SET_ACTIVE_CHANNEL](state: IChannelsState, channel: IChannel): void {
        state.activeChannel = channel;
    },
    [SET_DISCOVERY_ITEM](state: IChannelsState, discoveryItems: IPaging<IDiscoveryItem>): void {
        state.discoveryItems = discoveryItems;
    },
    [SET_LOAD_MORE_CURSOR](state: IChannelsState, cursor: string): void {
        state.loadMoreCursor = cursor;
    },
    [SET_FEATURED_CHANNELS](state: IChannelsState, featuredchannelList: IChannel[]): void {
        state.featuredChannelList = featuredchannelList;
    },
    [SET_SUBSCRIBED_CHANNELS](state: IChannelsState, subscribedChannelList: IChannel[]): void {
        state.subscribedChannelList = dedupeList(subscribedChannelList, 'code');
    },
    [SET_CATEGORIES](state: IChannelsState, payload: ICategory[]): void {
        state.categories = payload;
    },
    [SET_CATEGORY_FILTER](state: IChannelsState, payload: ICategory): void {
        state.categoryFilter = payload;
    },
    [ADD_TO_CHANNELS_CACHE](state: IChannelsState, channel: IChannel): void {
        state.channelsCache[channel.slug] = channel;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
} as Module<IChannelsState, IRootState>;
