import { Context } from '@nuxt/types/app';
import { browser } from '@inconvo/utils';
import notificationsConfig from '@/config/notifications.config';
import { QueryParam } from '@/enums';
import { SET_AUTH_REFRESHED } from '@/types/mutations.types';

export default ({ app, route }: Context) => {
    try {
        const notificationKey = route.query[QueryParam.Notification];

        if (notificationKey) {
            const notification = notificationsConfig[notificationKey.toString()];
            browser.replaceHistoryState(QueryParam.Notification);

            if (notification) {
                if (notification.disabledForLoggedInUsers) {
                    const unsubscribe = app.store?.subscribe((mutation, state) => {
                        if (mutation.type === `auth/${SET_AUTH_REFRESHED}`) {
                            if (unsubscribe) {
                                unsubscribe();
                            }

                            if (!state.auth.isLoggedIn) {
                                app.$notifications[notification.type](null, notification);
                            }
                        }
                    });
                } else {
                    app.$notifications[notification.type](null, notification);
                }
            }
        }
    } catch (error) {
        app.$logger.error({ msg: 'error.notifications.client.plugin', error, route });
    }
};
