<template>
    <footer v-editable="data" class="footer" :class="{ 'footer--drawer-present': drawerPresent }">
        <div class="page-container footer-container">
            <div class="footer-nav">
                <RadioList
                    v-model="selectedLocale"
                    class="locale-selector"
                    :options="regions"
                    :collapsable="true"
                    @input="onSelectLocale"
                >
                    <template v-slot:option="{ option }">
                        <span class="locale-selector-option">
                            <Icon class="flag-icon" :name="`flags/${option.countryCode}`" :width="20" :height="20" />
                            <span>
                                {{ option.value }}
                            </span>
                        </span>
                    </template>
                </RadioList>
                <ul v-if="linksList && linksList.length" class="footer-links">
                    <template v-for="(link, idx) in linksList">
                        <li v-if="!link.ygdPanelist || link.ygdPanelist === isYgdPanelist" :key="idx" v-editable="link">
                            <a
                                v-if="link.isExternal"
                                :href="link.href"
                                :target="link.target"
                                :rel="link.rel"
                                :class="['link', link.style && `link--${link.style}`]"
                            >
                                {{ link.name }}
                            </a>
                            <NuxtLink v-else :to="link.href" :class="['link', link.style && `link--${link.style}`]">
                                {{ link.name }}
                            </NuxtLink>
                        </li>
                    </template>
                </ul>
            </div>
            <div class="footer-content" data-cy="app-footer-content">
                <AppLogo class="mb-3" />
                <ul class="social-links">
                    <li v-for="(socialButton, index) in data.socialButtons" :key="index" v-editable="socialButton">
                        <a
                            :title="socialButton.title"
                            :href="socialButton.href"
                            :target="socialButton.target"
                            :rel="socialButton.rel"
                        >
                            <Icon :name="socialButton.icon" :width="30" :height="30" />
                        </a>
                    </li>
                </ul>
                <small class="container--styled" data-cy="app-footer-copyright">{{ data.copyright }}</small>
                <p v-if="useRecaptcha" class="container--styled" v-html="$t('registration.recaptcha')" />
            </div>
        </div>
    </footer>
</template>

<script>
import Icon from '@/components/Icon';
import { LinkStyle } from '@/enums';
import config from '@/config';
import RadioList from '@/components/forms/RadioList';
import AppLogo from '@/components/AppLogo';
import { RegionFactory } from '@/models/factories';
import locales from '../../config/locales';

export default {
    name: 'AppFooter',
    components: {
        Icon,
        RadioList,
        AppLogo,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        isAuthRefreshed: {
            type: Boolean,
            default: false,
        },
        isYgdPanelist: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            LinkStyle,
            useRecaptcha: config.featureToggle.useRecaptcha,
            regions: [],
            selectedLocale: null,
            drawerPresent: false,
        };
    },
    computed: {
        linksList() {
            return this.data.items
                ?.filter((item) => item.links)
                .map((item) => item.links)
                .flat();
        },
    },
    created() {
        const regions = locales.map(RegionFactory.makeFromLocale);
        this.regions = regions;
        this.selectedLocale = regions.find((r) => r.id === this.$i18n.locale);
    },
    mounted() {
        const unwatch = this.$watch('isAuthRefreshed', (newValue) => {
            if (newValue) {
                if (unwatch) {
                    unwatch();
                }
                this.drawerPresent = !!document.getElementById('drawer');
            }
        });
    },
    methods: {
        onSelectLocale(val) {
            this.$emit('setRegion', val);
        },
    },
};
</script>

<style lang="scss" scoped>
$footer-background-color: map-get($palette, shades, 1200);
$footer-color: map-get($palette, shades, 600);

.footer {
    padding: 4rem 0;
    background-color: $footer-background-color;
    color: $footer-color;
    font-family: $font-heading;
    line-height: 1.3;
    font-size: 13px;
    @include media-query(medium) {
        font-size: 14px;
    }
    &--drawer-present {
        padding-bottom: 8rem;
    }
}

.footer-container {
    display: flex;
    flex-direction: column;

    @include media-query(only-small) {
        padding-left: 1.6rem;
        padding-right: 1.6rem;
    }

    @include media-query(medium) {
        flex-direction: row;
        justify-content: space-between;
    }
}

.footer-nav {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;

    text-transform: uppercase;
    order: 1;

    @include media-query(medium) {
        flex-direction: row;
        order: 0;
        padding: 2rem;
    }
}
.footer-links {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 0;
    justify-content: flex-start;
    > li {
        padding: 1.5rem 0;
        min-width: 50%;
    }
    @include media-query(medium) {
        padding: 0 2rem;
        > li {
            min-width: 33%;
            padding: 0 2rem 2rem;
        }
    }
}

.footer-content {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    order: 0;

    @include media-query(medium) {
        width: 375px;
        padding: 9px 0 9px 5%;
        border-color: map-get($newPalette, shades, 600);
        border-style: solid;
        border-left-width: 1px;
        order: 1;
    }
}

.social-links {
    display: flex;
    order: 1;
    padding-top: 20px;
    margin-bottom: 0px;

    @include media-query(medium) {
        padding-top: 0px;
        margin-bottom: 16px;
        order: 0;
    }
    > li:not(:last-child) {
        margin-right: 10px;
    }

    a {
        display: block;
        transition: opacity 0.3s;

        &:hover {
            opacity: 0.8;
        }
    }
}

.locale-selector {
    max-height: 165px;
    flex-shrink: 0;
    width: min(200px, 100%);
}

.locale-selector-option {
    display: flex;
    align-items: center;
}

.flag-icon {
    flex: 0 0 auto;
    margin-right: 10px;
    border-radius: 6px;
}
</style>
