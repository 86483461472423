<template>
    <button
        :type="type"
        :class="classArray"
        :title="label || title"
        :disabled="disabled || isLoading"
        @click="$emit('click')"
    >
        <span v-if="isLoading" class="button-spinner" />
        <Icon v-if="icon" :title="displayTitle" :name="icon" class="button-icon" :height="null" :width="null" />
        <slot> {{ label }}</slot>
    </button>
</template>

<script>
import Icon from '@/components/Icon.vue';
import { ButtonSize, ButtonColor, ButtonVariant } from '@/enums/buttons';

export default {
    name: 'AppButton',
    components: {
        Icon,
    },

    props: {
        type: {
            type: String,
            default: 'button',
        },
        title: {
            type: String,
            default: undefined,
        },
        label: {
            type: String,
            default: undefined,
        },
        size: {
            type: String,
            default: ButtonSize.medium,
            validator: (value) => Object.values(ButtonSize).includes(value),
        },
        variant: {
            type: String,
            default: ButtonVariant.solid,
            validator: (value) => Object.values(ButtonVariant).includes(value),
        },
        color: {
            type: String,
            default: ButtonColor.primary,
            validator: (value) => Object.values(ButtonColor).includes(value),
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: undefined,
        },
    },
    computed: {
        classArray() {
            return [
                'button',
                `button--variant-${this.variant}`,
                `button--color-${this.color}`,
                `button--size-${this.size}`,
                {
                    'button--loading': this.isLoading,
                    'button--icon-only': this.iconOnlyButton,
                },
            ];
        },
        displayTitle() {
            return this.label || this.title || 'button';
        },
        iconOnlyButton() {
            return !this.label && !this.$slots.default;
        },
    },
};
</script>

<style lang="scss" scoped>
.button {
    font-family: $sans-serif;
    opacity: 1;
    position: relative;
    display: inline-flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    line-height: 1;
    background: transparent;
    border: 2px solid;
    border-radius: 1.8em;
    text-align: center;
    cursor: pointer;
    &:disabled {
        pointer-events: none;
    }
    outline: 0px solid transparent;
    transition: 0.3s ease-in;
}

// button sizes
.button--size {
    &-small {
        font-size: $small-font-size;
        font-weight: $font-weight-medium;
        padding: 0.6rem 0.9rem;
        @include media-query(medium) {
            padding: 0.95rem 1.8rem;
        }
    }
    &-medium {
        font-size: $normal-font-size;
        font-weight: $font-weight-medium;
        padding: 1rem 2.8rem;
    }

    &-large {
        font-size: $xlarge-font-size;
        font-weight: $font-weight-semibold;
        padding: 1.1rem 3.4rem;
        @include media-query(medium) {
            padding: 1.3rem 4.8rem;
        }
    }
}
.button--icon-only {
    padding: 0.5em;
    .button-icon {
        margin: 0;
    }
    .button-spinner {
        position: absolute;
        top: 0.25em;
        right: 0.25em;
        bottom: 0.25em;
        left: 0.25em;
    }
}
// button colors
.button--color {
    &-primary {
        --button-contrast-color: var(--white);
        --button-color: var(--blue-400);
        --button-focus-color: #92dcff;
        &:disabled:not(.button--loading) {
            --button-color: var(--shades-700);
        }
        &:active,
        &:focus {
            outline: 4px solid var(--button-focus-color);
        }
        &:focus,
        &:hover {
            --button-color: var(--blue-500);
        }
        &:active {
            background-color: #002a77;
        }
    }
    &-secondary {
        --button-contrast-color: var(--white);
        --button-color: var(--shades-900);
        --button-focus-color: #92dcff;

        &:disabled:not(.button--loading) {
            --button-contrast-color: var(--shades-400);
            --button-color: var(--shades-700);
        }
        &:active,
        &:focus {
            outline: 4px solid var(--button-focus-color);
        }
    }
    &-white {
        --button-color: var(--white);
        --button-focus-color: #92dcff;
        &:disabled:not(.button--loading) {
            --button-contrast-color: var(--shades-400);
        }
        &:active,
        &:focus {
            outline: 4px solid var(--button-focus-color);
        }
    }
}

// button variant
.button--variant {
    &-solid {
        background: var(--button-color);
        border-color: var(--button-color);
        color: var(--button-contrast-color, var(--black));

        --spinner-color: var(--button-contrast-color);
    }
    &-outline {
        background: transparent;
        border-color: var(--button-color);
        color: var(--button-color);
        &:focus {
            outline: 4px solid var(--button-color);
        }
        &:active,
        &:hover {
            background: var(--button-color);
            color: var(--button-contrast-color);
        }

        --spinner-color: var(--button-color);
    }
}

.button-icon {
    display: inline;
    vertical-align: middle;
    height: 1.2em;
    width: 1.2em;
    margin-right: 0.5em;
}
// loading
.button--loading {
    color: transparent;
}
.button-spinner {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    margin: auto;
    animation: spinnerEnter 0.3s linear 1;
}
.button-spinner:after {
    content: ' ';
    display: block;
    width: 1.25em;
    height: 1.25em;
    margin: 0;
    border-radius: 75%;
    border: 0.175em solid;
    border-color: var(--spinner-color) transparent var(--spinner-color) var(--spinner-color);
    animation: spinner 1.2s linear infinite;
}
@keyframes spinnerEnter {
    0% {
        clip-path: circle(25%);
        opacity: 0.1;
    }
    100% {
        clip-path: circle(75%);
        opacity: 1;
    }
}
@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
