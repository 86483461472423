import { IEditable } from '@/interfaces/storyblok.interface';

export default class LocaleSelector implements IEditable {
    _editable: string;
    _uid: string;

    constructor() {
        this._editable = '';
        this._uid = '';
    }
}
