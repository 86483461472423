<template>
    <div class="radio-button" :class="`radio-button--${variant}`">
        <input
            :id="id"
            type="radio"
            class="visuallyhidden radio-button-input"
            :name="name"
            :value="value"
            :checked="checked"
            @click="$emit('input', value)"
        />
        <label :for="id" class="radio-button-label" :class="{ collapsed }">
            <span class="radio-button-label-text">
                <slot name="label">{{ label }}</slot>
            </span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'RadioButton',
    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
        checked: {
            type: Boolean,
            required: true,
        },
        label: {
            type: String,
            default: undefined,
        },
        collapsed: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: 'default',
            validator: (value) => ['default', 'daily-chat'].includes(value),
        },
    },
};
</script>

<style lang="scss" scoped>
.radio-button-input {
    &:checked + .radio-button-label {
        &::after {
            background-color: $lighter-brand-color;
            box-shadow: 0 0 0 1px $lighter-brand-color;
        }
    }
}

.radio-button-label {
    display: block;
    position: relative;
    padding: 0 20px;
    cursor: pointer;

    &::after {
        @include center(y);
        content: '';
        display: block;
        position: absolute;
        right: 20px;
        width: 14px;
        height: 14px;
        border-radius: 10px;
        border: 2px solid $white;
        background-color: $white;
        box-shadow: 0 0 0 1px $form-input-border-color;
    }

    .radio-button-label-text {
        display: block;
        margin-right: 34px;
        padding: 12px 0;
    }
    &.collapsed {
        padding: 0 10px;

        &::after {
            display: none;
        }

        & .radio-button-label-text {
            margin-right: 15px;
            padding: 6px 0px;
        }
    }
}

.radio-button {
    &--daily-chat {
        .radio-button-input {
            &:checked + .radio-button-label {
                &::after {
                    background-color: $color-accent-4;
                    box-shadow: 0 0 0 0.1rem $color-accent-4;
                }
            }
        }
        .radio-button-label {
            padding: 0;

            @include media-query(medium) {
                padding: 0 2rem;
            }

            &::after {
                width: 2rem;
                height: 2rem;
                right: 0.1rem;
                border-radius: 2rem;
                border: 0.5rem solid $white;

                @include media-query(medium) {
                    width: 2.4rem;
                    height: 2.4rem;
                    border: 0.6rem solid $white;
                    border-radius: 2.4rem;
                    right: 3rem;
                }
            }
        }

        .radio-button-label-text {
            margin-right: 0;
        }
    }
}
</style>
