import { IValidateRecontactTokenResponse } from '@/api/interfaces/recontactTokenApiClient.interface';
import { eventsClient, yougovClient } from '@/api/server';
import ApiClient from '@/api/server/apiClient';
import { auth as AuthService } from '@/auth';
import config from '@/config';
import {
    CHAT_WIDGET_CLIENT_STORAGE_KEY,
    CLIENT_STORAGE_KEY,
    PERSON_ID_STORAGE_KEY,
    USER_ID_STORAGE_KEY,
} from '@/constants/storageKeys';
import {
    EmailValidationStatus,
    ErrorType,
    LoadingFlag,
    ModalType,
    QueryParam,
    UsernameType,
    UserStatus,
} from '@/enums';
import { IClient, ILoginParams, IOnUserResendCodeParams, IRegisterData, IRegisterUserResponse } from '@/interfaces';
import {
    IAfterAuthChallenge,
    IAuthChallengeResponse,
    IAuthSessionMetadata,
    ISocialLoginData,
} from '@/interfaces/auth.interface';
import { IUserProfile } from '@/interfaces/user.interface';
import { Region } from '@/models';
import {
    analyticsPayloadFactory,
    authChallengeResponseFactory,
    authMetadataFactory,
    RegionFactory,
} from '@/models/factories';
import ProfileService from '@/services/profile.service';
import { onChannelSubscribe } from '@/services/subscription-topic.service';
import {
    ADD_ERROR,
    CLEAR_CLIENT,
    CLEAR_ERRORS,
    CLEAR_PERSON_ID,
    CLEAR_USER,
    SET_AUTH_REFRESHED,
    SET_CLIENT,
    SET_CREDENTIALS,
    SET_DO_NOT_TRACK_FLAG,
    SET_EMAIL,
    SET_EMAIL_VALIDATION_STATUS,
    SET_FINGERPRINT_HASH,
    SET_KNOWN_USER_FLAG,
    SET_LOGGED_IN_FLAG,
    SET_NO_SIGNUP_FLAG,
    SET_PERSON_ID,
    SET_REFERRAL,
    SET_REGIONS,
    SET_SESSION_ID,
    SET_SUBSCRIBER_ID,
    SET_USER,
    SET_USER_ID,
    SET_USER_PROFILE,
    SET_USER_REGION,
} from '@/types/mutations.types';
import { IRootState } from '@/types/rootState';
import { ICredentials } from '@aws-amplify/core';
import { browser } from '@inconvo/utils';
import { CognitoAccessToken, CognitoIdToken, CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import Fingerprint2 from 'fingerprintjs2';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';

const LOGIN_METADATA_KEY = 'login.metadata';

export interface IError {
    type: ErrorType;
    message: string;
}

export interface IAuthState {
    authRefreshed: boolean;
    clientId: string;
    credentials: ICredentials;
    emailValidationStatus: string;
    fingerprintHash: string;
    isLoggedIn: boolean;
    isKnownUser: boolean;
    noSignup: boolean;
    subscriptionTopic: string;
    user: CognitoUser | null;
    userProfile: IUserProfile;
    recontactEmail: string;
    userId: string;
    doNotTrackUser: boolean;
    referral: string;
    errors: IError[];
    subscriberId: string;
    sessionId: string;
    regions: Region[];
    userRegion: Region | undefined;
    personId: string;
}

const state = () => {
    const state: IAuthState = {
        authRefreshed: false,
        clientId: '',
        credentials: {} as ICredentials,
        emailValidationStatus: '',
        fingerprintHash: '',
        isLoggedIn: false,
        isKnownUser: false,
        noSignup: false,
        recontactEmail: '',
        userId: '',
        subscriptionTopic: '',
        user: null,
        userProfile: {} as IUserProfile,
        doNotTrackUser: false,
        referral: '',
        errors: [] as IError[],
        subscriberId: '',
        sessionId: '',
        regions: [] as Region[],
        userRegion: undefined,
        personId: '',
    };
    return state;
};

const getters: GetterTree<IAuthState, IRootState> = {
    identityId: (state): string => {
        return state.credentials.identityId;
    },
    emailValidationStatus: (state): string => {
        if (state.emailValidationStatus) {
            return state.emailValidationStatus;
        }
        return state.isLoggedIn ? EmailValidationStatus.Valid : '';
    },
    email: (state): string => {
        const userSession = state.user?.getSignInUserSession();
        if (!userSession) {
            return '';
        }
        return userSession.getIdToken().payload?.email;
    },
    subId: (state): string => {
        const userSession = state.user?.getSignInUserSession();
        if (!userSession) {
            return '';
        }
        return userSession.getIdToken().payload?.sub;
    },
    subscriberId: (state): string => {
        return state.subscriberId;
    },
};

const actions: ActionTree<IAuthState, any> = {
    async getUserAccount({ dispatch, commit, rootGetters }) {
        try {
            if (rootGetters['wait/is'](LoadingFlag.GetUserProfile)) {
                return;
            }

            dispatch('wait/start', LoadingFlag.GetUserProfile, { root: true });
            const userProfile: IUserProfile = await ProfileService.getUserProfile();
            commit(SET_USER_PROFILE, userProfile);
        } catch (err) {
            this.app.$logger.error({ msg: 'error.getUserAccount', err });
        } finally {
            dispatch('wait/end', LoadingFlag.GetUserProfile, { root: true });
        }
    },

    async updateUserAccount({ state, dispatch }) {
        if (!state.user) {
            return;
        }
        try {
            dispatch('wait/start', LoadingFlag.UpdateUserProfile, { root: true });
            await ProfileService.updateUserProfile(state.userProfile);
            this.app.$notifications.success(this.$i18n.t('notification.profileUpdated'));
        } catch (err) {
            this.app.$logger.error({ msg: 'error.updateUserAccount', err });
        } finally {
            dispatch('wait/end', LoadingFlag.UpdateUserProfile, { root: true });
        }
    },
    async deleteUserAccount({ state, dispatch }) {
        if (!state.user) {
            return;
        }

        try {
            dispatch('wait/start', LoadingFlag.DeleteUserProfile, { root: true });
            await ProfileService.deleteUserProfile();
            AuthService.clearSession();
            dispatch('main/clearStoredLocale', null, { root: true });
            dispatch('main/clearStoredCountryCode', null, { root: true });
            window.location.href = window.location.origin;
        } catch (err) {
            this.app.$logger.error({ msg: 'error.deleteUserAccount', err });
        } finally {
            dispatch('wait/end', LoadingFlag.DeleteUserProfile, { root: true });
            this.app.$notifications.success(this.$i18n.t('notification.accountDeleted'));
        }
    },
    onProfilePropertyChange({ state, commit }, data) {
        const userProfile: IUserProfile = {
            name: typeof data.name === 'string' ? data.name : state.userProfile.name,
            birthdate: data.birthdate || state.userProfile.birthdate,
            gender: data.gender || state.userProfile.gender,
            phoneNumber: typeof data.phoneNumber === 'string' ? data.phoneNumber : state.userProfile.phoneNumber,
            email: typeof data.email === 'string' ? data.email : state.userProfile.email,
            consentSmsUpdate: data.consentSmsUpdate || state.userProfile.consentSmsUpdate,
            consentEmailUpdate: data.consentEmailUpdate || state.userProfile.consentEmailUpdate,
            usernameType: state.userProfile.usernameType,
            subscriptions: data.subscriptions || state.userProfile.subscriptions,
            personId: data.personId || state.userProfile.personId,
        };

        commit(SET_USER_PROFILE, userProfile);
    },
    async getAuthData({ state, getters }) {
        let idToken: CognitoIdToken | undefined;
        let idTokenJwt: string = '';
        let accessToken: CognitoAccessToken | undefined;
        let accessTokenJwt: string = '';
        try {
            if (state.user) {
                idToken = await AuthService.getIdToken();
                idTokenJwt = idToken.getJwtToken();
                accessToken = await AuthService.getAccessToken();
                accessTokenJwt = accessToken.getJwtToken();
            }
        } catch (error) {
            if (error !== 'No current user') {
                // @ts-ignore
                this.$sentry.captureException('getAuthData: could not get tokens', { extra: { error } });
            }
        }

        let authData: any = {
            idToken: idTokenJwt,
            accessToken: accessTokenJwt,
            idTokenExp: idToken?.getExpiration(),
            identityId: getters.identityId?.toString(),
            fingerprintHash: state.fingerprintHash,
            isKnownUser: state.isKnownUser,
            noSignup: state.noSignup,
            userId: state.userId,
            applicationCode: config.applicationCode,
            subscriberId: getters.subscriberId?.toString(),
            referral: state.referral,
        };

        if (state.clientId && state.subscriptionTopic) {
            authData.clientId = state.clientId;
            authData.subscriptionTopic = state.subscriptionTopic;
        }

        let user = state.user as any;

        if (user?.username) {
            user = { ...user, sub: AuthService.getUserSubId((state.user as any).username) };
        }

        authData = { ...authData, user: JSON.parse(JSON.stringify(user)) };
        return authData;
    },
    async getAuthDataV2({ state, getters }) {
        let cognitoUserSession: CognitoUserSession;
        let idToken: CognitoIdToken | undefined;
        let idTokenJwt: string = '';
        let accessToken: CognitoAccessToken | undefined;
        let accessTokenJwt: string = '';
        try {
            if (state.user) {
                cognitoUserSession = await AuthService.getCurrentSession();
                const idTokenExpiry = cognitoUserSession.getIdToken().getExpiration();
                const timeToTokenExpiry = idTokenExpiry * 1000 - Date.now();

                if (timeToTokenExpiry <= config.cognitoTokenRefreshExpiryOffset) {
                    cognitoUserSession = await AuthService.refreshSession();
                }

                idToken = cognitoUserSession.getIdToken();
                idTokenJwt = idToken.getJwtToken();
                accessToken = cognitoUserSession.getAccessToken();
                accessTokenJwt = accessToken.getJwtToken();
            }
        } catch (error) {
            if (error !== 'No current user') {
                // @ts-ignore
                this.$sentry.captureException('getAuthData: could not get tokens', { extra: { error } });
            }
        }

        let authData: any = {
            idToken: idTokenJwt,
            accessToken: accessTokenJwt,
            idTokenExp: idToken?.getExpiration(),
            identityId: getters.identityId?.toString(),
            fingerprintHash: state.fingerprintHash,
            isKnownUser: state.isKnownUser,
            noSignup: state.noSignup,
            userId: state.userId,
            applicationCode: config.applicationCode,
            subscriberId: getters.subscriberId?.toString(),
            referral: state.referral,
        };

        if (state.clientId && state.subscriptionTopic) {
            authData.clientId = state.clientId;
            authData.subscriptionTopic = state.subscriptionTopic;
        }

        let user = state.user as any;

        if (user?.username) {
            user = { ...user, sub: AuthService.getUserSubId((state.user as any).username) };
        }

        authData = { ...authData, user: JSON.parse(JSON.stringify(user)) };
        return authData;
    },
    init({ dispatch, state }) {
        const subscriberId = AuthService.getSubscriberId();
        dispatch('setSubscribedUser', subscriberId || '');
        dispatch('getFingerprint');

        const personId = state.personId || browser.storage.local.getItem(PERSON_ID_STORAGE_KEY) || '';

        if (personId) {
            dispatch('setPersonId', personId);
        }

        window.getPersonId = () => state.personId;
    },
    async onLoginLink({ dispatch }, params: ILoginParams) {
        const redirectOnError = () => {
            // @ts-ignore
            this.$notifications.error(this.$i18n.t('notification.linkNoLongerValid'));
            setTimeout(() => (window.location.href = `${window.location.origin}?action=signup`), 3000);
        };
        let isLoggedIn: boolean = false;
        try {
            isLoggedIn = await AuthService.getCurrentUser();
        } catch (error) {}
        if (isLoggedIn) {
            window.location.href = '/';
            return;
        }
        try {
            const response: IAuthChallengeResponse = await AuthService.answerAuthChallenge(
                params.sessionId,
                params.clientId,
                params.signInCode,
                params.referral,
            );

            AuthService.setSession(response);

            const afterAuthData = authChallengeResponseFactory.make(response);

            dispatch('onAfterAuthChallenge', {
                ...afterAuthData,
                isDailyChat: params.isDailyChat,
            });
        } catch (err) {
            return redirectOnError();
        }
    },
    async onUserAuthChallenge({ commit, dispatch, state }, data) {
        commit(CLEAR_ERRORS);
        if (!state.user && !data.sessionId) {
            // @ts-ignore
            this.$logger.warn({ msg: 'No active login attempt' });
            return;
        }

        try {
            const response: IAuthChallengeResponse = await AuthService.answerAuthChallenge(
                data.sessionId || state.sessionId,
                config.userPoolWebClientId,
                data.code,
                data.referral,
            );

            AuthService.setSession(response);

            const afterAuthData = authChallengeResponseFactory.make(response);

            dispatch('onAfterAuthChallenge', {
                ...afterAuthData,
                isDailyChat: data.isDailyChat,
                redirectPath: afterAuthData.redirectPath || window.location.pathname,
            });
        } catch (err) {
            // @ts-ignore
            this.$notifications.error(this.$i18n.t('notification.wrongVerificationCode'));
            const error = {
                type:
                    (err as any).response.data.error.message === 'Session not found'
                        ? ErrorType.SessionNotFound
                        : ErrorType.IncorrectVerification,
            };
            commit(ADD_ERROR, error);
            // @ts-ignore
            this.$logger.warn({ msg: 'error.onUserAuthChallenge', err });
            dispatch(
                'main/updateModal',
                {
                    payload: {
                        authChallengeIsActive: false,
                    },
                },
                { root: true },
            );
        }
    },

    async onAfterAuthChallenge({ dispatch, commit, getters }, data: IAfterAuthChallenge) {
        try {
            if (data.personId) {
                browser.storage.local.setItem(PERSON_ID_STORAGE_KEY, data.personId);
                dispatch('setPersonId', data.personId);
            }

            const payload = analyticsPayloadFactory.make(data.metadata);
            if (data.isNewUser) {
                // User Signup
                eventsClient.logUserSignup();
                this.app.$analytics.trackEvent([{ client: 'gtm', eventName: 'UserSignup', payload }]);
                onChannelSubscribe({
                    countryCode: data.countryCode,
                    sourceChannelCode: data.metadata.sourceChannelCode,
                    user: {
                        email: data.user.username,
                        userId: data.user.user_id,
                    },
                });
            } else {
                // User Login
                eventsClient.logUserLogin();
                this.app.$analytics.trackEvent([{ client: 'gtm', eventName: 'UserLogin', payload }]);
            }

            await AuthService.getCurrentSession();

            await dispatch('refreshAuth', false);

            if (data.isSocialLogin) {
                AuthService.setIdentityId(getters.identityId);
            }
            await dispatch('updateClient');
            AuthService.removePageLoadCounter();

            if (data.userStatus >= UserStatus.corConfirmed && data.locale) {
                if (data.locale.includes('-')) {
                    dispatch('main/storeLocale', data.locale, { root: true });
                } else if (data.countryCode) {
                    dispatch('main/storeLocale', `${data.locale}-${data.countryCode}`, { root: true });
                }
            }

            if (
                config.featureToggle.countryOfResidenceModal ||
                this.app.$queryParams(QueryParam.CountryOfResidenceEnabled)
            ) {
                dispatch('captureCountryOfResidence', {
                    redirect: data.redirectPath,
                    userStatus: data.userStatus,
                    countryCode: data.countryCode,
                    locale: data.locale,
                    isDailyChat: data.isDailyChat,
                    sourceChannelCode: data.metadata?.sourceChannelCode,
                });
            } else {
                window.location.href = data.redirectPath;
            }
        } catch (err) {
            let error: IError;
            if (data.isSocialLogin) {
                error = {
                    type: ErrorType.IncorrectVerification,
                    message: 'Something wrong with Social login, please try again.',
                };
            } else {
                error = {
                    type: ErrorType.IncorrectVerification,
                    message: 'Entered incorrect verification code, please try again.',
                };
            }
            commit(ADD_ERROR, error);
            // @ts-ignore
            this.$logger.warn({ msg: 'error.onAfterAuthChallenge', err });
        }
    },
    async captureCountryOfResidence(
        { dispatch, commit },
        data: {
            redirect: string;
            userStatus: UserStatus;
            countryCode: string;
            locale: string;
            isDailyChat: boolean;
            sourceChannelCode: string;
        },
    ) {
        try {
            const countryCode = data.countryCode ? data.countryCode.toLocaleLowerCase() : '';
            const locale = data.locale ? data.locale.toLocaleLowerCase() : '';
            const regionsData = await yougovClient.getRegions();
            const regions = regionsData.map(RegionFactory.make);
            commit(SET_REGIONS, regions);

            const findByCountryAndLanguage = (region: Region) =>
                region.countryCode === countryCode && region.languageCode === locale;
            const findByCountry = (region: Region) => region.countryCode === countryCode;
            const findByDefaultCountry = (region: Region) =>
                region.countryCode === config.defaultCountryCode.toLocaleLowerCase();

            const userRegion =
                regions.find(findByCountryAndLanguage) ||
                regions.find(findByCountry) ||
                regions.find(findByDefaultCountry);

            commit(SET_USER_REGION, userRegion);

            const captureCorUserStatuses = [
                UserStatus.anonymous,
                UserStatus.subscribed,
                UserStatus.social,
                UserStatus.registered,
                UserStatus.emailConfirmed,
            ];

            if (captureCorUserStatuses.includes(data.userStatus)) {
                if (data.isDailyChat) {
                    const route = this.$router.resolve({
                        path: '/daily-chat/country',
                        query: {
                            [QueryParam.RedirectPath]: data.redirect,
                            [QueryParam.sourceChannelCode]: data.sourceChannelCode,
                        },
                    });
                    this.$router.push({ path: route.href });
                } else {
                    dispatch(
                        'main/toggleModal',
                        {
                            type: ModalType.CountryOfResidenceModal,
                            payload: {
                                redirectPath: data.redirect,
                                sourceChannelCode: data.sourceChannelCode,
                                regions,
                                userRegion,
                                backdropStyle: 'solid',
                            },
                        },
                        { root: true },
                    );
                }
            } else {
                window.location.href = data.redirect;
            }
        } catch (error) {
            this.app.$sentry.captureException('error.captureCountryOfResidence', { extra: { error } });
            window.location.href = data.redirect;
        }
    },
    async onConfirmCountryOfResidence(
        { dispatch },
        data: {
            redirectPath: string;
            iso: string;
            countryCode: string;
            languageCode: string;
            sourceChannelCode?: string;
        },
    ) {
        try {
            await ProfileService.updateUserProfile({
                countryCode: data.countryCode,
                locale: `${data.languageCode}-${data.countryCode}`,
                userStatus: UserStatus.corConfirmed,
                sourceChannel: data.sourceChannelCode,
            } as IUserProfile);
            await dispatch('main/storeLocale', data.iso, { root: true });
        } catch (error) {
            this.app.$logger.error({ msg: 'error.onConfirmCountryOfResidence.updateUserProfile', error });
        } finally {
            window.location.href = data.redirectPath;
        }
    },
    refreshAuth: async ({ dispatch, commit }, setUser: boolean = true) => {
        await dispatch('getCredentials');
        if (setUser) {
            await dispatch('getCurrentUser');
        }
        commit(SET_AUTH_REFRESHED);
    },
    getCredentials: async ({ commit }) => {
        const credentials: ICredentials = await AuthService.getCredentials();
        commit(SET_CREDENTIALS, credentials);
    },
    onUserLogin(
        { commit, dispatch },
        loginData: {
            registerResult: IRegisterUserResponse;
            type?: string;
            redirectPath?: string;
            referral?: string;
            recaptchaToken?: string;
            source?: string;
            isDailyChat: boolean;
            metadata: IAuthSessionMetadata;
        },
    ) {
        try {
            const cognitoUserData = {
                userName: loginData.registerResult.username,
                userPoolId: loginData.registerResult.userPoolId,
                clientId: loginData.registerResult.clientId,
                session: loginData.registerResult.session,
            };
            const user = AuthService.makeCognitoUser(cognitoUserData);
            commit(SET_USER, user);
            commit(SET_SESSION_ID, loginData.registerResult.sessionId);
            if (loginData.isDailyChat) {
                const route = this.$router.resolve({
                    name: 'daily-chat-verify',
                    query: {
                        [QueryParam.UserEmail]: loginData.registerResult.username,
                        [QueryParam.SessionId]: loginData.registerResult.sessionId,
                        [QueryParam.Source]: loginData.source,
                        [QueryParam.RedirectPath]: loginData.redirectPath,
                        [QueryParam.Referral]: loginData.referral,
                    },
                });
                window.location.href = route.href;
            } else {
                dispatch(
                    'main/updateModal',
                    {
                        payload: {
                            authChallengeIsActive: true,
                        },
                    },
                    { root: true },
                );
            }
        } catch (err) {
            // @ts-ignore
            this.$logger.error({ msg: 'error.onUserLogin', err });
            dispatch('main/toggleModal', { type: null }, { root: true });
            // @ts-ignore
            this.$notifications.error(this.$i18n.t('notification.loginFailed'));
        }
    },
    async onUserResendCode(
        { commit, dispatch, rootState },
        { username, source, redirectPath, referral, recaptchaToken }: IOnUserResendCodeParams,
    ) {
        try {
            const registerData = {
                username,
                source,
                locale: rootState.main.locale,
                redirectPath,
                referral,
                recaptchaToken,
            } as IRegisterData;
            const result: IRegisterUserResponse = await AuthService.register(registerData);
            const cognitoUserData = {
                userName: result.username,
                userPoolId: result.userPoolId,
                clientId: result.clientId,
                session: result.session,
            };
            const loggedInUser = AuthService.makeCognitoUser(cognitoUserData);
            commit(SET_USER, loggedInUser);
            commit(SET_SESSION_ID, result.sessionId);
        } catch (err) {
            // @ts-ignore
            this.$logger.error({ msg: 'error.onUserResendCode', err });
            dispatch('main/toggleModal', { type: null }, { root: true });
            // @ts-ignore
            this.$notifications.error(this.$i18n.t('notification.loginFailed'));
        }
    },
    async onUserSignup({ dispatch, commit, rootState }, data) {
        commit(CLEAR_ERRORS);
        const registerData = {
            email: data.email,
            phoneNumber: data.phoneNumber,
            locale: rootState.main.locale,
            redirectPath: data.redirectPath,
            referral: data.referral,
            recaptchaToken: data.recaptchaToken,
            metadata: data.metadata,
        } as IRegisterData;

        try {
            const result: IRegisterUserResponse = await AuthService.register(registerData);
            if (result.isNewUser) {
                dispatch('updateClientAfterRegistration', result.subId);
            }
            const loginData = {
                registerResult: result,
                type: data.email ? UsernameType.Email : UsernameType.Phone,
                redirectPath: data.redirectPath,
                source: data.source,
                metadata: data.metadata,
            };
            dispatch('onUserLogin', loginData);
        } catch (err) {
            const error: IError = {
                type: ErrorType.IncorrectVerification,
                // @ts-ignore
                message: this.$i18n.t('registration.invalidEmail'),
            };
            commit(ADD_ERROR, error);
            // @ts-ignore
            this.$logger.warn({ msg: 'error.onUserSignup', err });
        }
    },
    async authenticateYougovUser({ dispatch }, code: string) {
        try {
            const response: IAuthChallengeResponse = await AuthService.authenticateYougovUser(code);

            AuthService.setSession(response);
            let locale: string;
            if (response.locale.includes('-')) {
                locale = response.locale;
            } else if (response.country_code) {
                locale = `${response.locale}-${response.country_code}`;
            } else {
                locale = config.defaultLocale;
            }

            const afterAuthData = authChallengeResponseFactory.make(response);

            dispatch('onAfterAuthChallenge', {
                ...afterAuthData,
                redirectPath: `/?embedded=1&locale=${locale}`,
                personId: response.yg_person_id,
            });
        } catch (error) {
            window.location.href = '/?embedded=1';
            this.app.$sentry.captureException('error.authenticateYougovUser', { extra: { error, code } });
        }
    },
    async onUserSubscribe(
        { dispatch, commit, state, rootState },
        { email, metadata }: { email: string; metadata: IAuthSessionMetadata },
    ): Promise<string> {
        try {
            commit(CLEAR_ERRORS);
            commit(SET_EMAIL_VALIDATION_STATUS, '');
            const request = {
                email,
                client_id: state.clientId,
                referral: state.referral,
                application_code: config.applicationCode,
                locale: rootState.main.locale,
                country_code: rootState.main.countryIso2Code,
                metadata: authMetadataFactory.makePayload(metadata),
            };
            const response = await ApiClient.storeSubscriber(request);
            commit(SET_EMAIL_VALIDATION_STATUS, EmailValidationStatus.Valid);
            await ApiClient.updateClientSubscriberId({
                clientId: state.clientId,
                subscriberId: response.subscriberId,
            });
            await dispatch('setSubscribedUser', response.subscriberId);
            return response.subscriberId;
        } catch (err) {
            const error: IError = {
                type: ErrorType.IncorrectVerification,
                // @ts-ignore
                message: this.$i18n.t('registration.invalidEmail'),
            };
            commit(ADD_ERROR, error);
            commit(SET_EMAIL_VALIDATION_STATUS, EmailValidationStatus.Invalid);
            // @ts-ignore
            this.$logger.warn({ msg: 'error.onUserSubscribe', err });
            // @ts-ignore
            this.$notifications.error(this.$i18n.t('notification.subscriptionFailed'));
        }
        return '';
    },
    async onUserLogout({ state, commit, dispatch }, options) {
        try {
            await AuthService.signOut((state.user as any).username);

            AuthService.clearSubscriberId();
            AuthService.removePageLoadCounter();

            commit(CLEAR_CLIENT);
            commit(CLEAR_USER);
            commit(CLEAR_PERSON_ID);

            dispatch('main/clearStoredLocale', null, { root: true });
            dispatch('main/clearStoredCountryCode', null, { root: true });

            if (options?.redirect) {
                window.$nuxt.$router.push({ path: options.redirect });
            } else {
                window.location.href = window.location.origin;
            }
        } catch (err) {
            // @ts-ignore
            this.$logger.warn({ msg: 'error.onUserLogout', err });
            window.location.href = window.location.origin;
        }
    },
    async onUserForgotPassword({ dispatch }, userName: string) {
        try {
            // This should trigger a confirmation code email to the user.
            // But it's currently not working, maybe due to AWS config?
            await AuthService.forgotPassword(userName);
            dispatch('main/toggleModal', { type: ModalType.EmailSent }, { root: true });
        } catch (err) {
            // @ts-ignore
            this.$logger.warn({ msg: 'error.onUserForgotPassword', err });
        }
    },
    async onUserNewPassword(_, { oldPassword, password: newPassword }) {
        try {
            await AuthService.changePassword(oldPassword, newPassword);
        } catch (err) {
            // @ts-ignore
            this.$logger.warn({ msg: 'error.onUserNewPassword', err });
        }
    },
    async onAfterSocialLogin({ dispatch, getters, rootState, state }, options) {
        const redirectPath = options.redirect;

        let userData;

        try {
            const loginMetadata = browser.storage.session.getItem(LOGIN_METADATA_KEY);
            const metadataRaw = loginMetadata ? JSON.parse(loginMetadata) : undefined;
            const metadata = authMetadataFactory.makeResponse(metadataRaw);

            sessionStorage.removeItem(LOGIN_METADATA_KEY);

            userData = {
                email: getters.email || options.email,
                locale: rootState.main.locale,
                userId: (state.user as any).username,
                identityProvider: options.identityProvider,
                metadata,
            } as ISocialLoginData;

            if (options.referral) {
                userData.referral = options.referral;
            }

            const response = await AuthService.socialLogin(userData);

            const afterAuthData = authChallengeResponseFactory.makeSocial(response);

            await dispatch('updateClientAfterRegistration', getters.subId);
            await dispatch('onAfterAuthChallenge', {
                redirectPath,
                metadata,
                isSocialLogin: true,
                loginType: options.identityProvider,
                referral: options.referral,
                ...afterAuthData,
                user: {
                    user_id: response?.user_id,
                    username: userData.email,
                    user_attributes: [],
                },
            } as Partial<IAfterAuthChallenge>);
        } catch (error) {
            this.app.$sentry.captureException('error.onAfterSocialLogin', { extra: { error, userData } });
            const provider = options.identityProvider;
            // @ts-ignore
            this.$notifications.error(this.$i18n.t('notification.socialLoginFailed', { provider }));
            setTimeout(() => (window.location.href = redirectPath), 3000);
        }
    },
    async onDailyChatSubscribe({ commit, state, rootState, dispatch }, data) {
        if (state.isLoggedIn) {
            this.$router.push({ path: '/login' });
            return;
        }
        const registerData = {
            email: data.email,
            locale: rootState.main.locale,
            redirectPath: data.redirectPath,
            referral: data.referral,
            recaptchaToken: data.recaptchaToken,
            source: data.source,
            metadata: data.metadata,
        } as IRegisterData;

        try {
            const result = await AuthService.register(registerData);
            if (result.isNewUser) {
                await dispatch('updateClientAfterRegistration', result.subId);
            }
            const loginData = {
                registerResult: result,
                type: UsernameType.Email,
                redirectPath: data.redirectPath,
                referral: data.referral,
                recaptchaToken: data.recaptchaToken,
                source: data.source,
                metadata: data.metadata,
                isDailyChat: true,
            };
            dispatch('onUserLogin', loginData);
        } catch (err) {
            this.app.$notifications.error(this.$i18n.t('registration.invalidEmail'));
            const error: IError = {
                type: ErrorType.IncorrectVerification,
                // @ts-ignore
                message: this.$i18n.t('registration.invalidEmail'),
            };
            commit(ADD_ERROR, error);
            // @ts-ignore
            this.$logger.warn({ msg: 'error.onDailyChatSubscribe', err });
        }
    },
    setClient: ({ commit }, client: IClient): void => {
        const storedClient = config.featureToggle.authClientLocalStorage
            ? browser.storage.local.getItem(CLIENT_STORAGE_KEY)
            : browser.storage.session.getItem(CLIENT_STORAGE_KEY);

        if (storedClient) {
            const client = JSON.parse(storedClient) as IClient;
            if (client.clientId) {
                commit(SET_CLIENT, client);
            }
        } else if (client) {
            commit(SET_CLIENT, client);

            if (config.featureToggle.authClientLocalStorage) {
                browser.storage.local.setItem(CHAT_WIDGET_CLIENT_STORAGE_KEY, JSON.stringify(client));
            } else {
                browser.storage.session.setItem(CHAT_WIDGET_CLIENT_STORAGE_KEY, JSON.stringify(client));
            }
        } else {
            const storedChatWidgetClient = config.featureToggle.authClientLocalStorage
                ? browser.storage.local.getItem(CHAT_WIDGET_CLIENT_STORAGE_KEY)
                : browser.storage.session.getItem(CHAT_WIDGET_CLIENT_STORAGE_KEY);
            if (storedChatWidgetClient) {
                const client = JSON.parse(storedChatWidgetClient) as IClient;
                commit(SET_CLIENT, client);
            }
        }
    },
    updateClientAfterRegistration: async ({ getters, state }, subId: string): Promise<any> => {
        const result = await ApiClient.updateClientAfterRegistration({
            client_id: state.clientId,
            client_type: 'Web',
            identity_id: getters.identityId,
            idfa: state.fingerprintHash,
            application_code: config.applicationCode,
            sub_id: subId,
        });
        return result;
    },
    async updateClient({ state, commit }) {
        try {
            const identityId = browser.storage.local.getItem(`CognitoIdentityId-${config.identityPoolId}`);
            const response = await ApiClient.updateClient({
                clientId: state.clientId,
                clientType: 'Web',
                identityId: `${identityId}`,
                idfa: state.fingerprintHash,
                applicationCode: config.applicationCode,
            });

            if (!state.clientId && response?.clientId) {
                const client: IClient = {
                    clientId: response.clientId,
                    subscriptionTopic: response.subscriptionTopic,
                };

                config.featureToggle.authClientLocalStorage
                    ? browser.storage.local.setItem(CLIENT_STORAGE_KEY, JSON.stringify(client))
                    : browser.storage.session.setItem(CLIENT_STORAGE_KEY, JSON.stringify(client));
                commit(SET_CLIENT, client);
            }
        } catch (error) {
            // @ts-ignore
            this.$logger.warn({ msg: 'error.updateClient', error });
        }
    },
    getFingerprint: ({ commit }) => {
        return new Promise((resolve, reject) => {
            if ((<any>window).requestIdleCallback) {
                (<any>window).requestIdleCallback(function () {
                    Fingerprint2.getV18((hash) => {
                        if (!hash) reject(new Error("Fingerprint doesn't provide hash."));
                        commit(SET_FINGERPRINT_HASH, hash);
                        resolve(hash);
                    });
                });
            } else {
                setTimeout(function () {
                    Fingerprint2.getV18((hash) => {
                        if (!hash) reject(new Error("Fingerprint doesn't provide hash."));
                        commit(SET_FINGERPRINT_HASH, hash);
                        resolve(hash);
                    });
                }, 1000);
            }
        });
    },
    getCurrentUserInfo() {
        try {
            return AuthService.getCurrentUserInfo();
        } catch (error) {
            // @ts-ignore
            this.$logger.warn({ msg: 'error.getCurrentUserInfo', error });
        }
    },
    getCurrentUser: async ({ commit, state }) => {
        try {
            const user = await AuthService.getCurrentUser();
            commit(SET_USER, user);
            commit(SET_LOGGED_IN_FLAG, true);
        } catch (error) {
            commit(CLEAR_USER);
            browser.storage.local.removeItem(PERSON_ID_STORAGE_KEY);

            if (!state.isKnownUser) {
                commit(CLEAR_PERSON_ID);
            }
        }
    },
    async validateRecontactToken({ dispatch }, recontactToken: string) {
        try {
            const response: IValidateRecontactTokenResponse = await ApiClient.validateRecontactToken({
                recontact_token: recontactToken,
            });

            if (response.user_id) {
                dispatch('setUserId', response.user_id);
                dispatch('setKnownUserFlag');
            }
        } catch (error) {
            const storedUserId = browser.storage.local.getItem(USER_ID_STORAGE_KEY);
            if (storedUserId) {
                dispatch('setUserId', storedUserId);
                dispatch('setKnownUserFlag');
            }
            // @ts-ignore
            this.$logger.warn({ msg: 'error.validateRecontactToken', error });
        }
    },
    setKnownUserFlag: ({ commit }) => {
        commit(SET_KNOWN_USER_FLAG, true);
    },
    setNoSignupFlag: ({ commit }) => {
        commit(SET_NO_SIGNUP_FLAG, true);
    },
    setDoNotTrackFlag: ({ commit }) => {
        commit(SET_DO_NOT_TRACK_FLAG, true);
    },
    setSubscribedUser: ({ commit }, subscriberId: string) => {
        AuthService.setSubscriberId(subscriberId);
        commit(SET_SUBSCRIBER_ID, subscriberId);
    },
    setUserEmail: ({ commit }, email) => {
        commit(SET_EMAIL, email);
    },
    setUserId: ({ commit }, userId: string) => {
        browser.storage.local.setItem(USER_ID_STORAGE_KEY, userId);
        commit(SET_USER_ID, userId);
    },
    setPersonId: ({ commit }, personId: string) => {
        commit(SET_PERSON_ID, personId);
    },
    setReferral: ({ commit }, referral) => {
        commit(SET_REFERRAL, referral);
    },
    setEmailValidationStatus: ({ commit }, status) => {
        commit(SET_EMAIL_VALIDATION_STATUS, status);
    },
    setError: ({ commit }, error: IError) => {
        commit(ADD_ERROR, error);
    },
};

const mutations: MutationTree<IAuthState> = {
    [SET_USER](state: IAuthState, payload: CognitoUser): void {
        state.user = payload;
    },
    [SET_USER_PROFILE](state: IAuthState, userProfile: any): void {
        state.userProfile = userProfile;
    },
    [SET_CREDENTIALS](state: IAuthState, payload: ICredentials): void {
        state.credentials = payload;
    },
    [SET_REGIONS](state: IAuthState, regions: Region[]): void {
        state.regions = regions;
    },
    [CLEAR_USER](state: IAuthState): void {
        state.user = null;
        state.isLoggedIn = false;
    },
    [CLEAR_PERSON_ID](state: IAuthState): void {
        state.personId = '';
    },
    [SET_CLIENT](state: IAuthState, client: IClient): void {
        state.clientId = client.clientId;
        state.subscriptionTopic = client.subscriptionTopic;
    },
    [CLEAR_CLIENT](state: IAuthState): void {
        state.clientId = '';
        state.subscriptionTopic = '';
    },
    [SET_FINGERPRINT_HASH](state: IAuthState, hash: string): void {
        state.fingerprintHash = hash;
    },
    [SET_LOGGED_IN_FLAG](state: IAuthState, payload: boolean): void {
        state.isLoggedIn = payload;
    },
    [CLEAR_ERRORS](state: IAuthState): void {
        state.errors = [];
    },
    [ADD_ERROR](state: IAuthState, error: IError): void {
        state.errors = [error, ...state.errors];
    },
    [SET_AUTH_REFRESHED](state: IAuthState): void {
        state.authRefreshed = true;
    },
    [SET_KNOWN_USER_FLAG](state: IAuthState, payload: boolean): void {
        state.isKnownUser = payload;
    },
    [SET_EMAIL](state: IAuthState, payload: string): void {
        state.recontactEmail = payload;
    },
    [SET_USER_ID](state: IAuthState, payload: string): void {
        state.userId = payload;
    },
    [SET_REFERRAL](state: IAuthState, payload: string): void {
        state.referral = payload;
    },
    [SET_NO_SIGNUP_FLAG](state: IAuthState, payload: boolean): void {
        state.noSignup = payload;
    },
    [SET_DO_NOT_TRACK_FLAG](state: IAuthState, payload: boolean): void {
        state.doNotTrackUser = payload;
    },
    [SET_EMAIL_VALIDATION_STATUS](state: IAuthState, status: string): void {
        state.emailValidationStatus = status;
    },
    [SET_SUBSCRIBER_ID](state: IAuthState, subscriberId: string): void {
        state.subscriberId = subscriberId;
    },
    [SET_SESSION_ID](state: IAuthState, sessionId: string): void {
        state.sessionId = sessionId;
    },
    [SET_USER_REGION](state: IAuthState, userRegion: Region): void {
        state.userRegion = userRegion;
    },
    [SET_PERSON_ID](state: IAuthState, personId: string): void {
        state.personId = personId;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
} as Module<IAuthState, IRootState>;
