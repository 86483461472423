<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { sharing } from '@inconvo/utils';
import { ConvoType, IdentityProvider } from '@inconvo/types/enums';
import { ModalType, LinkTarget, QueryParam, YgdRoutes } from '@/enums';
import config from '@/config';
import { auth as AuthService } from '@/auth';
import RouterService from '@/services/router.service';
import { analyticsPayloadFactory } from '@/models/factories';
import axios from 'axios';

export default {
    data() {
        return {
            channelSlug: this.$queryParams(QueryParam.Channel),
            restartConvo: this.$queryParams(QueryParam.Restart),
            authRefreshTimer: null,
            convoId: this.$route.params.convoId && Number(this.$route.params.convoId),
        };
    },
    computed: {
        ...mapGetters('auth', ['identityId', 'subscriberId', 'subId', 'authRefreshed']),
        ...mapGetters('channels', ['channelData']),
        ...mapState('channels', ['discoveryItems', 'channelList']),
        ...mapState('auth', {
            clientId: 'clientId',
            isLoggedIn: 'isLoggedIn',
            fingerprintHash: 'fingerprintHash',
            isKnownUser: 'isKnownUser',
            noSignup: 'noSignup',
            recontactEmail: 'recontactEmail',
            stateUserId: 'userId',
            user: 'user',
        }),
        ...mapState('yougovDirect', ['isPanelist']),
        ...mapState('main', ['skipChatWidgetInit']),
    },
    mounted() {
        const unwatch = this.$watch('authRefreshed', () => {
            unwatch();
            if (!document.hidden) {
                this.refreshTokens();
            }
        });

        if (!this.skipChatWidgetInit) {
            this.$chatWidget.addEventListener('openLink', this.onOpenLink);
            this.$chatWidget.addEventListener('openYgdTask', this.onOpenYgd);
            this.$chatWidget.addEventListener('openYgdProfile', this.onOpenYgd);
            this.$chatWidget.addEventListener('socialLogin', this.onSocialLogin);
            this.$chatWidget.addEventListener('openHomePage', this.onOpenHomePage);
            this.$chatWidget.addEventListener('close', this.onCloseChatWindow);
            this.$chatWidget.addEventListener('minimise', this.onMinimiseChatWindow);
            this.$chatWidget.addEventListener('openPanmanSurvey', this.onOpenPanmanSurvey);
            this.$chatWidget.addEventListener('openDispositionLink', this.onOpenDispositionLink);
            this.$chatWidget.addEventListener('lastConvoMessage', this.onLastConvoMessage);
            this.$chatWidget.addEventListener('firstConvoMessage', this.onFirstConvoMessage);
            this.$chatWidget.addEventListener('currentThreadId', this.onCurrentThreadId);
            this.$chatWidget.addEventListener('visibilitychange', this.onVisibilityChange);
            this.$chatWidget.addEventListener('analyticsTrackEvent', this.onAnalyticsTrackEvent);
            this.$chatWidget.addEventListener('share', this.onShare);
            this.$chatWidget.addEventListener('convoJump', this.onConvoJump);
            this.$chatWidget.addEventListener('userSignup', this.onUserSignup);
            this.$chatWidget.addEventListener('lastMessage', this.onLastMessage);
        }
    },
    beforeDestroy() {
        clearInterval(this.authRefreshTimer);
        if (!this.skipChatWidgetInit) {
            this.$chatWidget.removeEventListener('openLink', this.onOpenLink);
            this.$chatWidget.removeEventListener('openYgdTask', this.onOpenYgd);
            this.$chatWidget.removeEventListener('openYgdProfile', this.onOpenYgd);
            this.$chatWidget.removeEventListener('socialLogin', this.onSocialLogin);
            this.$chatWidget.removeEventListener('openHomePage', this.onOpenHomePage);
            this.$chatWidget.removeEventListener('close', this.onCloseChatWindow);
            this.$chatWidget.removeEventListener('minimise', this.onMinimiseChatWindow);
            this.$chatWidget.removeEventListener('openPanmanSurvey', this.onOpenPanmanSurvey);
            this.$chatWidget.removeEventListener('openDispositionLink', this.onOpenDispositionLink);
            this.$chatWidget.removeEventListener('lastConvoMessage', this.onLastConvoMessage);
            this.$chatWidget.removeEventListener('firstConvoMessage', this.firstConvoMessage);
            this.$chatWidget.removeEventListener('currentThreadId', this.onCurrentThreadId);
            this.$chatWidget.removeEventListener('visibilitychange', this.onVisibilityChange);
            this.$chatWidget.removeEventListener('share', this.onShare);
            this.$chatWidget.removeEventListener('convoJump', this.onConvoJump);
            this.$chatWidget.removeEventListener('userSignup', this.onUserSignup);
            this.$chatWidget.removeEventListener('lastMessage', this.onLastMessage);
        }
    },
    methods: {
        ...mapActions('main', ['toggleModal']),
        ...mapActions('channels', ['subscribeToChannel', 'unsubscribeFromChannel', 'getChannel']),
        ...mapActions('auth', {
            handleUserSubscribe: 'onUserSubscribe',
            getAuthData: 'getAuthData',
            getAuthDataV2: 'getAuthDataV2',
        }),
        ...mapActions('yougovDirect', ['getProfile']),
        ...mapActions('chatController', {
            chatControllerRemoveActive: 'removeActive',
            chatControllerToggleIsChatOpen: 'toggleIsChatOpen',
            chatControllerRemoveActiveConvo: 'removeActiveConvo',
            chatControllerSetActiveConvo: 'setActiveConvo',
            chatControllerSetCurrentThreadId: 'setCurrentThreadId',
            chatControllerSetLastMessage: 'setLastMessage',
        }),
        onOpenDispositionLink({ link }) {
            try {
                const PARAM_NAME = 'RID';
                const searchParams = new URLSearchParams(window.location.search);
                const rid = searchParams.get(PARAM_NAME);
                if (rid) {
                    const dispositionLink = new URL(link);
                    dispositionLink.searchParams.set(PARAM_NAME, rid);
                    axios.get(dispositionLink.toString());
                }
            } catch (error) {
                this.$sentry.captureException('error.onOpenDispositionLink', { extra: { error } });
            }
        },
        onOpenPanmanSurvey({ referralUrl, convoId }) {
            if (!this.isLoggedIn) {
                const loginLink = RouterService.getLoginLink(this.$route, convoId);
                window.location.href = loginLink;
                return;
            }
            try {
                this.$chatWidget.onModalOpen();
                this.toggleModal({
                    type: ModalType.Iframe,
                    payload: {
                        url: referralUrl,
                        closable: true,
                        closeCallback: () => {
                            this.$chatWidget.onModalClose();
                        },
                    },
                });
            } catch (error) {
                this.$notifications.error(error);
                this.$sentry.captureException('error.onOpenPanmanSurvey', { extra: { error } });
            }
        },
        onFirstConvoMessage(data) {
            this.onConvoMessageAnalyticEvent({ data, gtmCode: 'ChatStart' });
        },
        onLastConvoMessage(data) {
            this.onConvoMessageAnalyticEvent({ data, gtmCode: 'ChatComplete' });
            this.chatControllerRemoveActiveConvo(data.convoId);
        },
        onConvoMessageAnalyticEvent({ data, gtmCode }) {
            const IsValidConvoType = ![ConvoType.welcome, ConvoType.menu, ConvoType.signup, ConvoType.endFeed].includes(
                data.convoType,
            );

            if (IsValidConvoType) {
                const referral = this.referral ? JSON.parse(this.referral) : {};

                const payloadParams = {
                    sourceChannelCode: data.channelCode || referral.sourceChannelCode,
                    sourceConvoId: data.convoId || referral.sourceConvoId,
                    convoType: data.convoType,
                    utmCampaign: this.$queryParams(QueryParam.utmCampaign) || referral.utmCampaign,
                    utmSource: this.$queryParams(QueryParam.utmSource) || referral.utmSource,
                };

                const payload = analyticsPayloadFactory.make(payloadParams);

                this.$analytics.trackEvent([{ client: 'gtm', eventName: gtmCode, payload }]);
            }
        },
        onCurrentThreadId(data) {
            this.chatControllerSetCurrentThreadId(data.threadId);
        },
        onOpenLink(action) {
            const map = {
                [LinkTarget.Blank]: () => window.open(action.url, '_blank', 'noopener'),
                [LinkTarget.Internal]: () => (window.location.href = action.url),
                [LinkTarget.Modal]: () => {
                    this.$chatWidget.onModalOpen(action);
                    this.toggleModal({
                        type: ModalType.Iframe,
                        payload: {
                            url: action.url,
                            closable: true,
                            closeCallback: () => {
                                this.$chatWidget.onModalClose(action);
                            },
                        },
                    });
                },
            };
            if (map[action.target]) {
                map[action.target]();
            }
        },
        onOpenYgd() {
            window.location.href = YgdRoutes.Balance;
        },
        onUserSignup(payload) {
            this.toggleModal({ type: ModalType.LoginEmail, payload });
        },
        async onChannelLeave() {
            await this.unsubscribeFromChannel(this.channel);
            this.clearMessages();
            this.$emit('updatedSubscription');
            this.hideChat();
        },
        startChat(convoId = null) {
            this.$chatWidget.startChat({
                channel: this.channelData,
                convo: this.$route.params.convoId || convoId,
                restartConvo: this.restartConvo === '1',
            });
        },
        onUserSubscribe(email) {
            // not been handled
            this.handleUserSubscribe({
                email,
                metadata: {
                    sourceChannelCode: this.activeChannel.code,
                    sourceConvoId: this.convoId,
                    identityProvider: IdentityProvider.cognito,
                    utmCampaign: this.$queryParams(QueryParam.utmCampaign),
                    utmSource: this.$queryParams(QueryParam.utmSource),
                },
            });
        },
        toggleFullPageLoader(isVisible) {
            if (this.showFullPageLoader !== undefined) {
                this.showFullPageLoader = isVisible;
            }

            this.$emit('showFullPageLoader', isVisible);
        },
        onSocialLogin({ provider, channelSlug, convoId, channelCode, convoType }) {
            let route;

            if (this.isChatPage) {
                route = this.$router.resolve({
                    path: this.$route.path,
                    params: { slug: channelSlug, convoId },
                    query: RouterService.getRedirectParams(this.$route),
                });
            } else {
                const queryParams = {
                    ...RouterService.getRedirectParams(this.$route),
                };
                route = this.$router.resolve({ path: this.$route.path, query: queryParams });
            }

            AuthService.loginWithIdentityProvider({
                provider,
                redirectPath: route.href,
                referral: this.referral ? encodeURIComponent(this.referral) : null,
                metadata: {
                    sourceChannelCode: channelCode,
                    identityProvider: provider,
                    sourceConvoId: convoId,
                    convoType,
                    utmCampaign: this.$queryParams(QueryParam.utmCampaign),
                    utmSource: this.$queryParams(QueryParam.utmSource),
                },
            });
        },
        onOpenHomePage() {
            window.location.href =
                window.location.origin + this.$router.resolve({ name: 'index', query: this.$route.query }).href;
        },
        onCloseChatWindow() {
            this.chatControllerRemoveActive();
            this.$chatWidget.hideChat();
        },
        onMinimiseChatWindow() {
            this.chatControllerToggleIsChatOpen(false);
            this.$chatWidget.hideChat();
        },
        onVisibilityChange() {
            if (document.hidden) {
                clearTimeout(this.authRefreshTimer);
            } else {
                this.refreshTokens();
            }
        },
        async refreshTokens() {
            try {
                const authData = await this.getAuthDataV2();

                this.$chatWidget.refreshTokens({
                    idToken: authData.idToken,
                    accessToken: authData.accessToken,
                });

                if (authData.idTokenExp) {
                    let delay = authData.idTokenExp * 1000 - config.cognitoTokenRefreshExpiryOffset - Date.now();
                    delay = delay > 0 ? delay : config.authRefreshTimerInterval;
                    this.authRefreshTimer = setTimeout(this.refreshTokens, delay);
                }
            } catch (error) {
                this.$sentry.captureException('could not refresh tokens', { extra: { error } });
            }
        },
        onAnalyticsTrackEvent(event) {
            this.$analytics.trackEvent({ client: 'gtm', ...event });
        },
        onShare({ target, shareData }) {
            sharing.share(target, shareData);
        },
        onConvoJump({ channelCode, convoId }) {
            this.chatControllerSetActiveConvo({ channelCode, convoId });
        },
        onLastMessage({ channelCode, message }) {
            this.chatControllerSetLastMessage({ channelCode, message });
        },
    },
};
</script>
