<template>
    <header class="chat-header" :class="{ 'chat-header--show-back-button': showBackButton && !channel.isStandalone }">
        <div class="chat-header__avatar">
            <Avatar :image-url="channel.squareImage" />
        </div>

        <div class="chat-header__content">
            <div class="chat-header__name">{{ channel.name }}</div>
            <div class="chat-header__tagline truncate">{{ channel.tagLine }}</div>
        </div>
    </header>
</template>

<script>
import Avatar from './Avatar.vue';

export default {
    name: 'ChatHeader',
    components: {
        Avatar,
    },
    props: {
        channel: {
            type: Object,
            required: true,
        },
        showBackButton: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<style scoped lang="scss">
.chat-header {
    padding-left: 5px;
    height: 55px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    background-color: $bg-color-light;

    &--show-back-button {
        .chat-header__avatar {
            margin-left: 50px;
        }
    }
}
.chat-header__avatar {
    margin-right: 8px;
    margin-left: 10px;
}

.chat-header__content {
    flex: 1 1 auto;
    min-width: 0;
}

.chat-header__name {
    letter-spacing: -0.28px;
    line-height: 21px;
    font-weight: $bold-font-weight;
    font-size: $default-font-size;
    font-family: $heading-font-family;
}

.chat-header__tagline {
    opacity: 0.6;
    font-size: 13px;
    letter-spacing: -0.07px;
    line-height: 18px;
}
</style>
