import { auth as AuthService } from '@/auth';
import axios from 'axios';

const config = process.env.config as any;

const service = axios.create({
    baseURL: config.messagingApiBaseUrl,
    timeout: 15000,
});

service.interceptors.request.use(
    async (config) => {
        if (!config.headers || !config.headers.Authorization) {
            const idToken = await AuthService.getIdToken();
            const token = idToken.getJwtToken();
            config.headers.Authorization = `Bearer ${token}`;
        }

        config.headers['content-type'] = 'application/json';

        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);

service.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        return Promise.reject(error);
    },
);

export default service;
