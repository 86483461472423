<template>
    <div class="daily-chat-layout">
        <DailyChatHeader />

        <nuxt />

        <system-notifications :notifications="notifications" />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import config from '@/config';
import { StorySlug } from '@/enums';
import SystemNotifications from '@/components/SystemNotifications';
import ContentService from '@/services/content.service';
import MetadataService from '@/services/metadata.service';
import AbTestService from '@/services/ab-test.service';
import DailyChatHeader from '@/components/daily-chat/DailyChatHeader';

export default {
    name: 'DailyChatLayout',
    components: {
        SystemNotifications,
        DailyChatHeader,
    },
    async fetch() {
        try {
            const abTestService = new AbTestService(this.$cookies);
            const contentService = new ContentService(this.$storyapi, abTestService, this.$logger);
            const story = await contentService.getStory({
                story: StorySlug.Metadata,
                version: this.$storyblokHelper.version,
                locale: this.$i18n.locale,
                hasFallback: true,
            });

            const metadata = contentService.makeMetadataModel(story.content);
            const metadataService = new MetadataService(
                {
                    defaultMetatags: metadata.metatags,
                    i18nSeo: this.$nuxtI18nSeo(),
                    url: `${config.host}${this.$route.fullPath}`,
                    title: metadata.siteTitle,
                },
                this.$logger,
            );
            this.metadata = metadataService.getMetadata({ isCookieBannerEnabled: this.showCookieBanner });
        } catch (error) {
            this.$logger.error({ msg: 'error.blankLayout.fetch', error });
        }
    },
    data() {
        return {
            metadata: {},
        };
    },
    computed: {
        ...mapState('notifications', ['notifications']),
        ...mapGetters('main', ['showCookieBanner']),
    },
    head() {
        return this.metadata;
    },
};
</script>

<style lang="scss" scoped>
.daily-chat-layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    color: map-get($newPalette, white);
    background: map-get($newPalette, red, 300) url('../../assets/images/yg-background.svg') no-repeat center center;
    background-size: cover;
    font-family: $sans-serif;
}
</style>
