import { ILinkListDto, ILocaleSelectorDto } from '@/interfaces/dtos.interface';
import LinkList from '../linkList';
import LocaleSelector from '../localeSelector';
import LinkFactory from './link.factory';

export default class FooterItemFactory {
    public static make(data: ILinkListDto | ILocaleSelectorDto): LinkList | LocaleSelector {
        if ('links' in data) {
            const linkList = new LinkList();

            linkList._editable = data._editable;
            linkList._uid = data._uid;
            linkList.links = [];
            for (const link of data.links) {
                linkList.links.push(LinkFactory.make(link));
            }
            return linkList;
        } else {
            const localeSelector = new LocaleSelector();
            localeSelector._editable = data._editable;
            localeSelector._uid = data._uid;
            return localeSelector;
        }
    }
}
