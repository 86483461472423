<template>
    <AppButton
        class="login-button"
        :variant="$options.ButtonVariant.outline"
        :size="$options.ButtonSize.small"
        :label="label"
        @click="$emit('click', $event)"
    />
</template>

<script>
import AppButton from '@/components/AppButton';
import { ButtonVariant, ButtonSize } from '@/enums/buttons';

export default {
    name: 'HeaderLoginButton',
    components: {
        AppButton,
    },
    props: {
        label: {
            type: String,
            default: undefined,
        },
    },
    created() {
        this.$options.ButtonVariant = ButtonVariant;
        this.$options.ButtonSize = ButtonSize;
    },
};
</script>

<style lang="scss" scoped>
.login-button {
    padding: 1.2rem 1.1rem 1rem;
    line-height: 0.9rem;
    text-transform: uppercase;
    border-width: 1px;
    font-size: $smaller-font-size;

    @include media-query(medium) {
        padding: 1.3rem 2rem 1.2rem;
    }
}
</style>
