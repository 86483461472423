<template>
    <div class="speech-balloon-loader">
        <svg width="272" height="240" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <filter id="a" x="-2.9%" y="-2.4%" width="105.9%" height="106.7%" filterUnits="objectBoundingBox">
                    <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter1" />
                    <feGaussianBlur stdDeviation="3" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
                    <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" in="shadowBlurOuter1" />
                </filter>
            </defs>
            <g fill-rule="nonzero" fill="none">
                <path
                    d="M51.885 313.764h-25.25L44.49 295.91a60.419 60.419 0 0017.255-35.731C20.518 233.123 0 193.929 0 152.84 0 77.04 69.672 0 178.785 0c115.594 0 177.578 70.887 177.578 146.2 0 75.808-62.638 146.648-177.578 146.648a240.862 240.862 0 01-59.9-7.62 93.573 93.573 0 01-67 28.536z"
                    transform="matrix(.7296 0 0 .7296 6 3)"
                    fill="#000"
                    filter="url(#a)"
                />
                <path
                    d="M43.855 231.92H25.433l13.027-13.026a44.081 44.081 0 0012.589-26.07C20.969 173.085 6 144.49 6 114.512 6 59.208 56.832 3 136.44 3 220.777 3 266 54.719 266 109.666c0 55.31-45.7 106.994-129.56 106.994a175.731 175.731 0 01-43.702-5.56 68.27 68.27 0 01-48.883 20.82z"
                    fill="#FFF"
                />
            </g>
        </svg>
        <div class="dots">
            <div v-for="index in 5" :key="index" class="dot"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SpeechBalloonLoader',
};
</script>

<style lang="scss" scoped>
$dot-colors: #9077d7, #00b7b4, #ff6351, #ad61c4, #dc4b81;
$animation-duration: 2500;

.speech-balloon-loader {
    position: relative;
    width: 272px;
    height: 240px;
}

.dots {
    @include center(x);
    position: absolute;
    top: 104px;
    display: flex;
    width: 120px;
    justify-content: space-between;
}

.dot {
    width: 14px;
    height: 14px;
    border-radius: 14px;
    animation: dotAnimation #{$animation-duration}ms infinite linear normal;

    @for $i from 1 through length($dot-colors) {
        $color: nth($dot-colors, $i);

        &:nth-child(#{$i}) {
            background-color: $color;
            animation-delay: #{$i * $animation-duration/length($dot-colors) / 1.5}ms;
        }
    }
}

@keyframes dotAnimation {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    10% {
        transform: scale(1.5);
        opacity: 0.5;
    }

    40%,
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
</style>
