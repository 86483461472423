<template>
    <div class="radio-list-wrapper" :class="`radio-list-wrapper--${variant}`">
        <div v-if="label" class="radio-list-label" @click="onRadioListClick">{{ label }}</div>
        <div class="radio-list" :class="{ 'collapsable-list': collapsable }">
            <div v-if="showCollapasable" class="radio-list-placeholder" @click="onRadioListClick">
                <slot v-if="value" name="option" :option="value">{{ value.value }}</slot>
                <Icon
                    class="radio-list-chevron"
                    :class="{ 'chevron-up': isOpen }"
                    name="chevron-down"
                    :width="16"
                    :height="9"
                />
            </div>
            <div v-if="showExpanded" class="radio-list-options-wrapper">
                <div class="radio-list-options">
                    <div v-for="option of options" :key="option.id" :class="{ 'radio-list-option': !collapsable }">
                        <RadioButton
                            :id="option.id"
                            :value="option.id"
                            :name="name"
                            :checked="(value && option.id === value.id) || false"
                            :collapsed="collapsable"
                            :variant="variant"
                            @input="handleSelection(option)"
                        >
                            <template #label>
                                <slot name="option" :option="option">{{ option.value }}</slot>
                            </template>
                        </RadioButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/Icon';
import RadioButton from '@/components/forms/RadioButton';

export default {
    name: 'RadioList',
    components: {
        Icon,
        RadioButton,
    },
    props: {
        options: {
            type: Array,
            required: true,
        },
        value: {
            type: Object,
            default: null,
        },
        name: {
            type: String,
            default: 'radio-list',
        },
        label: {
            type: String,
            default: '',
        },
        errors: {
            type: Array,
            default: () => [],
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        infoText: {
            type: String,
            default: undefined,
        },
        collapsable: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: 'default',
            validator: (value) => ['default', 'daily-chat'].includes(value),
        },
    },
    data() {
        return {
            isOpen: false,
        };
    },
    computed: {
        showCollapasable() {
            return this.collapsable || (!this.isOpen && this.variant !== 'daily-chat');
        },
        showExpanded() {
            return this.isOpen || this.variant === 'daily-chat';
        },
    },
    mounted() {
        if (this.variant === 'daily-chat') {
            this.scrollToSelectedItem(this.value);
        }
    },
    methods: {
        onRadioListClick() {
            if (this.disabled) {
                return;
            }
            if (!this.isOpen) {
                this.isOpen = true;
            } else if (this.collapsable) {
                this.isOpen = false;
            }
        },
        handleSelection(value) {
            if (this.collapsable) {
                this.isOpen = false;
            }
            this.$emit('input', value);
        },
        scrollToSelectedItem(item) {
            const selectedElement = document.getElementById(item.id);
            const top = selectedElement.offsetTop;
            const el = document.querySelector('.radio-list-options-wrapper');

            if (selectedElement && top > el.scrollTop) {
                el.scrollTop = top;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.radio-list-wrapper {
    display: flex;
    flex-direction: column;

    &--daily-chat {
        .radio-list-label {
            font-size: $smaller-font-size;
            font-family: $font-heading;

            @include media-query(medium) {
                font-size: $small-font-size;
            }
        }
        .radio-list {
            border: none;
            border-radius: 0;
            color: $color-misc-1;
        }
        .radio-list-label {
            font-size: $small-font-size;
        }
    }
}

.radio-list-label {
    flex: 0 0 auto;
    margin-bottom: 16px;
    font-family: $heading-font-family;
    font-size: $smaller-font-size;
    font-weight: 300;
    color: $lighter-black;
}

.radio-list {
    flex: 0 1 auto;
    position: relative;
    display: flex;
    overflow: hidden;
    min-height: 40px;
    max-height: 100%;
    border-radius: 20px;
    border: solid 1px $light-grey-blue;
    background-color: $white;
    font-family: $heading-font-family;
    font-size: $smaller-font-size;
    color: $lighter-black;

    .radio-list-placeholder {
        flex: 1 1 auto;
        position: relative;
        padding: 12px 56px 12px 20px;
        cursor: pointer;
    }

    &.collapsable-list {
        border-radius: 2px;
        border: solid 1px map-get($newPalette, shades, 700);
        background-color: transparent;
        color: map-get($newPalette, shades, 300);
        flex-direction: column;

        .radio-list-placeholder {
            padding: 10px;
        }
        .radio-list-chevron {
            color: currentColor;
        }
    }
}

.radio-list-chevron {
    @include center(y);
    position: absolute;
    right: 20px;
    color: $light-grey-blue;
}

.chevron-up {
    transform: translateY(-50%) rotate(180deg);
}

.radio-list-options-wrapper {
    flex: 1 1 auto;
    overflow-y: auto;
}

.radio-list-option {
    &:not(:last-child) {
        ::v-deep .radio-button-label-text {
            border-bottom: solid 1px $form-input-border-color;
        }
    }
}
</style>
