export enum UserStatus {
    anonymous = 0,
    subscribed = 1,
    social = 2,
    registered = 3,
    emailConfirmed = 4,
    corConfirmed = 5,
    pmxRegistered = 6,
    virgin = 7,
    panelist = 8,
}
