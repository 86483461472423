import ApiClient from '@/api/server/apiClient';
import config from '@/config';
import { IChannel } from '@/interfaces';
import { SubscriptionTopic } from '@/enums';

const getSubscriptionChannels = (subscriptionChannels: string[]): Promise<IChannel[]> => {
    // from channelSlug to channelsCode
    return Promise.all(
        subscriptionChannels.map(async (channelSlug) => {
            const channel = await ApiClient.getChannel(channelSlug);
            return channel;
        }),
    );
};

export const getSubscriptionChannelSlug = async ({
    countryCode,
    sourceChannelCode,
    subscriptionChannels,
}: {
    countryCode: string;
    sourceChannelCode?: string;
    subscriptionChannels: string[];
}): Promise<SubscriptionTopic | undefined> => {
    // by sourceChannel
    if (sourceChannelCode) {
        const channelsList = await getSubscriptionChannels(subscriptionChannels);
        const channelSlug = channelsList?.find((channel) => channel.code === sourceChannelCode)?.slug || '';
        return config.subcriptionTopicOptions?.find((item) => item.channelSlug === channelSlug)
            ?.subscriptionTopic as SubscriptionTopic;
    }
    // by country
    return config.subcriptionTopicOptions?.find((item) => item.countryCode === countryCode)
        ?.subscriptionTopic as SubscriptionTopic;
};

export const onChannelSubscribe = async ({
    countryCode,
    sourceChannelCode,
    user,
}: {
    countryCode: string;
    sourceChannelCode?: string;
    user: { email?: string; userId?: string };
}) => {
    const dailyChatChannels = config.dailyChatChannels.split(',');
    const subscription = await getSubscriptionChannelSlug({
        countryCode,
        sourceChannelCode,
        subscriptionChannels: dailyChatChannels,
    });
    if (subscription) {
        ApiClient.optInUser({
            subscription,
            email: user.email,
            userId: user.userId,
        });
    }
};
