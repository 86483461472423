import { Inject, Context } from '@nuxt/types/app';
import { AnalyticsClient, FacebookClient, GtmClient, KeyweeClient } from '@inconvo/utils';
import AbTestService from '@/services/ab-test.service';

import config from '@/config';

export default (context: Context, inject: Inject) => {
    const abTestService = new AbTestService(context.$cookies, context.route);
    abTestService.setExperimentsOnGtmDataLayer();

    const analytics = new AnalyticsClient({
        enabled: true,
        debug: !config.isProduction,
    });

    if (config?.facebookPixelId !== '') {
        analytics.registerClient('facebook', new FacebookClient(config.facebookPixelId));
    }
    if (config?.keyweeId !== '') {
        analytics.registerClient('keywee', new KeyweeClient(config.keyweeId));
    }
    if (config?.gtmId !== '') {
        analytics.registerClient('gtm', new GtmClient(config.gtmId));
    }

    inject('analytics', analytics);
};
