import { IWidgetsData } from '@/interfaces/widget.interface';
import { IConvoListDto } from '@/interfaces/dtos.interface';
import { ConvoListStyle, ConvoCardDescription } from '@/enums';
import ConvoList from '../convoList';
import ConvoFactory from './convo.factory';

export default class ConvoListFactory {
    public static make(data: IConvoListDto, widgetsData: IWidgetsData): ConvoList {
        const convoList: ConvoList = new ConvoList();

        if (widgetsData[data._uid] instanceof Error) {
            convoList.showPlaceholder = true;
        }

        convoList._editable = data._editable;
        convoList._uid = data._uid;
        convoList.title = data.title;
        convoList.style = data.style as ConvoListStyle;
        convoList.cardDescription = data.card_description as ConvoCardDescription;
        convoList.loadMoreEnabled = data.load_more_enabled && !convoList.showPlaceholder;
        convoList.loadMoreButtonText = data.load_more_button_text;
        convoList.excludeChannels = data.exclude_channels;
        convoList.includeChannels = data.include_channels;
        convoList.excludeTags = data.exclude_tags;
        convoList.includeTags = data.include_tags;
        convoList.excludeTypes = data.exclude_types;
        convoList.excludeConvos = data.exclude_convos;
        convoList.includeTypes = data.include_types;
        convoList.sortingFields = data.sorting_fields;
        convoList.iconUrl = data.icon?.filename;
        convoList.items = [];
        convoList.size = +data.number_of_items;
        convoList.id = data.id;
        convoList.skip = data.skip ? +data.skip : 0;

        if (!convoList.showPlaceholder) {
            for (const convo of widgetsData[data._uid].items) {
                convoList.items.push(ConvoFactory.make(convo, convoList.cardDescription));
            }

            convoList.total = widgetsData[data._uid].total;
        } else {
            convoList.total = convoList.size;
        }

        return convoList;
    }
}
