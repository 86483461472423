import { IEditable } from '@/interfaces/storyblok.interface';
import { IWidget } from '@/interfaces/widget.interface';
import { WidgetType } from '@/enums/widgetType.enum';

export default class Widget<T> implements IWidget, IEditable {
    _editable: string;
    _uid: string;
    showPlaceholder: boolean;
    component!: WidgetType;
    items?: T[];
    title?: string;
    iconUrl?: string;

    constructor() {
        this._editable = '';
        this._uid = '';
        this.title = '';
        this.iconUrl = '';
        this.showPlaceholder = false;
    }
}
