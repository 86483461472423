import config from '@/config';
import AuthService from '@/services/auth.service';
import { AuthOptions } from '@aws-amplify/auth/lib-esm/types';

export const options: AuthOptions = {
    identityPoolId: config.identityPoolId,
    userPoolWebClientId: config.userPoolWebClientId,
    userPoolId: config.userPoolId,
    region: config.region,
    oauth: {
        redirectSignIn: config.socialLoginRedirectUri,
        redirectSignOut: config.redirectUriLogOut!,
        domain: config.authDomain!,
        scope: ['email', 'profile', 'openid'],
        responseType: 'code',
        options: {
            code_challenge_method: '',
        },
    },
};

export const auth = new AuthService(options);
