import { Inject } from '@nuxt/types/app';
import { QueryParam, StoryBlokVersion } from '@/enums';

export default ({ $queryParams }, inject: Inject) => {
    const storyblokHelper = {
        version: $queryParams(QueryParam.StoryblokToken) ? StoryBlokVersion.Draft : StoryBlokVersion.Published,
    };

    inject('storyblokHelper', storyblokHelper);
};
