import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { IYgdProfile } from '@/interfaces';
import { IRootState } from '@/types/rootState';
import YougovDirectClient from '@/api/server/yougovDirectClient';
import { SET_IS_PANELIST, SET_REDIRECT } from '@/types/mutations.types';

export interface IYougovDirectState {
    isPanelist: boolean;
    redirect: string | null;
}

const state = () => {
    const state: IYougovDirectState = {
        isPanelist: false,
        redirect: null,
    };
    return state;
};

export const getters: GetterTree<IYougovDirectState, IRootState> = {
    isPanelist: (state) => state.isPanelist,
    redirect: (state) => state.redirect,
};

export const actions: ActionTree<IYougovDirectState, any> = {
    async getProfile({ commit }) {
        try {
            const profile: IYgdProfile = await YougovDirectClient.getProfile();
            if (profile) {
                if (profile.isPanelist) {
                    commit(SET_IS_PANELIST, profile.isPanelist);
                } else if (profile.redirect) {
                    commit(SET_REDIRECT, profile.redirect);
                }
            }
        } catch (err) {
            // @ts-ignore
            this.$logger.warn({ msg: 'error.getProfile', err });
        }
    },
    setIsPanelist({ commit }, payload: boolean) {
        commit(SET_IS_PANELIST, payload);
    },
};

export const mutations: MutationTree<IYougovDirectState> = {
    [SET_IS_PANELIST](state: IYougovDirectState, isPanelist: boolean): void {
        state.isPanelist = isPanelist;
    },
    [SET_REDIRECT](state: IYougovDirectState, redirect: string): void {
        state.redirect = redirect;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
} as Module<IYougovDirectState, IRootState>;
