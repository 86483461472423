import { Channel } from '@/models/channel';
import { IChannel, ICategory, IChannelLandingPage } from '@/interfaces';

export class ChannelFactory {
    public static make(data: any, category?: ICategory): IChannel {
        const channel = new Channel();

        channel.id = data.id;
        channel.code = data.code;
        channel.name = data.name;
        channel.slug = data.slug;
        channel.showMemberCount = data.showMemberCount;
        channel.totalMembers = data.totalMembers || 0;
        channel.squareImage = data.squareImage || '';
        channel.landscapeImage = data.landscapeImage || '';
        channel.portraitImage = data.portraitImage || '';
        channel.tagLine = data.tagLine || '';
        channel.description = data.description || '';
        channel.tags = data.tags.split(',');
        channel.sharingTitle = data.sharingTitle;
        channel.sharingDescription = data.sharingDescription;
        channel.isStandalone = data.isStandalone || false;
        channel.landingPage = {} as IChannelLandingPage;
        channel.landingPage.channelTitle = data.landingPage?.channelTitle || channel.name;
        channel.landingPage.title = data.landingPage?.title || channel.tagLine;
        channel.landingPage.description = data.landingPage?.description || channel.description;
        channel.landingPage.image = data.squareImage;
        channel.activeUserCount = data.activeUserCount;

        if (category) {
            channel.category = category;
        }

        return channel;
    }
}
