<template>
    <div class="daily-chat-product-layout">
        <DailyChatHeader variant="product" :logo-clickable="true" />

        <nuxt />

        <AppFooter :data="footer" :is-auth-refreshed="authRefreshed" @setRegion="onSetRegion" />

        <SystemNotifications :notifications="notifications" />
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import config from '@/config';
import { StorySlug } from '@/enums';
import AppFooter from '@/components/AppFooter';
import DailyChatHeader from '@/components/daily-chat/DailyChatHeader';
import ContentService from '@/services/content.service';
import MetadataService from '@/services/metadata.service';
import AbTestService from '@/services/ab-test.service';
import SystemNotifications from '@/components/SystemNotifications';

export default {
    name: 'DailyChatProductLayout',
    components: {
        AppFooter,
        DailyChatHeader,
        SystemNotifications,
    },
    async fetch() {
        try {
            const abTestService = new AbTestService(this.$cookies);
            const contentService = new ContentService(this.$storyapi, abTestService, this.$logger);
            const story = await contentService.getStory({
                story: StorySlug.DefaultLayout,
                version: this.$storyblokHelper.version,
                locale: this.$i18n.locale,
                options: {
                    resolve_relations: 'metadata',
                },
                hasFallback: true,
            });

            this.storyId = story.id;
            this.footer = contentService.makeFooterModel(story.content.footer[0]);
            const metadata = contentService.makeMetadataModel(story.content.metadata.content);
            const metadataService = new MetadataService(
                {
                    defaultMetatags: metadata.metatags,
                    i18nSeo: this.$nuxtI18nSeo(),
                    url: `${config.host}${this.$route.fullPath}`,
                    title: metadata.siteTitle,
                },
                this.$logger,
            );

            this.metadata = metadataService.getMetadata({ isCookieBannerEnabled: this.showCookieBanner });
        } catch (error) {
            this.$logger.error({ msg: 'error.blankLayout.fetch', error });
        }
    },
    data() {
        return {
            storyId: undefined,
            metadata: {},
            footer: {},
        };
    },
    computed: {
        ...mapState('notifications', ['notifications']),
        ...mapState('auth', ['authRefreshed']),
        ...mapGetters('main', ['showCookieBanner']),
    },
    methods: {
        ...mapActions('main', ['setLocale', 'setCountryIso2Code']),
        async onSetRegion(region) {
            const locale = region.id;
            const countryCode = region.countryCode?.toUpperCase();

            if (this.$route.query.countryCode && this.$route.query.countryCode !== countryCode) {
                this.$router.push({ query: { countryCode } });
            }

            if (this.$route.query.locale && this.$route.query.locale !== locale) {
                this.$router.push({ query: { locale } });
            }

            if (locale !== this.$i18n.locale) {
                await this.setLocale({ locale });
                this.setCountryIso2Code(countryCode);

                this.$nuxt.refresh();
                window.scrollTo(0, 0);
            }
        },
    },
    head() {
        return this.metadata;
    },
};
</script>

<style lang="scss" scoped>
.daily-chat-product-layout {
}
</style>
