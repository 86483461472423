<template>
    <div class="page-container forbidden-page">
        <div class="forbidden-page__image">
            <img src="../../assets/images/403-forbidden.png" alt="Forbidden" />
        </div>
        <div class="forbidden-page__content">
            <h2 class="forbidden-page__title">{{ $t('forbiddenPage.title') }}</h2>
            <div class="forbidden-page__underline-text">{{ $t('forbiddenPage.subtitle') }}</div>
            <p class="forbidden-page__description">
                {{ $t('forbiddenPage.description') }}
            </p>
            <div class="forbidden-page__link">
                <button class="button" @click="backButtonClick">{{ $t('forbiddenPage.backButton') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ForbiddenPage',
    methods: {
        backButtonClick() {
            // eslint-disable-next-line no-self-assign
            window.location.href = '/';
        },
    },
};
</script>

<style lang="scss" scoped>
.forbidden-page {
    display: flex;
    flex-direction: column;

    @include media-query(medium) {
        flex-direction: row;
        padding-top: 5rem;
    }

    &__content {
        flex: 1 1 50%;
        color: #010101;
        line-height: normal;
        padding: 0 0 3rem 2rem;

        @include media-query(medium) {
            padding-top: 5rem;
        }
    }

    &__image {
        flex: 1 1 50%;
        text-align: center;

        img {
            max-width: 50%;

            @include media-query(medium) {
                max-width: 100%;
            }
        }
    }

    &__title {
        font-size: 2.4rem;
        font-weight: normal;
        color: $default-dark-color;
        text-align: center;

        @include media-query(medium) {
            font-size: 2.8rem;
            text-align: left;
        }
    }

    &__underline-text {
        position: relative;
        width: 17.1rem;
        margin: 0 auto;
        font-size: 3.8rem;
        font-weight: bold;
        color: $default-dark-color;

        &::before {
            content: '';
            z-index: -1;
            position: absolute;
            left: -1rem;
            bottom: 0.4rem;
            height: 1rem;
            width: calc(100% + 2rem);
            background-color: rgba(#aeb5c8, 0.75); //TODO: use variable from global styles
            @include media-query(medium) {
                height: 1.5rem;
            }
        }

        @include media-query(medium) {
            display: inline-block;
            font-size: 4.8rem;
            margin: 0;
            width: auto;
        }
    }

    &__description {
        font-family: $font-heading;
        line-height: 1.63;
        text-align: center;
        font-size: $default-font-size;
        font-weight: 300;
        padding: 2.8rem 2.6rem;
        color: $default-dark-color;

        @include media-query(medium) {
            margin-bottom: 4.2rem;
            font-size: 2.2rem;
            padding: 3.4rem 0 0 0;
            text-align: left;
            line-height: 1.45;
        }
    }

    &__link {
        width: 17.5rem;
        margin: 0 auto;
        @include media-query(medium) {
            margin: 0;
            width: auto;
        }
    }
    .button {
        border: 0.2rem solid $default-dark-color;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        height: 4rem;
        min-width: 16rem;
        font-size: 1.2rem;
        font-family: $default-font-family;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.192rem;
        cursor: pointer;
        background: $default-light-color;
    }
}
</style>
