<template>
    <ContentContainer tag="section" class="dc-opinion" :size="contentSize" data-cy="dc-opinion">
        <SectionHeader
            :text="data.title"
            icon="section-header-user"
            :variant="$options.SectionHeaderVariant.DailyChat"
            class="dc-opinion-header"
        />

        <AppTestimonial
            v-for="(item, idx) in data.items"
            :key="item.id"
            :class="{ 'mb-4': idx < data.items.length - 1 }"
            :image="item.image"
            :name="item.name"
            :testimonial="item.testimonial"
            :variant="(idx + 1) % 2 === 0 ? 'even' : 'odd'"
        />
    </ContentContainer>
</template>

<script>
import ContentContainer from '@/components/ContentContainer';
import SectionHeader from '@/components/SectionHeader.vue';
import AppTestimonial from '@/components/AppTestimonial';
import { SectionHeaderVariant } from '@/enums';

export default {
    name: 'DailyChatOpinion',
    components: {
        ContentContainer,
        SectionHeader,
        AppTestimonial,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        contentSize: {
            type: String,
            required: true,
        },
    },
    created() {
        this.$options.SectionHeaderVariant = SectionHeaderVariant;
    },
    methods: {
        richText(content) {
            return content ? this.$storyapi.richTextResolver.render(content) : '';
        },
    },
};
</script>

<style lang="scss" scoped>
.dc-opinion {
    padding: 4rem 0;

    @include media-query(medium) {
        padding: 9rem 0;
    }
}

.dc-opinion-header {
    margin-bottom: 4rem;

    @include media-query(medium) {
        margin-bottom: 6rem;
    }
}
</style>
