<template>
    <span
        class="toggle-wrapper"
        role="checkbox"
        :aria-checked="isOn.toString()"
        tabindex="0"
        @click="toggle"
        @keydown.space.prevent="toggle"
    >
        <span class="toggle-background" :style="backgroundStyles"></span>
        <span class="toggle-indicator" :style="indicatorStyles"></span>
    </span>
</template>

<script>
export default {
    name: 'Toggle',
    props: {
        isOn: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        backgroundStyles() {
            return {
                backgroundColor: this.isOn ? '#28bbca' : '#dae1e7',
            };
        },
        indicatorStyles() {
            return { transform: this.isOn ? 'translateX(3.2rem)' : 'translateX(0)' };
        },
    },
    watch: {
        isOn() {
            this.$emit('changed');
        },
    },
    methods: {
        toggle() {
            this.$emit('input', !this.isOn);
        },
    },
};
</script>

<style lang="scss" scoped>
.toggle-wrapper {
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 16px;
    width: 34px;
    border-radius: 7px;
}
.toggle-wrapper:focus {
    outline: 0;
}

.toggle-background {
    display: inline-block;
    border-radius: 9999px;
    height: 100%;
    width: 100%;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s ease;
}

.toggle-indicator {
    position: absolute;
    left: -10px;
    top: -2px;
    height: 20px;
    width: 20px;
    background-color: $default-light-color;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
}
</style>
