import { IEditable } from '@/interfaces/storyblok.interface';
import Link from './link';

export default class LinkList implements IEditable {
    links: Link[];
    _editable: string;
    _uid: string;

    constructor() {
        this._editable = '';
        this._uid = '';
        this.links = [];
    }
}
