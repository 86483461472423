import { MessengerEventType } from '@/enums';
import { browser } from '@inconvo/utils';
import { MessengerEventDto } from '../interfaces/messengerEvent.interface';

let API;
if (process.server || browser.storage.isSupported()) {
    API = require('@aws-amplify/api').default;
}

export class EventsClient {
    public async logUserSignup() {
        const body: MessengerEventDto = {
            taskId: '--',
            userId: '--',
            timestamp: new Date(),
            type: MessengerEventType.userSignup,
        };
        try {
            await API.post('messaging', 'analytics/events', { response: false, body });
        } catch {}
    }

    public async logUserLogin() {
        const body: MessengerEventDto = {
            taskId: '--',
            userId: '--',
            timestamp: new Date(),
            type: MessengerEventType.userLogin,
        };
        try {
            await API.post('messaging', 'analytics/events', { response: false, body });
        } catch {}
    }
}

export default new EventsClient();
