export const SET_CLIENT = 'setClient';
export const SET_CHANNELS = 'setChannels';
export const SET_ACTIVE_CHANNEL = 'setActiveChannel';
export const SET_DISCOVERY_ITEM = 'setDiscoveryItem';
export const SET_FINGERPRINT_HASH = 'setFingerprintHash';
export const ADD_CHANNELS = 'addChannels';
export const SET_MESSAGES = 'setMessages';
export const SET_MODAL_STATE = 'setModalState';
export const SET_USER = 'setUser';
export const SET_USER_PROFILE = 'setUserProfile';
export const SET_IS_LOADING = 'setIsLoading';
export const SET_MENU_STATE = 'setMenuState';
export const CLEAR_USER = 'clearUser';
export const CLEAR_PERSON_ID = 'clearPersonId';
export const CLEAR_CLIENT = 'clearClient';
export const SET_LOAD_MORE_CURSOR = 'setLoadMoreCursor';
export const SET_CREDENTIALS = 'setCredentials';
export const SET_FEATURED_CHANNELS = 'setFeaturedChannel';
export const SET_LOGGED_IN_FLAG = 'setLoggedInFlag';
export const ADD_ERROR = 'addError';
export const CLEAR_ERRORS = 'clearErrors';
export const SET_SUBSCRIBED_CHANNELS = 'setSubscribedChannels';
export const SET_AUTH_REFRESHED = 'setAuthRefreshed';
export const SET_KNOWN_USER_FLAG = 'setKnownUserFlag';
export const SET_EMAIL = 'setUserEmail';
export const SET_USER_ID = 'setUserId';
export const SET_REFERRAL = 'setReferral';
export const SET_NO_SIGNUP_FLAG = 'setNoSignupFlag';
export const SET_DO_NOT_TRACK_FLAG = 'setDoNotTrackFlag';
export const SET_LOCALE = 'setLocale';
export const SET_COUNTRY_ISO2_CODE = 'setCountryIso2Code';
export const SET_IS_PANELIST = 'setIsPanelist';
export const SET_CATEGORIES = 'setCategories';
export const SET_CATEGORY_FILTER = 'setCategoryFilter';
export const SET_EMAIL_VALIDATION_STATUS = 'setEmailValidationStatus';
export const SET_REDIRECT = 'setRedirect';
export const SET_IS_FULL_PAGE_LOADER_VISIBLE = 'setIsFullPageLoaderVisible';
export const ADD_TO_CHANNELS_CACHE = 'addToChannelsCache';
export const SET_SUBSCRIBER_ID = 'setSubscriberId';
export const SET_SKIP_CHAT_WIDGET_INIT = 'setSkipChatWidgetInit';
export const SET_SESSION_ID = 'setSessionId';
export const SET_REGIONS = 'setRegions';
export const SET_USER_REGION = 'setUserRegion';
export const SET_IS_DAILY_CHAT_ENABLED = 'setIsDailyChatEnabled';
export const SET_PERSON_ID = 'setPersonId';
export const SET_APP_OPTIONS = 'setAppOptions';
