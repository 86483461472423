import axios from 'axios';
import config from './../../config';

const service = axios.create({
    baseURL: config.messagingApiBaseUrl,
    timeout: 15000,
});

service.interceptors.request.use(
    async (config) => {
        config.headers['content-type'] = 'application/json';

        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);

service.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        return Promise.reject(error);
    },
);

export default service;
