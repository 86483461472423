<template>
    <div class="input-group" :class="wrapperClass">
        <div v-if="!!$slots.prepend" class="input-group-prepend">
            <slot name="prepend" />
        </div>

        <div class="input-group-input">
            <slot />
        </div>

        <div v-if="!!$slots.append" class="input-group-append">
            <slot name="append" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'InputGroup',
    computed: {
        wrapperClass() {
            const prependClass = this.$slots.prepend ? 'input-group--prepend' : '';
            const appendClass = this.$slots.append ? 'input-group--append' : '';
            return [prependClass, appendClass];
        },
    },
};
</script>

<style lang="scss" scoped>
.input-group {
    display: flex;
    width: 100%;
}

.input-group-input {
    flex: 1;
}

.input-group-append,
.input-group-prepend {
    > * {
        line-height: 1;
        margin: 0;
    }
}
.input-group--append {
    ::v-deep input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
}
.input-group--prepend {
    ::v-deep input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}
</style>
