import { IChatWidgetChannel, IChatWidgetLastMessage } from '@/interfaces';

export default class ChatWidgetChannel implements IChatWidgetChannel {
    code: string;
    slug: string;
    name: string;
    tagLine: string;
    isStandalone: boolean;
    sharingTitle: string;
    sharingDescription: string;
    squareImage: string;
    activeConvoId?: number;
    currentThreadId?: string;
    lastMessage?: IChatWidgetLastMessage;

    constructor() {
        this.code = '';
        this.slug = '';
        this.name = '';
        this.tagLine = '';
        this.isStandalone = false;
        this.sharingTitle = '';
        this.sharingDescription = '';
        this.squareImage = '';
        this.lastMessage = {} as IChatWidgetLastMessage;
    }

    isValid(): boolean {
        return !!(
            this.code &&
            this.slug &&
            this.name &&
            this.tagLine &&
            this.sharingTitle &&
            this.sharingDescription &&
            this.squareImage
        );
    }
}
