import pino from 'pino';

const { toString } = Object.prototype;
const seen = Symbol('circular-ref-tag');
const rawSymbol = Symbol('pino-raw-err-ref');
const pinoErrProto = Object.create(
    {},
    {
        type: {
            enumerable: true,
            writable: true,
            value: undefined,
        },
        message: {
            enumerable: true,
            writable: true,
            value: undefined,
        },
        stack: {
            enumerable: true,
            writable: true,
            value: undefined,
        },
        raw: {
            enumerable: false,
            get() {
                return this[rawSymbol];
            },
            set(val) {
                this[rawSymbol] = val;
            },
        },
    },
);
Object.defineProperty(pinoErrProto, rawSymbol, {
    writable: true,
    value: {},
});

function errSerializer(err) {
    if (!(err instanceof Error)) {
        return err;
    }

    err[seen] = undefined; // tag to prevent re-looking at this
    const _err = Object.create(pinoErrProto);
    _err.type = toString.call(err.constructor) === '[object Function]' ? err.constructor.name : err.name;
    _err.message = err.message;
    _err.stack = err.stack;
    for (const key in err) {
        if (_err[key] === undefined) {
            const val = err[key];
            if (val instanceof Error) {
                /* eslint-disable no-prototype-builtins */
                if (!val.hasOwnProperty(seen)) {
                    _err[key] = errSerializer(val);
                }
            } else {
                _err[key] = val;
            }
        }
    }

    delete err[seen]; // clean up tag in case err is serialized again later
    _err.raw = err;
    return _err;
}

export default function (isServer: boolean, app?: any) {
    let logger;

    if (isServer) {
        logger = pino({
            name: process.env.NUXT_APPLICATION_NAME,
            level: process.env.LOG_LEVEL || 'info',
            serializers: { error: errSerializer },
        });
    } else if (app) {
        const captureMessage = (props) => {
            const { msg, ...otherProps } = props;
            app.$sentry.captureMessage(msg, { extra: { ...otherProps } });
        };

        const captureException = (props) => {
            const { msg, ...otherProps } = props;
            app.$sentry.captureException(msg, { extra: { ...otherProps } });
        };

        logger = {
            error: captureException,
            info: captureMessage,
            warn: captureMessage,
        };
    }

    return logger;
}
